const Box = (props: { children: JSX.Element | JSX.Element[] }) => {
    return (
        <div
            className="bg-white"
            style={{
                padding: '16px',
                // margin: '16px',
                borderRadius: '6px',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)'
            }}
        >
            {props.children}
        </div>
    );
};

export default Box;