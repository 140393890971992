import { useEffect, useRef, useState } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { CSSTransition } from 'react-transition-group';

import priceFormatter from '../../../app/formatter';
import logoGDark from '../../../assets/images/logo-g-dark.svg';
import { Button } from '../../../common/button';
import { IModalManage } from '../../../types/subscription';
import ModalConfirm from '../modal-confirm/modal-confirm';
import styles from './modal-manage.module.css';
import { useAdmAcessosMutate } from '../../../hooks/useAdmAcessosMutate';
import Spinner from '../../../common/spinner/spinner';
import { useSubscriptionDelete } from '../../../hooks/useSubscriptionDelete';

function ModalManage(props: IModalManage) {
    const modalConfirmRef = useRef(null);
    const [showModalConfirm, setShowModalConfirm] = useState(false);
    function handleOpenModalConfirm() {
        setShowModalConfirm(true);
    }

    const [statusResponse, setStatusResponse] = useState({
        message: '',
        status: '',
    });

    const admAcessosMutate = useAdmAcessosMutate();

    function adminCancelSubscription() {
        const data = {
            tipo: 'excluir-acesso',
            usuario: props.user_id,
            produto: props.id,
        };
        admAcessosMutate.mutate(data);
    }

    useEffect(() => {
        if(admAcessosMutate.isError) {
            setStatusResponse({
                message: 'Erro ao cancelar assinatura.',
                status: 'error'
            });
        }

        if(admAcessosMutate.isSuccess) {
            setStatusResponse({
                message: 'Sucesso ao cancelar assinatura.',
                status: 'success'
            });
            handleCloseModalConfirm();
            props.onClose();
        }
    }, [admAcessosMutate.isError, admAcessosMutate.isSuccess]);

    const subscriptionDelete = useSubscriptionDelete();

    function userCancelSubscription() {
        const data = {
            product_id: "",
            subscription_id: props.subscription_id,
            card_id:""
        };
        subscriptionDelete.mutate(data);

        // const requestOptions = {
        //     method: "DELETE",
        //     headers: { "Content-Type": "application/json" },
        //     body: JSON.stringify({
        //         product_id: "",
        //         subscription_id: props.subscription_id,
        //         card_id:""
        //     }),
        // };
        // fetch(`api/assinaturas`, requestOptions)
        //     .then((response) => {
        //         if(response.ok) {
        //             setStatusResponse({
        //                 message: 'Sucesso ao cancelar assinatura.',
        //                 status: 'success'
        //             });
        //             if(props.onFetchData) {
        //                 props?.onFetchData();
        //             }
        //             handleCloseModalConfirm();
        //             props.onClose();
        //         } else {
        //             setStatusResponse({
        //                 message: 'Erro ao cancelar assinatura.',
        //                 status: 'error'
        //             });
        //         }
        //     })
        //     .catch((e) => {
        //         console.log(e);
        //     });
    }

    useEffect(() => {
        if(subscriptionDelete.isError) {
            setStatusResponse({
                message: 'Erro ao cancelar assinatura.',
                status: 'error'
            });
        }

        if(subscriptionDelete.isSuccess) {
            setStatusResponse({
                message: 'Sucesso ao cancelar assinatura.',
                status: 'success'
            });
            handleCloseModalConfirm();
            props.onClose();
        }
    }, [subscriptionDelete.isError, subscriptionDelete.isSuccess]);

    function handleConfirm() {
        if(props.subscription_id) {
            userCancelSubscription();
        } else {
            adminCancelSubscription();
        }
    }

    function handleCloseModalConfirm() {
        setStatusResponse({
            message: '',
            status: '',
        });
        setShowModalConfirm(false);
    }

    return (
        <>
            <div className={styles["modal-wrapper"]}>
                <div className={styles['modal-bg']} onClick={() => props.onClose()}></div>
                <div className={styles['modal']}>
                    <header className="d-flex justify-content-between" style={{marginBottom: '16px'}}>
                        <h5 className={"text-body text-semibold color-dark-01 " + styles['title-config']}>
                            Configurações
                        </h5>
                        <RiCloseFill onClick={() => props.onClose()} />
                    </header>
                    <section className={"d-flex justify-between " + styles['body']}>  
                        <div className={styles['col-about']}>
                            <img
                                src={logoGDark}
                                alt="Logo Greendex"
                                className={styles['img']}
                            />
                            <div className={styles["col-about-text"]}>
                                <h5 className="text-body text-xlarge text-semibold color-greyscale-01">
                                    {props.title}
                                </h5>
                                <p className="text-body text-medium color-greyscale-03">
                                    Tipo de assinatura: {props.type}
                                </p>
                                {props.payment && (
                                    <p className="text-body text-medium color-greyscale-03">
                                        Forma de pagamento: {props.payment}
                                    </p>
                                )}
                                {props.status && (
                                    <p className="text-body text-medium color-greyscale-03">
                                        Status: {props.status}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className={styles["col-price"]}>
                            {/* <p className="text-body text-large color-greyscale-03">
                                Total: <span className="text-w-medium color-greyscale-02">
                                    R$ {props.price
                                        .toLocaleString(
                                        'pt-br',
                                        {
                                            style: 'decimal',
                                            minimumFractionDigits: 2
                                        }
                                    )}
                                </span>
                            </p> */}
                        </div>
                        <div className={styles["col-buttons"]}>
                            {/* <Button
                                className="btn-primary"
                                text="Alterar forma de pagamento"
                                onClick={() => {}}
                                style={{
                                    fontSize: '12px',
                                    padding: '8px 12px',
                                    marginBottom: '16px'
                                }}
                            />
                            <Button
                                className="btn-primary-outline"
                                text="Suspender temporariamente"
                                onClick={() => {}}
                                style={{
                                    fontSize: '12px',
                                    padding: '8px 12px',
                                    marginBottom: '16px'
                                }}
                            /> */}
                            {props.price && (
                                <p
                                    className="text-body text-large color-greyscale-03"
                                    style={{ marginBottom: '16px' }}
                                >
                                    Total: <span className="text-w-medium color-greyscale-02">
                                        {priceFormatter(props.price)}
                                    </span>
                                </p>
                            )}
                            <Button.Primary
                                btnType="btn-red-outline"
                                onClick={() => handleOpenModalConfirm()}
                                style={{
                                    fontSize: '12px',
                                    padding: '8px 12px',
                                    marginBottom: '0'
                                }}
                            >
                                Cancelar assinatura
                            </Button.Primary>
                        </div>
                    </section>
                </div>
            </div>
            <CSSTransition
                in={showModalConfirm}
                nodeRef={modalConfirmRef}
                timeout={300}
                unmountOnExit
                classNames='fade'
            >
                <div ref={modalConfirmRef}>
                    <ModalConfirm
                        title="Você tem certeza que deseja cancelar esta assinatura?"
                        description="O valor mensal já foi cobrado no seu cartão e não poderá ser reembolsado."
                        btn_text="Cancelar assinatura"
                        statusResponse={statusResponse}
                        onConfirm={handleConfirm}
                        onClose={handleCloseModalConfirm}
                    />
                </div>
            </CSSTransition>
            {admAcessosMutate.isLoading && <Spinner /> }
        </>
    );
}
export default ModalManage;