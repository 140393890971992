interface ErrorMessageProps {
    expr: any | (() => boolean)
    message: string
}

const ErrorMessage = ({ expr, message }: ErrorMessageProps) => {
    if (typeof expr === 'function' && !expr()) return null
    if (!expr) return null

    return (
        <p className="input-error">
            {message}
        </p>
    );
}

export default ErrorMessage