import { Link } from "react-router-dom";
import { Button } from "../common/button";

function ErrorView() {
    return (
        <div className="bg-grey">
            <div className="container">
                <div className="row">
                    <div className="col-md-1"></div>
                    <div className='col-md-10' style={{ marginTop: '86px', marginBottom: '173px' }}>
                        <h3
                            className='title-h3 text-bold'
                            style={{ marginBottom: '24px' }}
                        >
                            Página não encontrada!
                        </h3>
                        <Button.Link
                            btnType="btn-primary"
                            to='/'
                        >
                            Voltar para home
                        </Button.Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ErrorView;