import { RiCloseFill } from "react-icons/ri";

import { Button } from "../../../common/button";
import styles from './modal-confirm.module.css';

interface IModalConfirm {
    title: string;
    description: string;
    btn_text: string;
    onConfirm: Function;
    onClose: Function;
    subscription_id?: string,
    statusResponse?: { message: string, status: string };
    children?: JSX.Element;
    btn_color?: "btn-primary-outline" | "btn-primary" | "btn-secondary" | "btn-social" | "btn-filter" | "btn-white" | "btn-secondary-outline" | "btn-red-outline";
}
function ModalConfirm(props: IModalConfirm) {
    return (
        <div className={styles["modal-wrapper"]}>
            <div className={styles['modal-bg']} onClick={() => props.onClose()}></div>
            <div className={styles['modal']}>
                <header
                    className="d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <h5 className={"text-body text-semibold color-dark-01 " + styles['title-config']}>
                        {props.title}
                    </h5>
                    <RiCloseFill
                        onClick={() => props.onClose()}
                        style={{ fontSize: '26px' }}
                    />
                </header>
                <section className={"d-flex justify-between " + styles['body']}>
                    {props.description && (
                        <p className="text-body text-medium color-greyscale-02">
                            {props.description}
                        </p>
                    )}
                    {props?.children}
                    {props.statusResponse && (
                        <p className={`input-${props.statusResponse.status}`}>
                            {props.statusResponse.message}
                        </p>
                    )}
                    <div className={styles["col-buttons"]}>
                        <Button.Primary
                            btnType={props.btn_color ? props.btn_color : "btn-red-outline"}
                            onClick={() => props.onConfirm()}
                            style={{
                                fontSize: '12px',
                                padding: '8px 12px',
                                marginBottom: '16px'
                            }}
                        >
                            {props.btn_text}
                        </Button.Primary>
                        <span
                            className="text-body text-large color-greyscale-01"
                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => props.onClose()}
                            >
                                Voltar
                        </span>
                    </div>
                </section>
            </div>
        </div>
    );
}
export default ModalConfirm;