import { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Card from './card';
import CardContainer from './card-container';
import TooltipContent from './tooltip-content';
import Tooltip from '../tooltip/tooltip';
import stylesTooltip from '../tooltip/tooltip.module.css';
import useWindowSize from '../../hooks/useWindowSize';

interface CardTooltipProps {
    id: string;
    thumbnail_link: string;
    title: string;
    description: string;
    paragraph_1: string;
    paragraph_2: string;
    redirect_link: string;
    badge?: string;
    children?: any;
    btn_text?: string
}

function CardTooltip(props: CardTooltipProps) {
    const tooltipRef = useRef(null);
    const [tooltipPosition, setTooltipPosition] = useState<'left' | 'right'>("left");
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const pageWidth = useWindowSize()[0];

    return (
        <CardContainer
            setTooltipPosition={setTooltipPosition}
            setIsHovering={setIsHovering}
        >
            <Card {...props}/>
            <CSSTransition
                in={!!isHovering && (pageWidth > 820)}
                nodeRef={tooltipRef}
                timeout={300}
                unmountOnExit
                classNames='fade'
            >
                <div
                    ref={tooltipRef}
                    className={
                        stylesTooltip['div-tooltip'] + ' ' + stylesTooltip[tooltipPosition]
                    }
                >
                    <Tooltip tooltipPosition={tooltipPosition}>
                        <TooltipContent
                            title={props.title}
                            paragraph_1={props.paragraph_1}
                            paragraph_2={props.paragraph_2}
                            redirect_link={props.redirect_link}
                        />
                    </Tooltip>
                </div>
            </CSSTransition>
        </CardContainer>
    );
}

export default CardTooltip;