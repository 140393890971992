import ProductDataSection from './product-data';
import ProductItemActions from './product-item-actions';
import ProductItemContainer from './product-item-container';

import When from '../../common/when/when';
import styles from '../../styles/purchases-historic.module.css';
import { ProductData } from '../../types/home';

interface ProductListProps {
    products: ProductData[],
    handleSelectProduct: Function,
    handleOpenModalDelete: Function
}

const ProductList = ({ products, handleSelectProduct, handleOpenModalDelete }: ProductListProps) => {
    return (
        <ul className={styles["list"]}>
            {products.map((product) => (
                <ProductItemContainer key={product.id}>
                    <div className={"d-flex " + styles["row-user-circle"]}>
                        <div className={styles["user-image"]}>
                            <img src={product.thumbnail_link} style={{ width: '100%', height: '100%' }} />
                        </div>
                        <ProductDataSection product={product} />
                    </div>
                    <ProductItemActions
                        product={product}
                        selectProduct={handleSelectProduct}
                        openModalDelete={handleOpenModalDelete}
                    />
                </ProductItemContainer>
            ))}
            <When expr={products.length === 0}>
                <p className="text-body text-large text-w-medium color-greyscale-01">
                    Nenhum produto encontrado
                </p>
            </When>
        </ul>
    );
}

export default ProductList