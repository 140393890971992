import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ICarsouselHeaderItem } from '../types/carousel-header';
import { IProduct } from '../types/produto';
import homeAPI from '../apis/HomeAPI';

interface IHomeState {
    loading: boolean,
    carousel: ICarsouselHeaderItem[],
    products: IProduct[],
    packages: any //mudar depois
};

const initialState: IHomeState = {
    loading: false,
    carousel: [],
    products: [],
    packages: [],
};

export const fetchData = createAsyncThunk(
    'home/fetchData',
    async () => {
        const response: { 
            produtos: IProduct[],
            carrossel: ICarsouselHeaderItem[] ,
            pacotes: any //mudar depois
        } = await homeAPI.fetchHomeData();
      return response;
    }
);

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.loading = false;
                state.products = [...action.payload.pacotes, ...action.payload.produtos];
                state.carousel = action.payload.carrossel;
                state.packages = action.payload.pacotes;
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.loading = false;
                console.log(action.error.message);
            })
    },
});

export const selectLoading = 
    (state: { home: IHomeState }) => state.home.loading;
export const selectCarousel = 
    (state: { home: IHomeState }) => state.home.carousel;
export const selectProducts = 
    (state: { home: IHomeState }) => state.home.products;
export const selectPackages = 
    (state: { home: IHomeState }) => state.home.packages;

export default homeSlice.reducer;