import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useHeaderConfig } from "./useHeaderConfig";

const PostData = async ({ data, contentType }: { data: FormData, contentType: string }): Promise<any> => {
    const defaultConfig = useHeaderConfig();
    const config = {
        ...defaultConfig,
        headers: {
            ...defaultConfig.headers,
            'Content-Type': contentType
        }
    };
    return await axios.post('/api/save_blob', data, config);
}

export function useBlobMutate() {
    const mutate = useMutation({
        mutationFn: PostData,
    });

    return mutate;
}