import { useState } from "react";

import BasePage from "../common/base-page";
import FormSimulation from "../components/prices-api/form-simulation/form-simulation";
import SimulationPrices from "../components/prices-api/simulation-prices/simulation-prices";
import { Button } from "../common/button";
import FormSystem from "../components/prices-api/form-system/form-system";
import SystemConsultedValues from "../components/prices-api/system_consulted_values/system_consulted_values";
import TablePhotovoltaicSystems from "../components/prices-api/table_photovoltaic_systems/table_photovoltaic_systems";
import { IPhotovoltaicSystems } from "../types/photovoltaic-systems";

export const PRICES_API_URL = 'https://staging.greendex.info';
export const PRICES_API_AUTHORIZATION = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDA4MzY1MjEsInVzZXJfaWQiOiJjMjZmNGVhOS00YTYwLTQ4MzQtYTY4Yi02NjdkMjQ3NTQ2N2UiLCJ1c2VybmFtZSI6ImNvbnRhdG9AZ3JlZW5kZXguaW5mbyJ9._qkkaymItS4UWEyGxZhMsZZMahXj6IUimr3V99K8Kvc';

interface IPrice {
    "min_price": number;
    "price_average": number;
    "max_price": number;
    "photovoltaic_systems": IPhotovoltaicSystems[];
};

function PricesApi() {
    const [view, setView] = useState<'simulation' | 'system'>('simulation');

    const [state, setState] = useState<string>();
    const [city, setCity] = useState<string>();
    const [averageBill, setAverageBill] = useState<number>();

    const [size, setSize] = useState<number>();
    const [minPrice, setMinPrice] = useState<number>();
    const [maxPrice, setMaxPrice] = useState<number>();
    const [averagePrice, setAveragePrice] = useState<number>();
    const [photovoltaicSystems, setPhotovoltaicSystems] = useState<IPhotovoltaicSystems[]>();

    const [consultedSize, setConsultedSize] = useState<number>();
    const [costExpectation, setCostExpectation] = useState<number>();
    const [filteredMinPrice, setFilteredMinPrice] = useState<number>();
    const [filteredMaxPrice, setFilteredMaxPrice] = useState<number>();
    const [filteredAveragePrice, setFilteredAveragePrice] = useState<number>();
    const [filteredPhotovoltaicSystems, setFilteredPhotovoltaicSystems] = useState<IPhotovoltaicSystems[]>();

    const [consultedSizeShow, setConsultedSizeShow] = useState<number>();
    const [costExpectationShow, setCostExpectationShow] = useState<number>();

    function handleSize(kwp: number) {
        setSize(kwp);
        getPrices(kwp);
    }

    function handleState(_state: string) {
        setState(_state);
    }

    function handleCity(_city: string) {
        setCity(_city);
    }

    function handleAverageBill(_averageBill: number) {
        setAverageBill(_averageBill);
    }

    function getPrices(kwp: number) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Authorization': PRICES_API_AUTHORIZATION
            },
            body: JSON.stringify({
                size: kwp,
                uf: state
            })
            
        };
        fetch(`${PRICES_API_URL}/prices`, requestOptions)
            .then(response => response.json())
            .then((data: IPrice) => {
                setMinPrice(data.min_price);
                setMaxPrice(data.max_price);
                setAveragePrice(data.price_average);
                setPhotovoltaicSystems(data.photovoltaic_systems);
            })
            .catch(e => console.log(e));
    }

    function handleConsultedSize(size: number) {
        setConsultedSize(size);
    }
    
    function handleCostExpectation(cost: number) {
        setCostExpectation(cost);
    }
    
    function handleFilteredPrice(price: IPrice) {
        setFilteredPhotovoltaicSystems(price.photovoltaic_systems);
        setFilteredMinPrice(price.min_price);
        setFilteredAveragePrice(price.price_average);
        setFilteredMaxPrice(price.max_price);
        setConsultedSizeShow(consultedSize);
        setCostExpectationShow(costExpectation);
    }

    return (
        <BasePage title={"API Preço dos Sistemas Fotovoltaicos"}>
            <>
                {view === 'simulation' && (
                    <>
                        <section className="d-flex">
                            <p
                                className="text-body text-xlarge text-bold"
                                style={{ borderBottom: '3px solid #23B385', width: 'max-content', paddingBottom: '4px', marginRight: '16px' }}
                            >
                                Simulação
                            </p>
                            <p
                                className="text-body text-xlarge text-bold color-greyscale-07"
                                style={{ borderBottom: '3px solid #A7E1CE', width: 'max-content', paddingBottom: '4px' }}
                            >
                                Sistema
                            </p>
                        </section>
                        <FormSimulation
                            setKwp={handleSize}
                            btn={size ? 'btn-primary-outline' : 'btn-primary'}
                            state={state}
                            city={city}
                            billAverage={averageBill}
                            setSelectedState={handleState}
                            setSelectedCity={handleCity}
                            setSelectedBillAverage={handleAverageBill}
                        />
                        {size && minPrice && averagePrice && maxPrice && (
                            <>
                                <SimulationPrices
                                    size={size}
                                    minPrice={minPrice}
                                    averagePrice={averagePrice}
                                    maxPrice={maxPrice}
                                />
                                <Button.Primary
                                    btnType="btn-primary"
                                    onClick={() => setView('system')}
                                    style={{ width: '100%' }}
                                >
                                    {"Próximo passo >>"}
                                </Button.Primary>
                            </>
                        )}
                    </>
                )}
                {view === 'system' && state && (
                    <>
                        <section className="d-flex">
                            <p
                                className="text-body text-xlarge text-bold color-greyscale-07"
                                style={{ borderBottom: '3px solid #A7E1CE', width: 'max-content', paddingBottom: '4px', marginRight: '16px' }}
                            >
                                Simulação
                            </p>
                            <p
                                className="text-body text-xlarge text-bold"
                                style={{ borderBottom: '3px solid #23B385', width: 'max-content', paddingBottom: '4px' }}
                            >
                                Sistema
                            </p>
                        </section>
                        {size && minPrice && averagePrice && maxPrice && (
                            <FormSystem
                                state={state}
                                setKwp={handleConsultedSize}
                                setCost={handleCostExpectation}
                                setFilteredPrice={handleFilteredPrice}
                                suggestedSize={size}
                                minPrice={minPrice}
                                averagePrice={averagePrice}
                                maxPrice={maxPrice}
                            />
                        )}
                        {filteredPhotovoltaicSystems && filteredMinPrice &&
                            filteredAveragePrice && filteredMaxPrice && consultedSizeShow && (
                            <SystemConsultedValues
                                size={consultedSizeShow}
                                minPrice={filteredMinPrice}
                                averagePrice={filteredAveragePrice}
                                maxPrice={filteredMaxPrice}                                
                            />
                        )}
                        {filteredPhotovoltaicSystems && (
                            <div style={{ width: '100%', maxHeight: '400px', overflow: 'scroll' }}>
                                <TablePhotovoltaicSystems photovoltaicSystems={filteredPhotovoltaicSystems} />
                            </div>
                        )}
                        <Button.Primary
                            btnType="btn-primary"
                            onClick={() => {
                                setView('simulation');
                                setFilteredPhotovoltaicSystems(undefined);
                            }}
                            style={{ width: '100%' }}
                        >
                            {"<< Anterior"}
                        </Button.Primary>
                    </>
                )}
            </>
        </BasePage>
    );
}
export default PricesApi;