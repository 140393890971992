import { useEffect, useState } from "react";

import When from "../common/when/when";
import styles from "../components/product/product-header/product-header.module.css";
import Spinner from "../common/spinner/spinner";
import AuthGuard from "../guards/auth-guard";
import { useAcessoDashData } from "../hooks/useAcessoDashData";

const DashProducaoStage = () => {
    const { data, isLoading } = useAcessoDashData();
    const [token, setToken] = useState<string>();
    const [userId, setUserId] = useState<string>();
    const [productId, setProductId] = useState<string>();

    useEffect(() => {
        if (data) {
            setToken(data.token);
            setUserId(data.user_id);
            setProductId(data.product_id);
        }
    }, [data]);

    return (
        <AuthGuard
            allow="greener"
            notAllowMessage={
                <div className="container" style={{ padding: '60px 0 180px 0' }}>
                    <h3 className={"title-h3 text-bold"}>
                        Página exclusiva para administradores
                    </h3>
                </div>
            }
        >
            <>
                <When expr={token && userId && productId}>
                    <div>
                        <div className="container-fluid bg-primary-01">
                            <header
                                className={"row color-white container"}
                                style={{ paddingTop: "50px", paddingBottom: "50px", margin: 'auto' }}
                            >
                                <h3 className={"title-h3 text-bold " + styles["title"]}>
                                    Prices Produção x Stage
                                </h3>
                                <p className={"text-body text-medium text-w-medium " + styles["description"]}
                                >
                                    Dashboard de uso interno
                                </p>
                            </header>
                        </div>
                        <iframe
                            src={`https://greendex.streamlit.app/?embed=true&token=${token}&user_id=${userId}&product_id=${productId}`}
                            frameBorder="0"
                            style={{ width: '100%', height: '100vh', border: '3px solid #F2F4F2' }}
                        ></iframe>
                    </div>
                </When>
                <When expr={!(token && userId && productId)}>
                    <div className="container" style={{ padding: '60px 0 180px 0' }}>
                        <h3 className={"title-h3 text-bold"}>
                            Não foi possível acessar o Dashboard
                        </h3>
                    </div>
                </When>
                {isLoading && <Spinner />}
            </>
        </AuthGuard>
    );
}

export default DashProducaoStage