import React, { RefObject } from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './pagination.module.css';

import Spinner from '../../../common/spinner/spinner';
import PaginationButtons from '../pagination-buttons/pagination-buttons';
import PaginationCards from '../pagination-cards/pagination-cards';
import PaginationFilter from '../pagination-filter/pagination-filter';
import usePagination from '../../../hooks/usePagination';
import { useHomeData } from '../../../hooks/useHomeData';

function Pagination(props: { type: 'home' | 'plansAndValues' }) {
    const { data, isLoading } = useHomeData();
    const {
        totalProducts,
        pageProducts,
        currentPage,
        productsPerPage,
        nextPage,
        previousPage,
        setCurrentPage,
        applyFilter
    } = usePagination(props.type, data.products);

    const paginationSection: RefObject<HTMLElement> = React.createRef();
    function scrollToTop() {
        if(paginationSection.current) {
            window.scrollTo({
                top: paginationSection.current.offsetTop,
                behavior: "smooth",
            });
        }
    }

    function handleChangePagePagination(change: string | number) {
        if(change === 'next')
            nextPage();
        else if (change === 'previus')
            previousPage();
        else if (typeof change === 'number')
            setCurrentPage(change);
        else return;
        scrollToTop();
    }

    const [filter, setFilter] = useState<string>('todas');
    function handleChangeFilter(value: string) {
        setFilter(value);
        applyFilter(value);
        scrollToTop();
    }

    const location = useLocation();
    useEffect(() => {
        if(location.hash === '') return;
        setTimeout(() => {
            const category = location.hash.replace('#', '');
            handleChangeFilter(category);
        }, 0);
    }, [location]);

    return (
        <section className="container" ref={paginationSection}>
            {props.type === 'home' && (
                <div className={styles['title-wrapper']}>
                    <h3 className="title-h3 text-w-medium">Navegue por categoria</h3>
                </div>
            )}
            {props.type === 'plansAndValues' && (
                <div className={styles['title-wrapper-plans']}>
                    <h4 className="title-h4" style={{ fontWeight: '300' }}>Navegue por categoria</h4>
                </div>
            )}
            <div className={styles['filter-wrapper']}>
                <PaginationFilter filterValue={filter} onChangeFilter={handleChangeFilter} />
            </div>
            <PaginationCards
                products={pageProducts}
                type={props.type}
            />
            <div className="d-flex justify-content-center">
                <PaginationButtons
                    currentPage={currentPage}
                    lastPage={Math.ceil(totalProducts/productsPerPage)}
                    onChangePagination={handleChangePagePagination}
                />
            </div>
            {isLoading && <Spinner />}
        </section>
    );
}
export default Pagination;