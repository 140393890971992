import { Button } from '../../button';

interface BuyButtonProps {
    id: string
}

const BuyButton = ({ id }: BuyButtonProps) => {
    return (
        <Button.Link
            btnType="btn-primary"
            to='/checkout/identificacao'
            onClick={() => {
                localStorage.setItem('productType', id.includes("importacao") ? 'importacao' : 'product');
                localStorage.setItem('productId', id);
            }}
            style={{
                width: '100%',
                padding: '9px 0',
                fontSize: '14px',
                marginBottom: '12px'
            }}
        >
            Assine agora
        </Button.Link>
    );
}

export default BuyButton