import Indicator from './indicator';
import IndicatorActive from './indicator-active';
import IndicatorList from './indicator-list';

interface CarouselIndicatorProps {
    length: number,
    current: number,
    show: number,
    onSetCarousel: Function
}

const CarouselIndicator = (props: CarouselIndicatorProps) => {
    function handleClick(id: number) {
        props.onSetCarousel(id);
    }

    return (
        <IndicatorList>
            {[...Array(props.length)].map((_, index) => {
                if (props.current === index)
                    return (
                        <IndicatorActive key={index} />
                    );
                if(index < (props.length - props.show))
                    return (
                        <Indicator
                            key={index}
                            onClick={() => handleClick(index)}
                        />
                    );
                return null;
            })}
        </IndicatorList>
    );
}
export default CarouselIndicator;