import { useEffect } from 'react';
import { useState } from 'react';

import BasePageAdmin from './base-page-admin';
import Header from './header';
import PostActions from './post-actions';
import PostContent from './post-content';
import PostItemContainer from './post-item-container';

import Spinner from '../../common/spinner/spinner';
import When from '../../common/when/when';
import PaginationButtons from '../../components/home/pagination-buttons/pagination-buttons';
import { useBlogData } from '../../hooks/useBlogData';
import { useBlogPostDelete } from '../../hooks/useBlogPostDelete';
import styles from '../../styles/purchases-historic.module.css';
import { PostData } from '../../types/blog';
import AuthGuard from '../../guards/auth-guard';

function AdminBlog() {
    const [postPage, setPostPage] = useState<number>();
    const { data, isLoading } = useBlogData(postPage);

    const [posts, setPosts] = useState<PostData[] | []>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState<number>(0);

    useEffect(() => {
        if (data) {
            setPosts(data.posts);
            setCurrentPage(data.metadados.pagina);
            setLastPage(data.metadados.pagina_limite);
        }
    }, [data]);

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    function handleChangePagePagination(change: string | number) {
        if (change === 'next' && currentPage < lastPage)
            setPostPage(currentPage + 1);
        else if (change === 'previus' && currentPage > 1)
            setPostPage(currentPage - 1);
        else if (typeof change === 'number')
            setPostPage(change);
        else
            return;
        scrollToTop();
    }

    const blogPostDelete = useBlogPostDelete();

    function handleDeletePost(id: number) {
        const data = { id };
        blogPostDelete.mutate(data);
    }

    return (
        <AuthGuard
            allow="administrador"
            notAllowMessage={
                <div className="container" style={{ padding: '60px 0 180px 0' }}>
                    <h3 className={"title-h3 text-bold"}>
                        Página exclusiva para administradores
                    </h3>
                </div>
            }
        >
            <div className="bg-grey">
                <BasePageAdmin>
                    <Header />
                    <ul className={styles['list']}>
                        {posts.map(post => (
                            <PostItemContainer>
                                <PostContent
                                    title={post.title}
                                    description={post.description}
                                    image_url={post.image_url}
                                />
                                <PostActions
                                    id={post.id}
                                    page_path={post.page_path}
                                    handleDeletePost={handleDeletePost}
                                />
                            </PostItemContainer>
                        ))}
                        <When expr={posts.length === 0}>
                            <p className="text-body text-large text-w-medium color-greyscale-01">
                                Nenhum post encontrado
                            </p>
                        </When>
                    </ul>
                    <div className="d-flex justify-content-center">
                        <PaginationButtons
                            currentPage={currentPage}
                            lastPage={lastPage}
                            onChangePagination={handleChangePagePagination}
                        />
                    </div>
                </BasePageAdmin>
                {(isLoading || blogPostDelete.isLoading) && <Spinner />}
            </div>
        </AuthGuard>
    );
}
export default AdminBlog;