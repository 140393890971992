import NavItem from './nav-item';
import iconMeusDados from '../../assets/images/icon-meus-dados.svg';
import iconMeusProdutos from '../../assets/images/icon-meus-produtos.svg';
import iconHistoricoCompras from '../../assets/images/icon-historico-compras.svg';
import iconGerenciarAssinaturas from '../../assets/images/icon-gerenciar-assinaturas.svg';
import styles from '../../styles/profile.module.css';
import When from '../../common/when/when';

const Nav = () => {
    const navItems = [
        {
            link: '/meus-dados',
            icon: iconMeusDados,
            title: 'Meus dados',
            description: 'Configure suas informações pessoais.'
        },
        {
            link: '/meus-produtos',
            icon: iconMeusProdutos,
            title: 'Meus produtos',
            description: 'Veja os produtos ativos na sua conta.'
        },
        // {
        //     link: '/historico-de-compras',
        //     icon: iconHistoricoCompras,
        //     title: 'Histórico de compras',
        //     description: 'Veja seu histórico de compras e pagamentos na plataforma.'
        // },
        // {
        //     link: '/gerenciar-assinaturas',
        //     icon: iconGerenciarAssinaturas,
        //     title: 'Gerenciar assinaturas',
        //     description: 'Veja todas as suas assinaturas ativas e/ou pendentes.'
        // },
    ];

    return (
        <nav className={styles['nav']}>
            {navItems.map((item, index) => (
                <>
                    <NavItem
                        key={item.title}
                        link={item.link}
                        icon={item.icon}
                        title={item.title}
                        description={item.description}
                    />
                    <When expr={index !== (navItems.length - 1)}>
                        <div className={styles['line']}></div>
                    </When>
                </>
            ))}
        </nav>
    );
}

export default Nav