import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { useHeaderConfig } from "./useHeaderConfig";

interface AcessoDashData {
  token: string;
  user_id: string;
  product_id: string;
}

const FetchData = async (): Promise<AxiosResponse<AcessoDashData>> => {
  const config = useHeaderConfig();
  const response = await axios.get<AcessoDashData>(
    `/api/acesso-dash?product_id=dash-qa`,
    config
  );
  return response;
};

export function useAcessoDashData() {
  const query = useQuery({
    queryFn: () => FetchData(),
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    data: query.data?.data,
  };
}
