import { createSlice } from "@reduxjs/toolkit"

interface IPackageState {
    id: number,
    packages: any[] // mudar depois
}

const initialState: IPackageState = {
    id: 0,
    packages: []
}

const packageSlice = createSlice({
    name: 'package',
    initialState,
    reducers: {
        setPackages: (state, action) => {
            state.packages = action.payload;
        },
        setSlidePackage: (state, action) => {
            state.id = action.payload.id;
        },
        nextSlidePackage: state => {
            if(state.id < (state.packages.length - 1)) {
                state.id++;
            }
        },
        previousSlidePackage: state => {
            if(state.id > 0) {
                state.id--;
            }
        }
    }
});

export const {
    setPackages,
    setSlidePackage,
    nextSlidePackage,
    previousSlidePackage
} = packageSlice.actions;

export const selectCarouselPackage =
    (state: { package: IPackageState }) => state.package;

    export default packageSlice.reducer;