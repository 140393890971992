import { RiCloseFill } from "react-icons/ri";

interface TopBarProps {
    title: string,
    onClose: Function
}

const TopBar = ({ title, onClose }: TopBarProps) => {
    return (
        <div className="d-flex justify-content-between"
            style={{ width: '100%', padding: '0 20px' }}
        >
            <h5 className="title-h5 color-greyscale-04">
                {title}
            </h5>
            <RiCloseFill
                className='color-greyscale-04'
                onClick={() => onClose()}
                style={{ fontSize: '24px', cursor: 'pointer' }}
            />
        </div>
    );
}

export default TopBar