import React, { useEffect, useState } from "react";
import Compra from "../types/compra";
import StatusCompra from "../types/status_compra";
import Usuario from "../types/usuario";
// import "./views.css";
function Compras() {
  const [loading, setLoading] = useState(true);
  const [_compras, setCompras] = useState<Compra[]>([]);
  useEffect(() => {
    fetch(`/api/compras`).then((response) => {
      response.json().then((_json) => {
        const resources: Array<any> = _json["compras"];
        const compras: Compra[] = [];
        for (var i = 0; i < resources.length; i++) {
          const c = resources.at(i);
          compras.push({
            id: c?.id,
            buy_date: c?.buy_date,
            buy_price: c?.buy_price,
            status: c?.status,
            product: {
              id: c?.product.id,
              name: c?.product.name,
              description: c?.product.description,
              paragraph1: c?.product.paragraph1,
              paragraph2: c?.product.paragraph2,
              price: c?.product.price,
              image: c?.product.image,
              type: c?.product.type,
              access: false,
            },
            user: new Usuario({ email: c?.user.email }),
          });
        }
        setCompras(compras);
        setLoading(false);
      });
    });
  }, []);
  function aprovar(id: number): void {
    fetch(`/api/webhook-compras?id=${id}&action=aprovar`).then((response) => {
      console.log("Aprovação: ", response.status);
    });
  }
  function cancelar(id: number): void {
    fetch(`/api/webhook-compras?id=${id}&action=cancelar`).then((response) => {
      console.log("Cancelamento: ", response.status);
    });
  }
  return (
    <div>
      {loading ? (
        <span>CARREGANDO</span>
      ) : (
        <ul>
          {_compras.map((item, index) => {
            console.log(`Item ${index}`, item);
            let badgeClasses;
            if (item.status == StatusCompra.pending)
              badgeClasses = "badge badge-primary";

            if (item.status == StatusCompra.complete)
              badgeClasses = "badge badge-success";

            if (item.status == StatusCompra.canceled)
              badgeClasses = "badge badge-danger";
            console.log("Badge:", badgeClasses);
            return (
              <li>
                <span>
                  {item.user.email}: {item.product.id} [
                  {item.buy_price.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  ] -{" "}
                </span>
                <span className={badgeClasses}>
                  {Compra.getStatusLabel(item.status)}
                </span>

                <div style={{ margin: "8px", display: "inline" }}>
                  {/* BOTÕES DE STATUS PENDENTE */}
                  {item.status == StatusCompra.pending ? (
                    <>
                      <button
                        type="button"
                        className={"btn btn-success btn-sm"}
                        onClick={() => {
                          aprovar(item.id);
                        }}
                      >
                        Aprovar
                      </button>
                      <button
                        type="button"
                        className={"btn btn-danger btn-sm"}
                        onClick={() => {
                          cancelar(item.id);
                        }}
                      >
                        Recusar
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* BOTÕES DE STATUS APROVADO */}
                  {item.status == StatusCompra.complete ? (
                    <>
                      <button
                        type="button"
                        className={"btn btn-dark btn-sm"}
                        onClick={() => {
                          cancelar(item.id);
                        }}
                      >
                        Cancelar
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
export default Compras;
