import styles from '../../styles/purchases-historic.module.css';
import { Button } from '../../common/button';
import { Link } from 'react-router-dom';


const Header = () => {
    return (
        <div className={"row " + styles["row-text"]}>
            <div
                className="d-flex justify-content-between"
                style={{
                    width: "100%",
                    marginBottom: "24px",
                    flexWrap: "wrap",
                    gap: "16px",
                }}
            >
                <h3 className={"title-h3 text-bold " + styles["title"]}>
                    Gerenciar Produtos
                </h3>
                <Button.Primary
                    btnType='btn-primary'
                >
                    <Link
                        to="/admin/produtos-internos/add"
                        style={{ color: '#fff' }}
                    >+ Adicionar</Link>
                </Button.Primary>
            </div>
        </div>
    );
}

export default Header