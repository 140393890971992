interface TitleProps {
    title: string
}

const Title = ({ title }: TitleProps) => {
    return (
        <div style={{ height: '65px' }}>
            <h6 className="title-h6 text-bold color-dark-03">{title}</h6>
            {/* <p className="text-body text-large color-greyscale-03">{props.description}</p> */}
        </div>
    );
}

export default Title