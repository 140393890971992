import { HTMLAttributes } from "react";

import styles from './styles.module.css';

interface CardWrapperProps extends HTMLAttributes<HTMLDivElement> {}

const CardWrapper = (props: CardWrapperProps) => {
    return (
        <div className={'card ' + styles.card} {...props} />
    );
}

export default CardWrapper