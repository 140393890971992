import { SetStateAction } from "react";
import { FaChevronLeft } from "react-icons/fa";

import styles from './carousel-header.module.css';

interface BtnLeftArrowProps {
    index: number;
    setIndex: (value: SetStateAction<number>) => void
}

const BtnLeftArrow = ({index, setIndex}: BtnLeftArrowProps) => {
    return (
        <button
            onClick={() => setIndex(index => --index)}
            disabled={index === 0}
            className={
                styles['left-arrow'] +
                ( index === 0 
                    ? ' ' + styles['arrow-disabled'] : ''
                )
            }
        >
            <FaChevronLeft />
        </button>
    );
}

export default BtnLeftArrow