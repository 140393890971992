import React, { RefObject } from 'react';
import { useEffect, useState } from 'react';

import Spinner from '../common/spinner/spinner';
import BlogPaginationCards from '../components/blog/blog-pagination-cards/blog-pagination-cards';
import PaginationButtons from '../components/home/pagination-buttons/pagination-buttons';
import { useBlogData } from '../hooks/useBlogData';
import { PostData } from '../types/blog';

function Blog() {
    const [postPage, setPostPage] = useState<number>();
    const { data, isLoading } = useBlogData(postPage);

    const [currentPage, setCurrentPage] = useState(1);
    const [posts, setPosts] = useState<PostData[]>([]);
    const [lastPage, setLastPage] = useState<number>(0);

    useEffect(() => {
        if(data) {
            setPosts(data.posts);
            setCurrentPage(data.metadados.pagina);
            setLastPage(data.metadados.pagina_limite);
        }
    }, [data])

    const paginationSection: RefObject<HTMLElement> = React.createRef();
    function scrollToTop() {
        if(paginationSection.current) {
            window.scrollTo({
                top: paginationSection.current.offsetTop,
                behavior: "smooth",
            });
        }
    }

    function handleChangePagePagination(change: string | number) {
        if(change === 'next' && currentPage < lastPage)
            setPostPage(currentPage + 1);
        else if (change === 'previus' && currentPage > 1)
            setPostPage(currentPage - 1);
        else if (typeof change === 'number')
            setPostPage(change);
        else
            return;
        scrollToTop();
    }

    return (
        <main>
            <section className="container" ref={paginationSection}>
                <div style={{ padding: '50px 0' }}>
                    <h3 className="title-h3 text-w-medium color-primary-01">Blog</h3>
                </div>
                <BlogPaginationCards posts={posts} />
                <div className="d-flex justify-content-center">
                    <PaginationButtons
                        currentPage={currentPage}
                        lastPage={lastPage}
                        onChangePagination={handleChangePagePagination}
                    />
                </div>
            </section>
            {isLoading && <Spinner />}
        </main>
    );
}
export default Blog;