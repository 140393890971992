import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { BlogData } from "../types/blog";
import { useHeaderConfig } from "./useHeaderConfig";

const mountQueryUrlAndKey = (page: number | undefined) => {
    if(page)
        return {
            url: `/api/blog?pagina=${page}`,
            key: ['blog-data', page]
        };
    return {
        url: `/api/blog`,
        key: ['blog-data']
    }
}

const FetchData = async (url: string): Promise<AxiosResponse<BlogData>> => {
    const config = useHeaderConfig();
    const response = await axios.get<BlogData>(url, config);
    return response;
};

export function useBlogData(page?: number) {
    const { url, key } = mountQueryUrlAndKey(page);
    const query = useQuery({
        queryFn: () => FetchData(url),
        queryKey: key,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        ...query,
        data: query.data?.data
    };
}