import { BsPencilSquare } from "react-icons/bs";

interface EditableFieldInputDataProps {
    value: string | undefined;
    onShowInput: () => void;
}

const EditableFieldInputData = ({ value, onShowInput }: EditableFieldInputDataProps) => {
    return (
        <div className="d-flex justify-content-between">
            <p className="text-body text-large color-greyscale-03">
                {value}
            </p>
            <BsPencilSquare
                className="color-greyscale-03"
                style={{ cursor: 'pointer' }}
                onClick={onShowInput}
            />
        </div>
    );
}

export default EditableFieldInputData