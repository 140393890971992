import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { ProfileData } from "../types/profile";
import { useHeaderConfig } from "./useHeaderConfig";

const FetchData = async (): Promise<AxiosResponse<ProfileData>> => {
    const config = useHeaderConfig();
    // console.log(config)
    const response = await axios.get<ProfileData>('/api/perfil', config);
    return response;
};

export function useProfileData() {
    const queryClient = useQueryClient();
    const query = useQuery({
        queryFn: FetchData,
        queryKey: ['profile-data'],
        onSuccess: () => {
            queryClient.invalidateQueries([
                'auth-me-data',
                'manage-subscriptions-data',
                'billing-profile-data',
                'product-data',
                'filter-data',
                'my-products-data',
                'purchase-historic-data'
            ]);
        }
    });

    return {
        ...query,
        profile: query.data?.data
    };
}