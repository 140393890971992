import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { ProductPageData } from "../types/product";
import { PackagePageData } from "../types/package";
import { useHeaderConfig } from "./useHeaderConfig";

const mountUrl = (id: string) => {
    if(id.includes('pacote')) return `/api/produtos?pacote_id=${id}`
    return `/api/produtos?id=${id}`;
}

const FetchData = async (url: string): Promise<AxiosResponse<ProductPageData & PackagePageData>> => {
    const config = useHeaderConfig();
    const response = await axios.get<ProductPageData & PackagePageData>(url, config);

    console.log(response)
    
    let mediasLinks = response.data.detalhes_pagina.medias_links
    if(typeof mediasLinks === 'string') {
        const mediasLinksString = response.data.detalhes_pagina.medias_links as unknown as string;
        mediasLinks = mediasLinksString.replace('{', '').replace('}', '').split(',');
    }
    
    let responseWithMediasLinks = response;
    responseWithMediasLinks.data.detalhes_pagina.medias_links = mediasLinks;

    return responseWithMediasLinks;
};

export function useProductData(id: string) {
    const url = mountUrl(id);

    const query = useQuery({
        queryFn: () => FetchData(url),
        queryKey: ['product-data', id],
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        ...query,
        productData: query.data?.data
    };
}