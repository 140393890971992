
interface CardContentProps {
    title: string;
    description: string;
    children?: any;
}

const CardContent = (props: CardContentProps) => {
    return (
        <div
            className={'card-body'}
            style={{ padding: '24px 16px' }}
        >
            <h6
                className="title-h6 text-bold color-dark-03"
                style={{ marginBottom: '16px' }}
            >
                {props.title}
            </h6>
            <p className="text-body text-large color-greyscale-03 card-description">
                {props.description}
            </p>
            {props?.children}
        </div>
    );
}

export default CardContent