import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { RegisterMutate } from "../types/register";
import { useHeaderConfig } from "./useHeaderConfig";
import { useRdRegisterMutate } from "./useRdRegisterMutate";

const PostData = async (data: RegisterMutate): Promise<any> => {
    const config = useHeaderConfig();
    return await axios.post('/api/cadastro', data);
}

export function useRegisterMutate(path?: string) {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const rdRegisterMutate = useRdRegisterMutate();
    
    const mutate = useMutation({
        mutationFn: PostData,
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries(['profile-data', 'auth-me-data']);

            if(variables.email) {
                rdRegisterMutate.mutate({
                    event_type: 'CONVERSION',
                    event_family: 'CDP',
                    payload: {
                      conversion_identifier: 'cadastro-greendex',
                      email: variables.email,
                      name: variables.nome,
                      job_title: variables.setor,
                      company_name: variables.empresa
                    }
                });
            } else {
                const userData = localStorage.getItem('auth@aad');
                if (!userData) return;
                const userJson = JSON.parse(userData);
                const userEmail = userJson.userDetails;

                rdRegisterMutate.mutate({
                    event_type: 'CONVERSION',
                    event_family: 'CDP',
                    payload: {
                      conversion_identifier: 'cadastro-greendex',
                      email: userEmail,
                      name: variables.nome,
                      job_title: variables.setor,
                      company_name: variables.empresa
                    }
                });
            }

            if(path)
                navigate(path);
            // else
            //     window.location.reload();
        }
    });

    return mutate;
}