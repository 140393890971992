import { useState } from "react";

import NavManageView from "./nav-manage-view";
import SubscriptionsList from "./subscriptions-list";
import SubscriptionsUserList from "./subscriptions-user-list";

import BasePage from "../../common/base-page";
import When from "../../common/when/when";
import styles from '../../styles/admin-user.module.css';
import { IAcessos } from "../../types/user";

interface SectionManageSubscriptionsProps {
    subscriptionsUser: IAcessos[],
    handleOpenModalAddConfirm: Function,
    handleOpenModalManage: Function
}

const SectionManageSubscriptions = ({ subscriptionsUser, handleOpenModalAddConfirm, handleOpenModalManage }: SectionManageSubscriptionsProps) => {
    const [manageView, SetManageView] = useState('disponiveis');

    return (
        <div className={styles['mb-section']}>
            <BasePage
                title="Gerenciar Assinaturas"
            >
                <>
                    <NavManageView
                        manageView={manageView}
                        SetManageView={SetManageView}
                    />
                    <section>
                        <When expr={manageView === 'disponiveis' }>
                            <SubscriptionsList
                                handleOpenModalAddConfirm={handleOpenModalAddConfirm}
                            />
                        </When>
                        <When expr={manageView !== 'disponiveis' }>
                            <SubscriptionsUserList
                                subscriptionsUser={subscriptionsUser}
                                handleOpenModalManage={handleOpenModalManage}
                            />
                        </When>
                    </section>
                </>
            </BasePage>
        </div>
    );
}

export default SectionManageSubscriptions