import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import profileAPI from '../apis/ProfileAPI';
import { ProfileData, ProfileState } from '../types/profile';

const initialState: ProfileState = {
    loading: false,
    profile: null,
};

export const fetchProfileData = createAsyncThunk(
    'profile/fetchProfileData',
    async () => {
        const response: ProfileData = await profileAPI.fetchProfileData();
      return response;
    }
);

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        logout: state => {
            state.profile = null;
        },
        setProfile: (state, action) => {
            state.profile = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProfileData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchProfileData.fulfilled, (state, action) => {
                state.loading = false;
                state.profile = action.payload;
            })
            .addCase(fetchProfileData.rejected, (state, action) => {
                state.loading = false;
                console.log(action.error.message);
            })
    },
});

export const { logout, setProfile } = profileSlice.actions;

export const selectLoading = 
    (state: { profile: ProfileState }) => state.profile.loading;
export const selectProfile = 
    (state: { profile: ProfileState }) => state.profile.profile;

export default profileSlice.reducer;