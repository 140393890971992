import { Dispatch, SetStateAction } from 'react';

import styles from '../../styles/admin-user.module.css';

interface NavManageVieProps {
    manageView: string,
    SetManageView: Dispatch<SetStateAction<string>>
}

const NavManageView = ({ manageView, SetManageView }: NavManageVieProps) => {
    return (
        <nav className={styles['manage-nav']}>
            <span
                className={manageView === 'disponiveis' ? styles['active'] : ''}
                onClick={() => SetManageView('disponiveis')}
            >
                Disponíveis
            </span>
            <span
                className={manageView === 'ativas' ? styles['active'] : ''}
                onClick={() => SetManageView('ativas')}
            >
                Ativas
            </span>
        </nav>
    );
}

export default NavManageView