import BreadcrumbItem from './breadcrumb-item';
import BreadcrumbList from './breadcrumb-list';

function Breadcrumb(props: { children: any[] }) {
    return (       
        <BreadcrumbList>
            {props.children.map((item, index) => (
                <BreadcrumbItem
                    key={index}
                    index={index}
                    listLength={props.children.length}
                >
                    {item}
                </BreadcrumbItem>
            ))}
        </BreadcrumbList>
    );
}
export default Breadcrumb;