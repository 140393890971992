import priceFormatter from "../../../app/formatter";

interface ISystemConsultedValues {
    size: number;
    minPrice: number;
    averagePrice: number;
    maxPrice: number;
}

function SystemConsultedValues(props: ISystemConsultedValues) {
    return (
        <section
            className="d-flex justify-content-around"
            style={{
                padding: '14px 16px',
                background: '#F2F2F2',
                border: '0.5px solid #E5E5E5',
                borderRadius: '6px',
            }}
        >
            <div style={{ textAlign: 'center', width: '50%' }}>
                <p
                    className="text-body color-greyscale-03"
                    style={{ marginBottom: '8px' }}
                >
                    Porte do Sistema<br/>Consultado
                </p>
                <p className="text-body text-xlarge text-bold color-greyscale-03">{props.size} kWp</p>
            </div>
            <div className="bg-greyscale-06" style={{ width: '5px' }}></div>
            <div style={{ textAlign: 'center', flexGrow: '1' }}>
                <p className="text-body color-greyscale-03" style={{ marginBottom: '8px' }}>
                    Valor mínimo <span className="text-bold">{priceFormatter(props.minPrice)}</span>
                </p>
                <p className="text-body color-greyscale-03" style={{ marginBottom: '8px' }}>
                    Valor médio <span className="text-bold">{priceFormatter(props.averagePrice)}</span>
                </p>
                <p className="text-body color-greyscale-03">
                    Valor máximo <span className="text-bold">{priceFormatter(props.maxPrice)}</span>
                </p>
            </div>
        </section>
    );
}
export default SystemConsultedValues;