interface ButtonAddImageProps {
    handleImageInput: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const ButtonAddImage = ({ handleImageInput }: ButtonAddImageProps) => {
    return (
        <input
            className="input-register"
            type="file"
            accept='image/*'
            onChange={e => handleImageInput(e)}
        />
    );
}

export default ButtonAddImage