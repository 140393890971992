import { Actions } from './actions';
import styles from './styles.module.css';
import Title from './title';
import When from '../when/when';
import DiscontinuedProductActions from './actions/discontinued-product-actions';

interface CardContentProps {
    id: string;
    title: string;
    payment_link: string;
    periodo_faturamento: string;
    price?: number;
    preco_anual?: number; //atualmente usado para o valor semestral
    preco_semestral?: number; //atualmente usado para o valor trimestral
}

const CardContent = (props: CardContentProps) => {
    return (
        <div className={'card-body ' + styles['card-body']}>
            <Title title={props.title} />
            <hr />
            <DiscontinuedProductActions
                id={props.id}
                title={props.title}
                type={props.price ? 'produto' : 'pacote'}
            />
            {/* <When expr={props.price}>
                <Actions.Product
                    id={props.id}
                    title={props.title}
                    price={props.price!}
                    periodo_faturamento={props.periodo_faturamento}
                    payment_link={props.payment_link}
                />
            </When>
            <When expr={props.preco_anual && props.preco_anual !== -1}>
                <Actions.SemesterPackage
                    id={props.id}
                    title={props.title}
                    preco_anual={props.preco_anual!}
                />
            </When>
            <When expr={(!props.preco_anual || props.preco_anual === -1) && props.preco_semestral}>
                <Actions.QuarterlyPackage
                    id={props.id}
                    title={props.title}
                    preco_semestral={props.preco_semestral!}
                />
            </When>
            <When expr={props.price === 0}>
                <Actions.FreeProduct
                    id={props.id}
                    title={props.title}
                />
            </When> */}
        </div>
    );
}

export default CardContent