import { useState } from "react";

function useCarouselHeader(length: number) {
    const [index, setIndex] = useState(0);
    const [touchPosition, setTouchPosition] = useState<number | null>(null)

    function handleTouchStart(e: React.TouchEvent) {
        const touchDown = e.touches[0].clientX
        setTouchPosition(_ => touchDown);
    }

    function handleTouchEnd(e: React.TouchEvent) {
        if (touchPosition === null) return;

        const touchDown = touchPosition;
        const currentTouch = e.changedTouches[0].clientX;
        const diff = touchDown - currentTouch;
    
        if (diff > 5 && (index + 1) <= (length - 1)) {
           setIndex(index => ++index)
        }
        
        if (diff < -5 && (index - 1) >= 0) {
            setIndex(index => --index);
        }
    
        setTouchPosition(null);
    }

    return {
        index,
        setIndex,
        handleTouchStart,
        handleTouchEnd
    }
}

export default useCarouselHeader;