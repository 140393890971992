import { useEffect, useLayoutEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

import logoDark from "../assets/images/logo-dark.svg";
import logoLight from "../assets/images/logo-light.svg";
import { Button } from "../common/button";
import Input from "../common/input/input";
import Spinner from "../common/spinner/spinner";
import styles from "../styles/register-social.module.css";
import { useRegisterMutate } from "../hooks/useRegisterMutate";
import { useProfileData } from "../hooks/useProfileData";
import { useLogout } from "../hooks/useLogout";

function RegisterSocial() {
    const navigate = useNavigate();
    const { profile, isLoading } = useProfileData();

    const logout = useLogout();

    useLayoutEffect(() => {
        if(profile) {
            navigate("/");
        }
    }, [profile]);

    const occupationAreas = [
        "Integrador",
        "Distribuidor",
        "EPC",
        "Financiador",
        "Desenvolvedor",
        "Investidor",
        "Fabricante",
        "Serviços",
        "Consumidor",
        "Empresa de serviços públicos",
        "Outro",
    ];
    const [selectedOccupationArea, setSelectedOccupationArea] = useState("Outro");
    const [formSubmited, setFormSubmited] = useState(false);
    const [privacyPolicies, setPrivacyPolicies] = useState(false);
    const [formValue, setFormValue] = useState({
        name: { value: "", valid: false },
        company: { value: "", valid: false },
        password: { value: "", valid: false },
        confirmPassword: { value: "", valid: false },
    });

    function validateEntries() {
        //typescript não aceita fazer isso de forma iterativa
        return (
            privacyPolicies && formValue["name"].valid && formValue["company"].valid
        );
    }

    function setInputValue(inputName: string, value: string, valid: boolean) {
        setFormValue((state) => {
            return {
                ...state,
                [inputName]: { value, valid },
            };
        });
    }

    useEffect(() => {
        const formLeadStr = localStorage.getItem("formLead");
        if (formLeadStr) {
            const formLead: {
                email: string;
                empresa: string;
                setor: string;
            } = JSON.parse(formLeadStr);
            if(formLead.empresa)
                setInputValue("company", formLead.empresa, true);
            if(formLead.setor)
                setSelectedOccupationArea(formLead.setor);
        }
    }, []);

    const [loading, setLoading] = useState(false);
    const registerMutate = useRegisterMutate('/');

    function handleSubmit(e: any) {
        e.preventDefault();
        setFormSubmited(true);
        if (!validateEntries()) {
            return;
        }
        
        const data = {
            nome: formValue.name.value,
            empresa: formValue.company.value,
            setor: selectedOccupationArea,
        };
        console.log(data)
        registerMutate.mutate(data);
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className={"col-md-8 " + styles["col-img"]}>
                        <Link to="/">
                            <img src={logoLight} alt="logo Greendex" />
                        </Link>
                    </div>
                    <div className="col-md-4 col-xl-3 p-4">
                        <button
                            className={"d-flex align-items-center " + styles["back-button"]}
                            onClick={() => logout('post_logout_redirect_uri=/login')}
                        >
                            <FaChevronLeft className={styles["icon"]} />
                            <span
                                className={"text-body t-medium " + styles["back-button-text"]}
                            >
                                Voltar
                            </span>
                        </button>
                        <Link to="/">
                            <img
                                src={logoDark}
                                alt="logo Greendex"
                                className={styles["logo-dark"]}
                            />
                        </Link>
                        <h5
                            className="title-h5 text-semibold"
                            style={{ marginBottom: "24px" }}
                        >
                            Cadastro social
                        </h5>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <Input
                                inputName="name"
                                label="Nome de usuário"
                                placeholder="Defina um nome de usuário"
                                type="text"
                                errorMessage="* insira um nome válido"
                                formSubmited={formSubmited}
                                setInputValue={setInputValue}
                            />
                            <Input
                                inputName="company"
                                label="Empresa"
                                placeholder="Sua empresa"
                                type="text"
                                errorMessage="* insira uma empresa válida"
                                formSubmited={formSubmited}
                                setInputValue={setInputValue}
                                value={formValue["company"].value}
                            />
                            <p className="label-register">Área de atuação</p>
                            <div className={styles["select-wrapper"]}>
                                <select
                                    className={"input-register " + styles["select"]}
                                    value={selectedOccupationArea}
                                    onChange={(e) => setSelectedOccupationArea(e.target.value)}
                                >
                                    {occupationAreas.map((area) => (
                                        <option value={area} key={area}>
                                            {area}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <input
                                type="checkbox"
                                onChange={() => {
                                    setPrivacyPolicies(!privacyPolicies);
                                }}
                            ></input>
                            <span>
                                {" "}
                                Eu li e concordo com os
                                <a
                                    target="_blank"
                                    href="https://greendexv2beta.blob.core.windows.net/greendex-assets/Greendex_-_Contrato_de_Licenciamento_de_Uso_de_Plataforma_e_Prestacao_de_Servicos_1.pdf"
                                >
                                    {" "}
                                    Termos de Uso
                                </a>
                            </span>

                            <Button.Primary
                                btnType="btn-primary"
                                type="submit"
                                style={{ width: "100%", marginTop: "16px" }}
                            >
                                Cadastrar
                            </Button.Primary>
                        </form>
                        <p className={styles["bottom-panel"]}>
                            © Greendex {new Date().getFullYear()}
                        </p>
                    </div>
                </div>
            </div>
            {(loading || isLoading || registerMutate.isLoading) && <Spinner />}
        </>
    );
}
export default RegisterSocial;
