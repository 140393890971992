const SaveReportSuccess = () => {
    return (
        <div>
            <p className="text-body text-medium color-greyscale-02" style={{ marginBottom: '8px', textAlign: 'justify' }}>
                O relatório final foi gerado e será enviado para o email cadastrado na plataforma.
            </p>
            <p className="text-body text-medium color-greyscale-02">
                Caso você não o encontre em sua caixa de entrada, por favor verifique também a caixa de Spam.
            </p>
        </div>
    );
}

export default SaveReportSuccess