import { createSlice } from '@reduxjs/toolkit'
import { ICarouselHeaderState } from '../../../types/carousel-header';

const initialState: ICarouselHeaderState = {
    id: 0,
    carouselItems: [],
};

const carouselHeaderSlice = createSlice({
    name: 'carouselHeader',
    initialState,
    reducers: {
        setCarousel: (state, action) => {
            state.carouselItems = action.payload;
        },
        setCarouselId: (state, action) => {
            state.id = action.payload;
        },
        next: state => {
            if(state.id < (state.carouselItems.length - 1)) {
                state.id += 1;
            }
        },
        previous: state => {
            if(state.id > 0) {
                state.id -= 1;
            }
        },
    },
});
export const { setCarousel, setCarouselId, next, previous } = carouselHeaderSlice.actions;

export const selectCarouselHeader = 
    (state : { carouselHeader: ICarouselHeaderState }) => state.carouselHeader;

export default carouselHeaderSlice.reducer;