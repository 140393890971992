import { HTMLAttributes } from "react";

interface FieldProps extends HTMLAttributes<HTMLDivElement> {}

const Field = (props: FieldProps) => {
    return (
        <div className="d-flex flex-column" {...props}/>
    );
}

export default Field