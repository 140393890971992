import { useEffect, useState } from 'react';

import SubItemActions from './sub-item-actions';
import SubItemContent from './sub-item-content';

import Spinner from '../../common/spinner/spinner';
import When from '../../common/when/when';
import { useHomeData } from '../../hooks/useHomeData';
import styles from '../../styles/admin-user.module.css';
import { ProductData } from '../../types/home';

interface SubscriptionsListProps {
    handleOpenModalAddConfirm: Function
}

const SubscriptionsList = ({ handleOpenModalAddConfirm }: SubscriptionsListProps) => {
    const homeData = useHomeData();
    const [products, setProducts] = useState<ProductData[]>();

    useEffect(() => {
        setProducts([...homeData.data.products, ...homeData.data.internProducts])
    }, [homeData.data])

    return (
        <ul className={styles['list']}>
            {products && products
                .filter(product => !product.id.includes('pacote'))
                .map((subscription: ProductData, index: any) => (
                    <li className={styles['subscription-data']} key={index}>
                        <SubItemContent
                            title={subscription.title}
                            description={subscription.description}
                        />
                        <SubItemActions
                            id={subscription.id}
                            handleOpenModalAddConfirm={handleOpenModalAddConfirm} />
                    </li>
            ))}
            <When expr={!products || products.filter(product => !product.id.includes('pacote')).length === 0}>
                <p className="text-body text-large text-w-medium color-greyscale-01">
                    Nenhum produto encontrado
                </p>
            </When>
            <When expr={homeData.isLoading}>
                <Spinner />
            </When>
        </ul>
    );
}

export default SubscriptionsList