import { IoMdCheckmark, IoMdClose } from 'react-icons/io';

interface EditableFieldInputProps {
    value: string | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onEdit: () => void;
    onCancel: () => void;
}

const EditableFieldInput = ({ value, onChange, onEdit, onCancel }: EditableFieldInputProps) => {
    return (
        <div className="d-flex justify-content-between">
            <input
                type="text"
                className="color-primary-04"
                style={{ outline: 'none', border: 'none', width: '100%' }}
                value={value}
                onChange={(e) => onChange(e)}
            />
            <div className="d-flex">
                <IoMdClose
                    className="color-error"
                    style={{ marginRight: '16px', cursor: 'pointer' }}
                    onClick={onCancel}
                />
                <IoMdCheckmark
                    className="color-success"
                    style={{ cursor: 'pointer' }}
                    onClick={onEdit}
                />
            </div>
        </div>
    );
}

export default EditableFieldInput