import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { z } from "zod";

import BasePage from "../common/base-page";
import Field from "../common/field/field";
import Label from "../common/label/label";
import Input from "../common/input/input-zod";
import InputErrorMessage from "../common/input-error-message";
import { Button } from "../common/button";
import Breadcrumb from "../common/breadcrumb";
import SelectInput from "../common/input/select-input";
import { useEffect } from "react";
import { useInternProductEditMutate } from "../hooks/useInternProductEditMutate";
import AuthGuard from "../guards/auth-guard";

const editInternProductSchema = z.object({
    id: z.string()
        .min(1, {
            message: 'O id é obrigatório'
        }),
    type: z.string()
        .min(1, {
            message: "O tipo é obrigatório"
        }),
    dash: z.string()
        .min(1, {
            message: "O report-id/link é obrigatório"
        }),
    description: z.string()
        .min(1, {
            message: "A descrição é obrigatória"
        }),
});

type EditInternProductData = z.infer<typeof editInternProductSchema>

const ProdutosInternosEdit = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const { id } = useParams();
    const tipo = searchParams.get('tipo');
    const dash = searchParams.get('dash');
    const descricao = searchParams.get('descricao');

    const addInternProductForm = useForm<EditInternProductData>({
        resolver: zodResolver(editInternProductSchema),
    });

    const {
        handleSubmit,
        formState: { errors },
        reset
    } = addInternProductForm;

    useEffect(() => {
        reset({
            id: id,
            type: tipo || '',
            dash: dash || '',
            description: descricao || ''
        })
    }, [reset, id, tipo, dash, descricao])

    const internProductEditMutate = useInternProductEditMutate();
    function editInternProduct(data: EditInternProductData) {
        const mutateData = {
            id: data.id,
            tipo: data.type,
            dash: data.dash,
            descricao: data.description
        };
        internProductEditMutate.mutate(mutateData);
    }

    return (
        <AuthGuard
            allow="administrador"
            notAllowMessage={
                <div className="container" style={{ padding: '60px 0 180px 0' }}>
                    <h3 className={"title-h3 text-bold"}>
                        Página exclusiva para administradores
                    </h3>
                </div>
            }
        >
            <BasePage
                title='Adicionar Produto Interno'
                beadcrumb={
                    <Breadcrumb>
                        <Link to="/admin">Painel Administrativo</Link>
                        <Link to="/admin/produtos-internos">Produtos Internos</Link>
                        <span>Editar</span>
                    </Breadcrumb>}
            >
                <FormProvider {...addInternProductForm}>
                    <form onSubmit={handleSubmit(editInternProduct)}>
                        <Field>
                            <Label htmlFor="id">
                                Id do produto
                            </Label>
                            <Input
                                type="text"
                                name="id"
                                placeholder="Defina o id do produto"
                                disabled
                            />
                            <InputErrorMessage field="id" />
                        </Field>

                        <Field>
                            <Label htmlFor="type">
                                Tipo
                            </Label>
                            <SelectInput
                                name="type"
                                options={['capacidade', 'link']}
                            />
                            <InputErrorMessage field="type" />
                        </Field>

                        <Field>
                            <Label htmlFor="dash">
                                Dash
                            </Label>
                            <Input
                                type="text"
                                name="dash"
                                placeholder="Insira o report id ou link do dash"
                            />
                            <InputErrorMessage field="dash" />
                        </Field>

                        <Field>
                            <Label htmlFor="description">
                                Descrição
                            </Label>
                            <Input
                                type="text"
                                name="description"
                                placeholder="Insira a descrição do dash"
                            />
                            <InputErrorMessage field="description" />
                        </Field>

                        {internProductEditMutate.isError && (
                            <p className="input-error">Erro ao realizar edição.</p>
                        )}
                        {internProductEditMutate.isSuccess && (
                            <p className="input-success">Edição realizada com sucesso.</p>
                        )}
                        <Button.Primary
                            btnType='btn-primary'
                            type='submit'
                            style={{ width: '100%', marginTop: '16px' }}
                        >
                            Editar
                        </Button.Primary>
                    </form>
                </FormProvider>
            </BasePage>
        </AuthGuard>
    );
}

export default ProdutosInternosEdit