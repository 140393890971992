import { BsGear } from 'react-icons/bs';

import styles from '../../styles/admin-user.module.css';

interface SubItemActionsProps {
    id: string,
    handleOpenModalAddConfirm: Function
}

const SubItemActions = ({ id, handleOpenModalAddConfirm }: SubItemActionsProps) => {
    return (
        <div className={"d-flex align-items-center " + styles['subscription-col-2']}>
            <div
                className={styles['subscription-gear']}
                onClick={() => handleOpenModalAddConfirm(id)}
            >
                <BsGear className={styles['gear']}/>
                <p className={"text-body text-small color-greyscale-03 " + styles['show-sm']}>
                    Gerenciar assinatura
                </p>
            </div>
        </div>
    );
}

export default SubItemActions