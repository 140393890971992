import { Link } from "react-router-dom";

import { Button } from "../button";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

interface TooltipContentProps {
    title: string;
    paragraph_1: string;
    paragraph_2: string;
    redirect_link: string;
    btn_text?: string
}

const TooltipContent = (props: TooltipContentProps) => {
    const gaEventTracker = useAnalyticsEventTracker('Card');

    return (
        <>
            <h4 className="title-h4 color-dark-03">
                {props.title}
            </h4>
            <ul>
                <li
                    className="text-body text-large color-greyscale-03"
                    style={{ marginBottom: '24px' }}
                >
                    {props.paragraph_1}
                </li>
                <li
                    className="text-body text-large color-greyscale-03"
                >
                    {props.paragraph_2}
                </li>
            </ul>
            <Link to={props.redirect_link}>
                <Button.Primary
                    btnType='btn-primary'
                    style={{ width: '100%' }}
                    onClick={() => gaEventTracker(
                        'Clique no botão Saiba mais',
                        `Clique no botão Saiba mais do card da página '${window.location.pathname}' para a visualização do produto ${props.title}`
                    )}>
                        {props.btn_text ? props.btn_text : 'Saiba mais'}
                    </Button.Primary>
            </Link>
        </>
    );
}

export default TooltipContent