import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { useHeaderConfig } from "./useHeaderConfig";
import { InternProductEditMutate } from "../types/intern-product";

const PostData = async (data: InternProductEditMutate): Promise<any> => {
    const config = useHeaderConfig();
    return await axios.put('/api/dash-interno', data, config);
}

export function useInternProductEditMutate() {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: PostData,
        onSuccess: () => {
            queryClient.invalidateQueries(['home-data']);
        }
    });

    return mutate;
}