import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import SectionManageSubscriptions from './section-manage-subscriptions';
import SectionUserData from './section-user-data';
import ModalConfirmContent from './modal-confirm-content';
import { dateFormatter } from '../../app/formatter';
import { phoneMask } from '../../app/mask';
import Spinner from '../../common/spinner/spinner';
import ModalConfirm from '../../components/manage-subscriptions/modal-confirm/modal-confirm';
import ModalManage from '../../components/manage-subscriptions/modal-manage/modal-manage';
import { useAdminUserData } from '../../hooks/useAdminUserData';
import { useAdmAcessosMutate } from '../../hooks/useAdmAcessosMutate';
import { ISubscriptionMin } from '../../types/subscription';
import { IAcessos } from '../../types/user';
import AuthGuard from '../../guards/auth-guard';

function AdminUser() {
    const { id } = useParams();
    const adminUserData = useAdminUserData(id || '');

    const modalManageRef = useRef(null);
    const modalAddRef = useRef(null);

    const [userData, setUserData] = useState([
        {
            title: 'Nome de usuário',
            value: ''
        },
        {
            title: 'E-mail',
            value: ''
        },
        {
            title: 'Telefone',
            value: ''
        },
        {
            title: 'Empresa',
            value: ''
        },
        {
            title: 'Área de atuação',
            value: ''
        },
    ]);
    const [subscriptionsUser, setSubscriptionsUser] = useState<IAcessos[]>([]);

    useEffect(() => {
        if (adminUserData.user) {
            setUserData([
                {
                    title: 'Nome de usuário',
                    value: adminUserData.user.nome
                },
                {
                    title: 'E-mail',
                    value: adminUserData.user.email
                },
                {
                    title: 'Telefone',
                    value: adminUserData.user.telefone ? phoneMask(adminUserData.user.telefone) : ''
                },
                {
                    title: 'Empresa',
                    value: adminUserData.user.empresa
                },
                {
                    title: 'Área de atuação',
                    value: adminUserData.user.setor
                },
            ]);
            setSubscriptionsUser(adminUserData.user.acessos);
        }
    }, [adminUserData.user]);

    const [selectedSubscription, setSelectedSubscription] = useState<ISubscriptionMin | null>(null);
    const [showModalManage, setShowModalManage] = useState(false);
    function handleOpenModalManage(subscription: any) {
        if (subscription.produto) {
            subscription = {
                id: subscription.produto.id,
                title: subscription.produto.title,
                description: subscription.produto.description,
                type: subscription.tipo,
                status: subscription.tipo === 'Assinatura'
                    ? `Renova em ${dateFormatter(subscription.renova_ou_expira_em)}`
                    : `Expira em ${dateFormatter(subscription.renova_ou_expira_em)}`
            }
        }
        setSelectedSubscription(subscription);
        setShowModalManage(true);
    }

    function handleCloseModalManage() {
        setSelectedSubscription(null);
        setShowModalManage(false);
    }

    const [showModalAddConfirm, setShowModalAddConfirm] = useState(false);
    const [productId, setProductId] = useState<string>();
    function handleOpenModalAddConfirm(product_id: string) {
        setProductId(product_id);
        setShowModalAddConfirm(true);
    }

    function handleCloseModalAddConfirm() {
        setStatusResponse({
            message: '',
            status: '',
        });
        setShowModalAddConfirm(false);
    }

    const [selectedPeriod, setSelectedPeriod] = useState('1 mês');
    const [statusResponse, setStatusResponse] = useState({
        message: '',
        status: '',
    });

    const admAcessosMutate = useAdmAcessosMutate();

    function addSubscription() {
        if (!adminUserData.user || !productId || !selectedPeriod) return;

        const periodInDays =
            selectedPeriod === '1 mês' ? 30 :
                selectedPeriod === '6 meses' ? 182 : 365;

        const data = {
            tipo: 'criar-acesso',
            usuario: adminUserData.user.id,
            produto: productId,
            tempo: periodInDays,
        };
        admAcessosMutate.mutate(data);
    }

    useEffect(() => {
        if (admAcessosMutate.isError) {
            setStatusResponse({
                message: 'Erro ao adicionar assinatura.',
                status: 'error'
            });
        }

        if (admAcessosMutate.isSuccess) {
            setStatusResponse({
                message: 'Sucesso ao adicionar assinatura.',
                status: 'success'
            });
            handleCloseModalAddConfirm();
        }
    }, [admAcessosMutate.isError, admAcessosMutate.isSuccess]);

    return (
        <AuthGuard
            allow="administrador"
            notAllowMessage={
                <div className="container" style={{ padding: '60px 0 180px 0' }}>
                    <h3 className={"title-h3 text-bold"}>
                        Página exclusiva para administradores
                    </h3>
                </div>
            }
        >
            <>
                <SectionUserData
                    username={adminUserData.user?.nome || ''}
                    userData={userData}
                />
                <SectionManageSubscriptions
                    subscriptionsUser={subscriptionsUser}
                    handleOpenModalAddConfirm={handleOpenModalAddConfirm}
                    handleOpenModalManage={handleOpenModalManage}
                />
                {/* <PurchasesHistoric /> */}
                <CSSTransition
                    in={!!selectedSubscription && showModalManage && !!adminUserData.user}
                    nodeRef={modalManageRef}
                    timeout={300}
                    unmountOnExit
                    classNames='fade'
                >
                    <div ref={modalManageRef}>
                        {selectedSubscription && adminUserData.user && (
                            <ModalManage
                                id={selectedSubscription.id}
                                user_id={adminUserData.user.id}
                                title={selectedSubscription.title}
                                description={selectedSubscription.description}
                                status={selectedSubscription?.status}
                                type={selectedSubscription.type}
                                payment={selectedSubscription.payment}
                                price={selectedSubscription.price}
                                // onFetchData={fetchDataUser}
                                onClose={handleCloseModalManage}
                            />
                        )}
                    </div>
                </CSSTransition>
                <CSSTransition
                    in={showModalAddConfirm}
                    nodeRef={modalAddRef}
                    timeout={300}
                    unmountOnExit
                    classNames='fade'
                >
                    <div ref={modalAddRef}>
                        <ModalConfirm
                            title="Você tem certeza que deseja adicionar esta assinatura?"
                            description="O usuário passará a ter acesso gratuito aos produtos desta assinatura."
                            btn_text="Adicionar assinatura"
                            statusResponse={statusResponse}
                            onConfirm={addSubscription}
                            onClose={handleCloseModalAddConfirm}
                        >
                            <ModalConfirmContent
                                selectedPeriod={selectedPeriod}
                                setSelectedPeriod={setSelectedPeriod}
                            />
                        </ModalConfirm>
                    </div>
                </CSSTransition>
                {adminUserData.isLoading && <Spinner />}
            </>
        </AuthGuard>
    );
}
export default AdminUser;