function cpfValidation(cpf: string) {
    let sum = 0;
    let rest;
    if (cpf === '00000000000') return false;

    for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(cpf.substring(i-1, i)) * (11 - i)
    }
    rest = (sum * 10) % 11;
    if ((rest === 10) || (rest === 11)) {
        rest = 0;
    }
    if (rest !== parseInt(cpf.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) {
        sum = sum + parseInt(cpf.substring(i-1, i)) * (12 - i)
    }
    rest = (sum * 10) % 11;
    if ((rest === 10) || (rest === 11)) {
        rest = 0
    }
    if (rest !== parseInt(cpf.substring(10, 11))) return false;

    return true;
}

function emailValidation(email: string) {
    if(!email) {
        return false;
    } else {
        const emailRegex = 
            new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
        return emailRegex.test(email);
    }
}

export function passwordValidation(password: string) {
    if(!password) {
        return '* insira uma senha válida';
    } else {
        const strongPasswordRegex = 
            new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/, "gm");
        
        if(strongPasswordRegex.test(password)) {
            return 'valid';
        } else {
            const oneDigitRegex = 
                new RegExp(/[0-9]/, "gm");
            const oneLowerCaseRegex = 
                new RegExp(/[a-z]/, "gm");
            const oneUpperCaseRegex = 
                new RegExp(/[A-Z]/, "gm");
            const oneSpecialCharacterRegex = 
                new RegExp(/[$*&@#]/, "gm");
            
            if(!oneDigitRegex.test(password)) {
                return '* adicione um número a sua senha';
            } else if(!oneLowerCaseRegex.test(password)) {
                return '* adicione uma letra minúscula a sua senha';
            } else if(!oneUpperCaseRegex.test(password)) {
                return '* adicione uma letra maiúscula a sua senha';
            } else if(!oneSpecialCharacterRegex.test(password)) {
                return '* adicione um caractere especial a sua senha';
            } else if(password.length < 8){
                return '* a senha deve conter no mínimo 8 caracteres';
            } else {
                return '* a senha deve conter apenas caracteres válidos';
            }
        }
    }
}

function cpfCnpjValidation(_cpfCnpj: string) {
    if (!_cpfCnpj) return false;
    if (_cpfCnpj.length <= 11) return cpfValidation(_cpfCnpj);
    return true;
}

function phoneValidation(_phone: string) {
    if (!_phone) return false;
    return _phone.length >= 8;
}

function cepValidation(_cep: string) {
    if (!_cep) return false;
    return _cep.length === 8;
}

export { cpfValidation, emailValidation, cpfCnpjValidation, phoneValidation, cepValidation };