import { useEffect, useState } from 'react';

import NonEditableField from './non-editable-field';
import { useProfileData } from '../../hooks/useProfileData';

const NonEditableFields = () => {
    const { profile } = useProfileData();

    const [nonEditableFields, setNonEditableFields] = useState<{ title: string, value: string | undefined }[]>();

    useEffect(() => {
        setNonEditableFields([
            {
                title: 'Nome de usuário',
                value: profile?.nome
            },
            {
                title: 'E-mail',
                value: profile?.email
            },
        ]);
    }, [profile]);

    return (
        <>
            {nonEditableFields && nonEditableFields.map((field, index) => (
                <NonEditableField
                    key={index}
                    title={field.title}
                    value={field.value}
                />
            ))}
        </>
    );
}

export default NonEditableFields