import PrimaryButton from './primary-button';

interface AnchorButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    btnType: 
        | 'btn-primary'
        | 'btn-secondary' 
        | 'btn-social'
        | 'btn-filter'
        | 'btn-white'
        | 'btn-primary-outline'
        | 'btn-secondary-outline'
        | 'btn-red-outline',
    href: string
}

const AnchorButton = ({ href, ...props }: AnchorButtonProps) => {
    return (
        <a href={href}>
            <PrimaryButton {...props} />
        </a>
    );
}

export default AnchorButton