import { Button } from '../../common/button';
import When from '../../common/when/when'

interface ButtonSubmitProps {
    editMode: boolean | undefined,
    handleEditPost: () => void,
    handleAddPost: () => void
}

const ButtonSubmit = ({ editMode, handleEditPost, handleAddPost}: ButtonSubmitProps) => {
    return (
        <>
            <When expr={editMode}>
                <Button.Primary
                    btnType="btn-primary"
                    onClick={handleEditPost}
                >
                    Editar
                </Button.Primary>
            </When>
            <When expr={!editMode}>
                <Button.Primary
                    btnType="btn-primary"
                    onClick={handleAddPost}
                >
                    Adicionar
                </Button.Primary>
            </When>
        </>
    );
}

export default ButtonSubmit