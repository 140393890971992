interface BadgeProps {
    text: string | undefined
}

const Badge = ({ text }: BadgeProps) => {
    return (
        <span
            className={'bg-tertiary-02 color-white text-body text-medium'}
            style={{
                margin: '-29px auto 0 auto',
                padding: '5px 10px',
                borderRadius: '5px 5px 0px 0px'
            }}
        >
            {text}
        </span>
    );
}

export default Badge