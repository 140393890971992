import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { MyProductData } from "../types/my-product";
import { useHeaderConfig } from "./useHeaderConfig";

const FetchData = async (): Promise<AxiosResponse<MyProductData[]>> => {
    const config = useHeaderConfig();
    const response = await axios.get<MyProductData[]>('/api/meus_produtos', config);
    return response;
};

export function useMyProductsData() {
    const query = useQuery({
        queryFn: FetchData,
        queryKey: ['my-products-data'],
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        ...query,
        products: query.data?.data,
        internProducts: query.data?.data.filter(product => product.acesso === 'interno-link' || product.acesso === 'interno-capacidade'),
        plataformProducts: query.data?.data.filter(product => product.acesso !== 'interno-link' && product.acesso !== 'interno-capacidade')
    };
}