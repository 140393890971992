import { LabelHTMLAttributes } from "react";

const Label = (props: LabelHTMLAttributes<HTMLLabelElement>) => {
    return (
        <label
            className="label-register"
            {...props}
        />
    );
}

export default Label;