import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ForgotPassMutate } from "../types/forgot-pass";
import { useHeaderConfig } from "./useHeaderConfig";

const PostData = async (data: ForgotPassMutate): Promise<any> => {
    const config = useHeaderConfig();
    return await axios.post('/api/recuperacao-senha', data, config);
}

export function useForgotPassMutate() {    
    const mutate = useMutation({
        mutationFn: PostData
    });

    return mutate;
}