import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

import FilterItemContainer from './filter-item-container';
import SavedFilters from './saved-filters';
import ErrorMessage from '../../error-message';

interface FiltersOpenProps {
    id: string,
    isErrorSaveFilter: boolean,
    setOpenFilters: Function,
    applyFilter: Function,
    addFilter: Function
}

const FiltersOpen = (props: FiltersOpenProps) => {
    const [enteredName, setEnteredName] = useState<string>('');

    return (
        <aside
            className='d-flex'
            style={{padding: '16px', gap: '16px', flexDirection: 'column'}}
        >
            <SavedFilters
                id={props.id}
                setOpenFilters={props.setOpenFilters}
                applyFilter={props.applyFilter}
            />
            <hr style={{ width: '100%' }} />
            <FilterItemContainer>
                <input
                    type="text"
                    className="input-register"
                    placeholder='Adicionar filtro'
                    style={{ marginBottom: '0' }}
                    value={enteredName}
                    onChange={(e) => setEnteredName(e.target.value)}
                />
                <AiOutlinePlus
                    className='color-greyscale-04'
                    style={{ fontSize: '24px', cursor: 'pointer' }}
                    onClick={() => props.addFilter(enteredName)}
                />
            </FilterItemContainer>
            <ErrorMessage
                expr={props.isErrorSaveFilter}
                message='Erro ao salvar filtro.'
            />
        </aside>
    );
}

export default FiltersOpen