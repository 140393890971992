import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

const fetchData = async (): Promise<AxiosResponse> => {
    const response = await axios.get('/.auth/me');
    return response;
};

export function useAuthMeData() {
    const query = useQuery({
        queryFn: fetchData,
        queryKey: ['auth-me-data']
    });

    return {
        ...query,
        data: query.data?.data
    };
}