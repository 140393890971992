export default function priceFormatter(price: number) {
    return `R$ ${price
        .toLocaleString(
        'pt-br',
        {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }
    )}`;
}

export function dateFormatter(date: string) {
    return `${date.slice(-2)}/${date.slice(-5, -3)}/${date.slice(0, 4)}`;
}