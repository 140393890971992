import { createSlice } from '@reduxjs/toolkit';
import { IProduct, IProductState } from '../types/produto';

interface IActionCarouselSetSlide {
    payload: {
        typeCarousel: 'free' | 'mostAccess' | 'package';
        id: number;
    }
}

const initialState: IProductState = {
    products: [],
    pagination: {
        page: 1,
        productsPerPage: 8,
        products: [],
    },
    paginationPlanAndValues: {
        pagePV: 1,
        productsPerPagePV: 8,
        productsPV: [],
    },
    carousel: {
        free: {
            id: 0,
            products: [],
        },
        mostAccess: {
            id: 0,
            products: [],
        },
        package: {
            id: 0,
            products: [],
        },
    },
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
            state.pagination.products = action.payload;
            state.paginationPlanAndValues.productsPV = action.payload
                .filter((product: IProduct) => ((product.price && product.price !== 0) || product.preco_anual));

            state.carousel.free.products = action.payload
                .filter((product: IProduct) => product.price === 0);
            state.carousel.mostAccess.products = action.payload.filter((product: IProduct) => !product.id.includes('pacote'));
        },
        nextPage: state => {
            state.pagination.page++;
        },
        previousPage: state => {
            state.pagination.page--;
        },
        setPage: (state, action) => {
            state.pagination.page = action.payload;
        },
        applyFilter: (state, action) => {
            if(action.payload === 'todas') {
                state.pagination.products = state.products;
            } else {
                state.pagination.products = state.products.filter(
                    product => product.category === action.payload
                );
            }
            state.pagination.page = 1;
        },
        nextPagePlanAndValues: state => {
            state.paginationPlanAndValues.pagePV++;
        },
        previousPagePlanAndValues: state => {
            state.paginationPlanAndValues.pagePV--;
        },
        setPagePlanAndValues: (state, action) => {
            state.paginationPlanAndValues.pagePV = action.payload;
        },
        applyFilterPlanAndValues: (state, action) => {
            if(action.payload === 'todas') {
                state.paginationPlanAndValues.productsPV = state.products
                    .filter((product: IProduct) => 
                        ((product.price && product.price !== 0) || product.preco_anual)
                    );
            } else {
                state.paginationPlanAndValues.productsPV = state.products.filter(
                    product => product.category === action.payload
                ).filter((product: IProduct) => 
                    ((product.price && product.price !== 0) || product.preco_anual)
                );
            }
            state.paginationPlanAndValues.pagePV = 1;
        },
        setSlide: (state, action: IActionCarouselSetSlide) => {
            const typeCarousel = action.payload.typeCarousel;
            state.carousel[typeCarousel].id = action.payload.id;
        },
        nextSlide: (state, action: { payload: 'free' | 'mostAccess' | 'package' }) => {
            const typeCarousel = action.payload;
            if(state.carousel[typeCarousel].id < (state.carousel[typeCarousel].products.length - 1)) {
                state.carousel[typeCarousel].id++;
            }
        },
        previousSlide: (state, action: { payload: 'free' | 'mostAccess' | 'package' }) => {
            const typeCarousel = action.payload;
            if(state.carousel[typeCarousel].id > 0) {
                state.carousel[typeCarousel].id--;
            }
        },
        setCarouselPackage: (state, action) => {
            state.carousel.package.id = 0;
            state.carousel.package.products = action.payload;
        }
    },
});
export const {
    setProducts,
    nextPage,
    previousPage,
    setPage,
    applyFilter,
    nextPagePlanAndValues,
    previousPagePlanAndValues,
    setPagePlanAndValues,
    applyFilterPlanAndValues,
    setSlide,
    nextSlide,
    previousSlide,
    setCarouselPackage
} = productSlice.actions;

export const selectProductPagination = 
    (state: { product: IProductState }) => state.product.pagination;
export const selectProductPaginationPlanAndValues = 
    (state: { product: IProductState }) => state.product.paginationPlanAndValues;
export const selectProductCarousels = 
    (state: { product: IProductState }) => state.product.carousel;

export default productSlice.reducer;