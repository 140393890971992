import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { LogAcessosMutate } from "../types/admin";
import { useHeaderConfig } from "./useHeaderConfig";

const PostData = async (data: LogAcessosMutate): Promise<any> => {
    const config = useHeaderConfig();
    return await axios.post('/api/log_acessos', data, config);
}

export function useLogAcessosMutate() {    
    const mutate = useMutation({
        mutationFn: PostData
    });

    return mutate;
}