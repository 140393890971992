import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiCloseFill } from 'react-icons/ri';

import styles from './navbar-desktop.module.css';
import LogoutDropdown from '../logout-dropdown/logout-dropdown';
import SearchProduct from '../search-product/search-product';
import logoLight from '../../../assets/images/logo-light.svg';
import { IProduct } from '../../../types/produto';
import { ProfileData } from '../../../types/profile';

interface NavbarProps {
    products: IProduct[] | [],
    profile: ProfileData | undefined
}

function NavbarDesktop(props: NavbarProps) {
    const [showSearch, setShowSearch] = useState<boolean>(false);

    return (
        <header className={styles['header']}>
            <div className={'container ' + styles["navbar"]}>
                <Link to="/">
                    <img src={logoLight} alt="logo Greendex" />
                </Link>
                {!showSearch ? (
                    <div className='d-flex' style={{ gap: '32px' }}>
                        <Link to="/dados-gd" className='text-body text-medium text-white'>Dados GD</Link>
                        <Link to="/contato" className='text-body text-medium text-white'>Contato</Link>
                        <Link to="/blog" className='text-body text-medium text-white'>Blog</Link>
                        <AiOutlineSearch
                            className='color-white'
                            style={{ cursor: 'pointer' }}
                            onClick={() => setShowSearch(true)}
                        />
                    </div>
                ) : (
                    <div className='d-flex align-items-center' style={{ width: '40%', gap: '32px' }}>
                        <SearchProduct products={props.products} style={{ flexGrow: '1' }} />
                        <RiCloseFill
                            className='color-white'
                            style={{ cursor: 'pointer' }}
                            onClick={() => setShowSearch(false)}
                        />
                    </div>
                )}
                {props.profile ? (
                    <div className='d-flex' style={{ gap: '32px' }}>
                        <Link
                            to="/meus-produtos"
                            className='text-body text-medium text-white'
                            style={{ alignSelf: 'center' }}
                        >
                            Meus produtos
                        </Link>
                        <LogoutDropdown profile={props.profile} />
                    </div>
                ) : (
                    <div className='d-flex align-items-center' style={{ gap: '32px' }}>
                        <Link to="/login" className='text-body t-medium text-white'>Fazer login</Link>
                    </div>
                )}
            </div>
        </header>
    );
}
export default NavbarDesktop;