import styles from './styles.module.css';

interface CircleAvatarProps {
    nameInitial: string
    size?: string,
    className?: string;
    style?: any
}

const CircleAvatar = ({ nameInitial, className='', style=''} : CircleAvatarProps) => {
    return (
        <div
            className={styles['circle'] + ' ' + styles[className]}
            style={style ? styles : {}}
        >
            {nameInitial}
        </div>
    );
}

export default CircleAvatar