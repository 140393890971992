import { HTMLAttributes } from "react"

import styles from './styles.module.css';

interface IndicatorListProps extends HTMLAttributes<HTMLDivElement> {}

const IndicatorList = (props: IndicatorListProps) => {
  return (
    <div className={styles['indicators']} {...props} />
  )
}

export default IndicatorList