import BtnBuyWpp from '../../../components/package/package-header/btn-buy-wpp/btn-buy-wpp';

const BuyPackageButton = () => {
    return (
        <BtnBuyWpp
            style={{
                width: '100%',
                padding: '9px 0',
                fontSize: '14px',
                marginBottom: '12px'
            }}
        />
    );
}

export default BuyPackageButton