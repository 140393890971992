
import { useEffect, useState } from 'react'

import { useProfileData } from '../hooks/useProfileData';
import { AcessUsers } from '../types/user';

interface AuthGuardProps {
    children: JSX.Element
    allow?: AcessUsers
    notAllowMessage?: JSX.Element
}

const AuthGuard = ({ children, allow, notAllowMessage }: AuthGuardProps) => {
    const { profile } = useProfileData();

    const [isAuthorized, setIsAuthorized] = useState(false);

    useEffect(() => {
        if (profile && (profile.roles.includes('administrador') || (allow && profile.roles.includes(allow)))) {
            setIsAuthorized(true);
        }
    }, [profile]);

    return isAuthorized ? children : notAllowMessage || <></>;
}

export default AuthGuard