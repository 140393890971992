import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import Breadcrumb from '../../common/breadcrumb';
import ModalBI from '../../common/modal-bi';
import ModalTestBI from '../../common/modal-test-bi';
import Spinner from '../../common/spinner/spinner';
import { useMyProductsData } from '../../hooks/useMyProductsData';
import styles from '../../styles/my-products.module.css';
import { MyProductData } from '../../types/my-product';
import { useLogAcessosMutate } from '../../hooks/useLogAcessosMutate';
import MyProductsSection from './my-products-section';

function MyProducts() {
    const { internProducts, plataformProducts, isLoading } = useMyProductsData();

    const logAcessosMutate = useLogAcessosMutate();
    
    function addAcessLog(productId: string) {
        const data = {
            id_produto: productId,
        };
        logAcessosMutate.mutate(data);
    }

    function handleSelectBI(product: MyProductData) {
        addAcessLog(product.produto.id);
        setBiTitle(product.detalhes_cartao.title);
        if(product.acesso === 'Pago' || product.acesso === 'interno-capacidade') {
            handleSelectBIpaid(product.produto.id);
        } else {
            handleSelectFreeBI(product.produto.link, product.detalhes_cartao.title);
        }
    }

    const [showBIpaid, setShowBIpaid] = useState<boolean>(true);
    const [id, setId] = useState<string | null>(null);

    function handleSelectBIpaid(idSelectedBI: string) {
        setId(idSelectedBI);
        setShowBIpaid(true);
    }

    function handleCloseModalBIpaid() {
        setShowBIpaid(false);
    }

    const [showFreeBI, setShowFreeBI] = useState<boolean>(false);
    const [iframe, setIframe] = useState<string | null>(null);
    const [biTitle, setBiTitle] = useState<string>('');
    function handleSelectFreeBI(link: string, title: string) {
        if(!link || link === '/') return;
        setIframe(
            `<iframe
                title="modal-bi"
                src="${link}"
                width="1140"
                height="541.25"
                frameborder="0"
                allowFullScreen="true"
            ></iframe>`
        );
        setShowFreeBI(true);
    }
    function handleCloseModalFreeBI() {
        setShowFreeBI(false);
    }

    const modalFreeBIRef = useRef(null);
    const modalBIRef = useRef(null);

    return (
        <main>
            {isLoading ? (
                <Spinner />
            ): (
                <div className={styles['bg']}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className={'col-md-10 ' + styles['col-content']}>
                                <div className={styles['show-tablet']}>
                                    <Breadcrumb>
                                        <Link to="/perfil">Perfil</Link>
                                        <span>Meus produtos</span>
                                    </Breadcrumb>
                                </div>
                                {internProducts && internProducts.length > 0 && (
                                    <div style={{ marginBottom: '48px' }}>
                                        <MyProductsSection
                                            title="Produtos internos"
                                            products={internProducts}
                                            handleSelectBI={handleSelectBI}
                                        />
                                    </div>
                                )}
                               {plataformProducts && plataformProducts.length > 0 && (
                                    <MyProductsSection
                                        title="Meus produtos"
                                        products={plataformProducts}
                                        handleSelectBI={handleSelectBI}
                                    />
                               )}
                            </div>
                            <CSSTransition
                                in={!!id && showBIpaid}
                                nodeRef={modalBIRef}
                                timeout={200}
                                unmountOnExit
                                classNames='fade'
                            >
                                <div ref={modalBIRef}>
                                    <ModalBI
                                        id={id ? id : ''}
                                        title={biTitle}
                                        onClose={handleCloseModalBIpaid}
                                    />
                                </div>
                            </CSSTransition>
                            <CSSTransition
                                in={!!iframe && showFreeBI}
                                nodeRef={modalFreeBIRef}
                                timeout={200}
                                unmountOnExit
                                classNames='fade'
                            >
                                <div ref={modalFreeBIRef}>
                                    <ModalTestBI
                                        title={biTitle}
                                        iframe={iframe ? iframe : ''}
                                        onClose={handleCloseModalFreeBI}
                                    />
                                </div>
                            </CSSTransition>
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
}
export default MyProducts;