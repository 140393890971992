import { models, Report } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import FiltersLoading from './filters-loading';
import FiltersOpen from './filters-open';
import FiltersClosed from './filters-closed';
import SaveReportSuccess from './save-report-success';
import SaveReportError from './save-report-error';
import TopBar from './top-bar';
import styles from './styles.module.css';

import Spinner from '../spinner/spinner';
import { useEmbeddedBiData } from '../../hooks/useEmbeddedBiData';
import { useExportBiMutate } from '../../hooks/useExportBiMutate';
import { useFilterMutate } from '../../hooks/useFilterMutate';
import ModalPosSaveReport from '../../components/manage-subscriptions/modal-pos-save-report/modal-pos-save-report';

function ModalBi(props: { id: string, title: string, onClose: Function }) {
    const { report, isLoading } = useEmbeddedBiData(props.id);

    useEffect(() => {
        document.body.classList.add('scrollbar-hidden');
        return function () {
            document.body.classList.remove('scrollbar-hidden');
        } ;
     }, []);

    const [embeddedReport, setEmbeddedReport] = useState<Report>();
    const exportBiMutate = useExportBiMutate();
    async function saveReport() {
        const state = await captureFilter();
        const data = {
            id_produto: props.id,
            state: state,
        };
        exportBiMutate.mutate(data);
    }

    const modalPosSaveRef = useRef(null);
    const [showModalPosSaveReport, setShowModalPosSaveReport] = useState(false);
    useEffect(() => {
        if(exportBiMutate.isSuccess || exportBiMutate.isError) {
            setShowModalPosSaveReport(true);
        }
    }, [exportBiMutate.isSuccess, exportBiMutate.isError]);

    async function captureFilter() {
        if(embeddedReport) {
            const bookmarks = await embeddedReport.bookmarksManager.capture();
            console.log(bookmarks);
            return bookmarks.state;
        }
    }

    async function applyFilter(status: string) {
        if(embeddedReport) {
            await embeddedReport.bookmarksManager.applyState(status);
        }
    }

    const filterMutate = useFilterMutate();
    async function addFilter(name: string) {
        const state = await captureFilter();
        const data = {
            id_produto: props.id,
            state: state,
            nome: name,
        };
        filterMutate.mutate(data);
    }

    const [openFilters, setOpenFilters] = useState(false);

    return (
        <div className={styles['modal-wrapper']}>
            <TopBar
                title={props.title}
                onClose={props.onClose}
            />
            <div className={styles['content']}>
                {exportBiMutate.isLoading && <FiltersLoading />}
                {!exportBiMutate.isLoading && (openFilters ?
                    <FiltersOpen
                        id={props.id}
                        isErrorSaveFilter={filterMutate.isError}
                        setOpenFilters={setOpenFilters}
                        applyFilter={applyFilter}
                        addFilter={addFilter}
                    />
                    : <FiltersClosed
                        id={props.id}
                        setOpenFilters={setOpenFilters}
                        saveReport={saveReport}
                    />
                )}
                {report ? (
                    <PowerBIEmbed
                        embedConfig = {{
                            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                            id: report.reportConfig[0].reportId, 
                            embedUrl: report.reportConfig[0].embedUrl,
                            accessToken: report.accessToken,    // Keep as empty string, null or undefined
                            tokenType: models.TokenType.Embed,
                        }}
                        cssClassName={styles['report-style-class']}
                        getEmbeddedComponent={
                            (embeddedReport) => {
                                setEmbeddedReport(embeddedReport as Report);
                            }}
                    />
                ) : (
                    <span>Não foi possível acesssar o BI</span>
                )}
            </div>
            <CSSTransition
                in={showModalPosSaveReport}
                nodeRef={modalPosSaveRef}
                timeout={300}
                unmountOnExit
                classNames='fade'
            >
                <div ref={modalPosSaveRef}>
                    <ModalPosSaveReport
                        title={exportBiMutate.isSuccess ? "Relatório processado com sucesso!" : "Erro ao processar relatório!"}
                        description=""
                        btn_text="Continuar"
                        onConfirm={() => setShowModalPosSaveReport(false)}
                        onClose={() => setShowModalPosSaveReport(false)}
                    >
                        <div className={styles['pre-save-report']}>
                            {exportBiMutate.isSuccess ? (
                                <SaveReportSuccess />
                            ): (
                                <SaveReportError />
                            )}
                        </div>
                    </ModalPosSaveReport>
                </div>
            </CSSTransition>
            { isLoading && <Spinner /> }
        </div>
    );
}
export default ModalBi;