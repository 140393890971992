import Spinner from '../common/spinner/spinner';
import { useProductData } from '../hooks/useProductData';
import styles from '../styles/gd-data.module.css';

function GdData() {
    const { productData, isLoading } = useProductData('mapeamento-gd');

    return (
        <main>
            <div className="bg-grey">
                <div className="container-fluid">
                    <section className={styles['section-bi']}>
                        {productData && (
                            <iframe
                                title="modal-bi"
                                src={productData?.produto.link}
                                width="1140"
                                height="600.25"
                                frameBorder="0"
                                allowFullScreen={true}
                                className={styles['bi']}
                            ></iframe>
                        )}
                    </section>
                </div>
            </div>
            { isLoading && <Spinner /> }
        </main>
    );
}
export default GdData;