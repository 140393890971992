import { Link, useParams } from "react-router-dom";

import PDFViewer from "../../common/pdf-viewer/pdf-viewer";
import When from "../../common/when/when";
import Breadcrumb from "../../common/breadcrumb";
import { useRelatorioData } from "../../hooks/useRelatorioData";
import Spinner from "../../common/spinner/spinner";
import AuthGuard from "../../guards/auth-guard";

const RelatorioNew = () => {
    const { id } = useParams();
    const { data, isLoading } = useRelatorioData(id);

    return (
        <AuthGuard
            allow="greener"
            notAllowMessage={
                <div className="container" style={{ padding: '60px 0 180px 0' }}>
                    <h3 className={"title-h3 text-bold"}>
                        Página exclusiva para administradores
                    </h3>
                </div>
            }
        >
            <div className="bg-grey">
                <div className="container">
                    <div style={{ marginBottom: '32px', paddingTop: '48px' }}>
                        <Breadcrumb>
                            <Link to="/admin">Painel Administrativo</Link>
                            <Link to="/relatorio-distribuidores">Relatório de Distribuidores</Link>
                            <span>Relatório</span>
                        </Breadcrumb>
                        <h3 className={'title-h3 text-bold'}>
                            Relatório
                        </h3>
                    </div>
                    <When expr={data && data.pdf_url}>
                        <PDFViewer base64String={data ? data.pdf_url : ''} />
                    </When>
                    <When expr={isLoading}>
                        <Spinner />
                    </When>
                </div>
            </div>
        </AuthGuard>
    );
}

export default RelatorioNew;