import { HTMLAttributes } from "react";

import styles from './styles.module.css';

interface BreadcrumbItemProps extends HTMLAttributes<HTMLLIElement> {
    index: number,
    listLength: number
}

const BreadcrumbItem = ({ index, listLength, children }: BreadcrumbItemProps) => {
    return (
        <li
            className={
                index === (listLength - 1)
                    ? styles['breadcrumb-item'] + ' ' + styles['active']
                    : styles['breadcrumb-item']
            }
        >
            {children}
        </li>
    );
}

export default BreadcrumbItem