import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import BasePage from './base-page';
import Header from './header';
import ProductList from './product-list';

import Breadcrumb from '../../common/breadcrumb';
import Spinner from '../../common/spinner/spinner';
import When from '../../common/when/when';
import ModalConfirm from '../../components/manage-subscriptions/modal-confirm/modal-confirm';
import { useHomeData } from '../../hooks/useHomeData';
import { ProductData } from '../../types/home';
import { useInternProductDelete } from '../../hooks/useInternProductDelete';
import AuthGuard from '../../guards/auth-guard';

function ProdutosInternosList() {
    const modalDeleteRef = useRef(null);

    const { data, isLoading } = useHomeData();

    const navigate = useNavigate();
    function handleSelectProduct(product: ProductData) {
        navigate(`/admin/produtos-internos/${product.id}?tipo=${product.tipo === 'interno-link' ? 'link' : 'capacidade'}&dash=${product.dash}&descricao=${product.description}`);
    }

    const [showModalDelete, setShowModalDelete] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<ProductData>();
    function handleOpenModalDelete(product: ProductData) {
        setShowModalDelete(true);
        setSelectedProduct(product);
    }
    function handleCloseModalDelete() {
        setShowModalDelete(false);
    }
    const [statusResponse, setStatusResponse] = useState({
        message: '',
        status: '',
    });

    const internProductDelete = useInternProductDelete();

    function deleteProduct() {
        if (selectedProduct && selectedProduct.id && selectedProduct.tipo) {
            internProductDelete.mutate({
                id: selectedProduct.id,
                tipo: selectedProduct.tipo
            });
        }
    }

    useEffect(() => {
        if (internProductDelete.isError) {
            setStatusResponse({
                message: 'Erro ao deletar usuário.',
                status: 'error'
            });
        }
        if (internProductDelete.isSuccess) {
            setStatusResponse({
                message: 'Sucesso ao deletar usuário.',
                status: 'success'
            });
            // fetchData();
            handleCloseModalDelete();
        }
    }, [internProductDelete.isError, internProductDelete.isSuccess])

    return (
        <AuthGuard
            allow="administrador"
            notAllowMessage={
                <div className="container" style={{ padding: '60px 0 180px 0' }}>
                    <h3 className={"title-h3 text-bold"}>
                        Página exclusiva para administradores
                    </h3>
                </div>
            }
        >
            <>
                <BasePage>
                    <Breadcrumb>
                        <Link to="/admin">Painel Administrativo</Link>
                        <span>Produtos Internos</span>
                    </Breadcrumb>
                    <Header />
                    <ProductList
                        products={data.internProducts}
                        handleSelectProduct={handleSelectProduct}
                        handleOpenModalDelete={handleOpenModalDelete}
                    />
                </BasePage>
                {/* <When expr={showModalDelete}>
                    <ModalConfirm
                        title="Você tem certeza que deseja deletar este produto?"
                        description="O produto será deletado e as informações serão perdidas."
                        btn_text="Deletar produto"
                        statusResponse={statusResponse}
                        onConfirm={deleteProduct}
                        onClose={handleCloseModalDelete}
                    />
                </When> */}
                <CSSTransition
                    in={showModalDelete}
                    nodeRef={modalDeleteRef}
                    timeout={300}
                    unmountOnExit
                    classNames='fade'
                >
                    <div ref={modalDeleteRef}>
                        <ModalConfirm
                            title="Você tem certeza que deseja deletar este produto?"
                            description="O produto será deletado e as informações serão perdidas."
                            btn_text="Deletar produto"
                            statusResponse={statusResponse}
                            onConfirm={deleteProduct}
                            onClose={handleCloseModalDelete}
                        />
                    </div>
                </CSSTransition>
                <When expr={isLoading || internProductDelete.isLoading}>
                    <Spinner />
                </When>
            </>
        </AuthGuard>
    );
}
export default ProdutosInternosList;
