import { useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import logoDark from '../assets/images/logo-dark.svg';
import logoLight from '../assets/images/logo-light.svg';
import { Button } from '../common/button';
import Input from '../common/input/input';
import Spinner from '../common/spinner/spinner';
import styles from '../styles/login.module.css';
import { useForgotPassMutate } from '../hooks/useForgotPassMutate';

function ForgotPass() {
    const [formSubmited, setFormSubmited] = useState(false);
    const [formValue, setFormValue] = useState({
        email: { value: '', valid: false },
    });

    function validateEntries() {
        //typescript não aceita fazer isso de forma iterativa
        return formValue['email'].valid;
    }

    function setInputValue(inputName: string, value: string, valid: boolean) {
        setFormValue(state => {
            return {
                ...state,
                [inputName]: { value, valid }
            };
        })
    }

    const forgotPassMutate = useForgotPassMutate();

    function handleSubmit(e: any) {
        e.preventDefault();
        setFormSubmited(true);

        if(!validateEntries()) {
            return;
        }

        const data = {
            email: formValue.email.value,
        };
        forgotPassMutate.mutate(data);
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className={"col-md-8 " + styles['col-img']}>
                        <Link to="/">
                            <img src={logoLight} alt="logo Greendex" />
                        </Link>
                        <Link
                            to="/"
                            className={styles['back-button']}
                            
                        >
                            <FaChevronLeft className={styles['icon']} />
                            <span className={'text-body t-medium ' + styles['back-button-text']}>
                                Voltar
                            </span>
                        </Link>
                    </div>
                    <div className="col-md-4 col-xl-3 p-4">
                        <Link
                            to="/login"
                            className={'d-flex align-items-center mb-0 ' + styles['back-button']}
                            
                        >
                            <FaChevronLeft className={styles['icon']} />
                            <span className={'text-body t-medium ' + styles['back-button-text']}>
                                Voltar
                            </span>
                        </Link>
                        <Link to='/'>
                            <img
                                src={logoDark} alt="logo Greendex"
                                className={styles['logo-dark']}
                            />
                        </Link>
                        <h5
                            className="title-h5 text-semibold"
                            style={{ marginBottom: '24px' }}
                        >
                            Esqueci minha senha
                        </h5>
                        <form onSubmit={handleSubmit}>
                            <Input
                                inputName="email"
                                label="Login"
                                placeholder="Seu e-mail"
                                type="email"
                                errorMessage="* insira um e-mail válido"
                                formSubmited={formSubmited}
                                setInputValue={setInputValue}
                            />
                            {forgotPassMutate.isError && 
                                <p className="input-error">
                                    Erro em esqueci minha senha.
                                </p>
                            }
                            {forgotPassMutate.isSuccess && (
                                <p className="input-success">
                                    Operação realizada com sucesso. Verifique seu e-mail para redefinir sua senha.
                                </p>
                            )}
                            <Button.Primary
                                btnType='btn-primary'
                                type='submit'
                                style={{ width: '100%', marginTop: '16px' }}
                            >
                                Enviar
                            </Button.Primary>
                        </form>
                    </div>
                </div>
            </div>
            {(forgotPassMutate.isLoading) && <Spinner />}
        </>
    );
}
export default ForgotPass;