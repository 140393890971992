import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { useHeaderConfig } from "./useHeaderConfig";
import { RelatorioData } from "../types/relatorio";

const FetchData = async (): Promise<AxiosResponse<RelatorioData[]>> => {
    const config = useHeaderConfig();
    const response = await axios.get<RelatorioData[]>( `/api/relatorio`, config);
    return response;
};

export function useRelatoriosData() {
    const query = useQuery({
        queryFn: () => FetchData(),
        queryKey: ['relatorio-distribuidores'],
        staleTime: 2 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        ...query,
        data: query.data?.data
    };
}