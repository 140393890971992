import priceFormatter from "../../../app/formatter";
import { IPhotovoltaicSystems } from "../../../types/photovoltaic-systems";

function TablePhotovoltaicSystems(props: { photovoltaicSystems: IPhotovoltaicSystems[] }) {
    return (
        <table
            className="text-body"
            style={{ background: '#F2F2F2', borderRadius: '6px', textAlign: 'center', width: '100%' }}
            cellSpacing={0}
            cellPadding={8}
        >
            <tr className="color-greyscale-03" style={{ border: '0.5px solid #E5E5E5' }}>
                <th>Porte Sistema<br/>[kWp]</th>
                <th>Marca do<br/>módulo</th>
                <th>Potência do<br/>módulo [Wp]</th>
                <th>Marca do<br/>Inversor</th>
                <th>Potência do<br/>Inversor [kW]</th>
                <th>Valor</th>
            </tr>
            {props.photovoltaicSystems.map(system => (
                <tr style={{ border: '0.5px solid #E5E5E5', fontSize: '12px', color: '#808080' }}>
                    <th>{system.size}</th>
                    <th>{system.module_brand}</th>
                    <th>{system.module_power}</th>
                    <th>{system.inverter_brand}</th>
                    <th>{system.inverter_power}</th>
                    <th>{priceFormatter(system.total_price)}</th>
                </tr>
            ))}
        </table>
    );
}
export default TablePhotovoltaicSystems;