import Badge from './badge';
import styles from './styles.module.css';
import CardContent from './card-content';
import When from '../when/when';

interface BaseCardProps {
    id: string | number;
    thumbnail_link: string;
    title: string;
    description: string;
    paragraph_1: string;
    paragraph_2: string;
    badge?: string;
    children?: any;
}

const BaseCard = (props: BaseCardProps) => {
    return (
        <div className={'card ' + styles.card}>
            <img
                className={'card-img-top ' + styles['card-img-top']}
                src={props.thumbnail_link}
                alt={props.title}
            />
            <When expr={props.badge && props.badge !== "None"}>
                <Badge text={props.badge} />
            </When>
            <CardContent
                title={props.title}
                description={props.description}
            >
                {props?.children}
            </CardContent>
        </div>
    );
}

export default BaseCard