import { HTMLAttributes } from "react";

import styles from './styles.module.css';

interface ModalWrapperProps extends HTMLAttributes<HTMLDivElement> {}

const ModalWrapper = (props: ModalWrapperProps) => {
    return (
        <div className={styles['modal-wrapper']} {...props} />
    );
}

export default ModalWrapper