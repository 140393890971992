import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ResetPassMutate } from "../types/reset-pass";
import { useHeaderConfig } from "./useHeaderConfig";

const PostData = async (data: ResetPassMutate): Promise<any> => {
    const config = useHeaderConfig();
    return await axios.post('/api/troca-senha', data, config);
}

export function useResetPassMutate() {    
    const mutate = useMutation({
        mutationFn: PostData
    });

    return mutate;
}