import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

import BasePage from "../common/base-page";
import Field from "../common/field/field";
import Label from "../common/label/label";
import Input from "../common/input/input-zod";
import InputErrorMessage from "../common/input-error-message";
import { Button } from "../common/button";
import { useAddInternProductMutate } from "../hooks/useAddInternProductMutate";
import Breadcrumb from "../common/breadcrumb";
import SelectInput from "../common/input/select-input";
import AuthGuard from "../guards/auth-guard";

const addInternProductSchema = z.object({
    name: z.string()
        .min(1, {
            message: 'O nome é obrigatório'
        }),
    type: z.string()
        .min(1, {
            message: "O tipo é obrigatório"
        }),
    dash: z.string()
        .min(1, {
            message: "O report-id/link é obrigatório"
        }),
    description: z.string()
        .min(1, {
            message: "A descrição é obrigatória"
        }),
});

type AddInternProductData = z.infer<typeof addInternProductSchema>

const ProdutosInternosAdd = () => {
    const addInternProductForm = useForm<AddInternProductData>({
        resolver: zodResolver(addInternProductSchema),
    });

    const {
        handleSubmit,
        formState: { errors }
    } = addInternProductForm;

    const addInternProductMutate = useAddInternProductMutate();
    function addInternProduct(data: AddInternProductData) {
        const mutateData = {
            nome: data.name,
            tipo: data.type,
            dash: data.dash,
            descricao: data.description
        };
        addInternProductMutate.mutate(mutateData);
    }

    return (
        <AuthGuard
            allow="administrador"
            notAllowMessage={
                <div className="container" style={{ padding: '60px 0 180px 0' }}>
                    <h3 className={"title-h3 text-bold"}>
                        Página exclusiva para administradores
                    </h3>
                </div>
            }
        >
            <BasePage
                title='Adicionar Produto Interno'
                beadcrumb={
                    <Breadcrumb>
                        <Link to="/admin">Painel Administrativo</Link>
                        <Link to="/admin/produtos-internos">Produtos Internos</Link>
                        <span>Adicionar</span>
                    </Breadcrumb>}
            >
                <FormProvider {...addInternProductForm}>
                    <form onSubmit={handleSubmit(addInternProduct)}>
                        <Field>
                            <Label htmlFor="name">
                                Nome do produto
                            </Label>
                            <Input
                                type="name"
                                name="name"
                                placeholder="Defina o nome do produto"
                            />
                            <InputErrorMessage field="name" />
                        </Field>

                        <Field>
                            <Label htmlFor="type">
                                Tipo
                            </Label>
                            <SelectInput
                                name="type"
                                options={['capacidade', 'link']}
                            />
                            <InputErrorMessage field="type" />
                        </Field>

                        <Field>
                            <Label htmlFor="dash">
                                Dash
                            </Label>
                            <Input
                                type="text"
                                name="dash"
                                placeholder="Insira o report id ou link do dash"
                            />
                            <InputErrorMessage field="dash" />
                        </Field>

                        <Field>
                            <Label htmlFor="description">
                                Descrição
                            </Label>
                            <Input
                                type="text"
                                name="description"
                                placeholder="Insira a descrição do dash"
                            />
                            <InputErrorMessage field="description" />
                        </Field>

                        {addInternProductMutate.isError && (
                            <p className="input-error">Erro ao realizar cadastro.</p>
                        )}
                        {addInternProductMutate.isSuccess && (
                            <p className="input-success">Cadastro realizado com sucesso.</p>
                        )}
                        <Button.Primary
                            btnType='btn-primary'
                            type='submit'
                            style={{ width: '100%', marginTop: '16px' }}
                        >
                            Cadastrar
                        </Button.Primary>
                    </form>
                </FormProvider>
            </BasePage>
        </AuthGuard>
    );
}

export default ProdutosInternosAdd