import { Link } from 'react-router-dom';

import Breadcrumb from '../../common/breadcrumb';
import { PostData } from '../../types/blog';

interface PostProps {
    data: PostData
}

const Post = ({ data }: PostProps) => {
    return (
        <div className="container">
            <div className="row" style={{ margin: '50px 0' }}>
                <Breadcrumb>
                    <Link to="/blog">Blog</Link>
                    <span>{data.title}</span>
                </Breadcrumb>
                <div className="col-12">
                    <div className="blog" dangerouslySetInnerHTML={{__html: data.content}} />
                </div>
            </div>
        </div>
    );
}

export default Post