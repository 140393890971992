interface PrimaryButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    btnType: 
        | 'btn-primary'
        | 'btn-secondary' 
        | 'btn-social'
        | 'btn-filter'
        | 'btn-white'
        | 'btn-primary-outline'
        | 'btn-secondary-outline'
        | 'btn-red-outline'
}

const PrimaryButton = ({ btnType, ...props }: PrimaryButtonProps) => {
    return (
        <button
            className={'btn ' + btnType}
            {...props}
        />
    );
}

export default PrimaryButton