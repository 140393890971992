import { useEffect } from 'react';

import TopBar from './top-bar';
import ModalWrapper from './modal-wrapper';

interface IModalTestBI {
    title: string,
    iframe: string,
    onClose: Function
}

function ModalTestBI(props : IModalTestBI) {
    useEffect(() => {
        document.body.classList.add('scrollbar-hidden');
        return function () {
            document.body.classList.remove('scrollbar-hidden');
        } ;
     }, []);

    return (
        <ModalWrapper>
            <TopBar
                title={props.title}
                onClose={props.onClose}
            />
            <div dangerouslySetInnerHTML={{__html: props.iframe}} />
        </ModalWrapper>
    );
}
export default ModalTestBI;