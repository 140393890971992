import { HTMLAttributes } from "react";

import styles from '../../styles/purchases-historic.module.css';

interface UserItemContainerProps extends HTMLAttributes<HTMLDivElement> {}

const UserItemContainer = (props: UserItemContainerProps) => {
    return (
        <li className={styles["order-data"] + " " + styles["user-data"]}>
            <div
                className="d-flex justify-content-between align-items-center"
                {...props}
            />
        </li>
    );
}

export default UserItemContainer