import { Fragment } from "react"

interface WhenProps {
    expr: any | (() => boolean)
    children: JSX.Element
}

const When = ({ expr, children }: WhenProps) => {
    if (typeof expr === 'function' && !expr()) return null
    if (!expr) return null

    return children;
}

export default When