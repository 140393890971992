import { Button } from "../../../common/button";
import styles from "./pagination-filter.module.css";

function PaginationFilter(props: {
  filterValue: string;
  onChangeFilter: Function;
}) {
  const buttons: {
    filterValue: string;
    text: string;
  }[] = [
    {
      filterValue: "todas",
      text: "Todas",
    },
    {
      filterValue: "GD",
      text: "Geração Distribuída",
    },
    {
      filterValue: "mercado-livre",
      text: "Mercado Livre",
    },
    {
      filterValue: "relatorios-importacao",
      text: "Relatórios de Importação",
    },
    // {
    //     filterValue: 'dashboard',
    //     text: 'Dashboard',
    // },
  ];

  return (
    <div className={styles["scroll-x"]}>
      <div className={styles["filter-list"]}>
        {buttons.map((button) => (
          <Button.Primary
            key={button.filterValue}
            btnType={
              props.filterValue === button.filterValue
                ? "btn-filter"
                : "btn-white"
            }
            onClick={() => props.onChangeFilter(button.filterValue)}
          >
            {button.text}
          </Button.Primary>
        ))}
      </div>
    </div>
  );
}
export default PaginationFilter;
