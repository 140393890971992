import { useState } from 'react';

import BtnRightArrow from './btn-right-arrow';
import BtnLeftArrow from './btn-left-arrow';
import CarouselItem from './carousel-item';

import styles from './carousel-header.module.css';

import CarouselIndicator from '../../../common/carousel-indicator';
import useCarouselHeader from '../../../hooks/useCarouselHeader';
import { useHomeData } from '../../../hooks/useHomeData';
import { ICarsouselHeaderItem } from '../../../types/carousel-header';

function CarouselHeader() {
    const { data } = useHomeData();
    const {
        index,
        setIndex,
        handleTouchStart,
        handleTouchEnd
    } = useCarouselHeader(data.carousel ? data.carousel.length : 0);

    return (
        <header className={styles['header']}>
            <div className={'container ' + styles['carousel-container']}>
                <div className={styles['carousel-wrapper']}>
                    <div
                        className={styles['carousel-content-wrapper']}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleTouchEnd}
                    >
                        <BtnLeftArrow index={index} setIndex={setIndex}/>
                        <div
                            className={styles['carousel-content']}
                            style={{ transform: `translateX(-${index * 100}%)` }}
                        >
                            {data.carousel && data.carousel.map((item: ICarsouselHeaderItem) => 
                                 <CarouselItem key={item.title} item={item} />
                            )}
                        </div>
                        <BtnRightArrow
                            index={index}
                            setIndex={setIndex}
                            length={data.carousel ? data.carousel.length : 0}
                        />
                        <CarouselIndicator
                            length={data.carousel ? data.carousel.length : 0}
                            current={index}
                            show={0}
                            onSetCarousel={setIndex}
                        />
                    </div>
                </div>
            </div>
        </header>
    )
}
export default CarouselHeader;