import styles from './styles.module.css';

interface BreadcrumbListProps {
    children: any[]
}

const BreadcrumbList = ({ children }: BreadcrumbListProps) => {
    return (
        <nav>
            <ul className={styles['breadcrumb']}>
                {children}
            </ul>
        </nav>
    );
}

export default BreadcrumbList