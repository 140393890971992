import CircleAvatar from '../../common/circle-avatar';
import styles from '../../styles/profile.module.css';

interface UserDataProps {
    name: string
}

const UserData = ({ name }: UserDataProps) => {
    return (
        <div className={'row ' + styles['row-text']}>
            <div className="col-3 col-lg-2">
                <CircleAvatar
                    nameInitial={name.substring(0, 1)}
                    className='big'
                />
            </div>
            <div className='col-9 col-lg-10'>
                <h3
                    className={'title-h3 text-bold ' + styles['title']}
                    style={{ marginBottom: '24px' }}
                >
                    Bem vindo {name}
                </h3>
                {/* <p className={'text-body text-large ' + styles['subtitle']}>
                    Lorem ipsum dolor sit amet consectetur. Lobortis amet eget ut dignissim elementum magna elementum malesuada faucibus. Quam ac morbi ligula pe.
                </p> */}
            </div>
        </div>
    );
}

export default UserData