import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Form from './form';
import ButtonSubmit from './button-submit';

import BasePage from '../../common/base-page';
import Spinner from '../../common/spinner/spinner';
import Breadcrumb from '../../common/breadcrumb';
import When from '../../common/when/when';
import { useBlogMutate } from '../../hooks/useBlogMutate';
import { useBlogPostData } from '../../hooks/useBlogPostData';
import { useBlobMutate } from '../../hooks/useBlobMutate';
import { useBlogPostMutate } from '../../hooks/useBlogPostMutate';


let Inline = Quill.import('blots/inline');
class LinkBlot extends Inline {
    static create(value: string) {
        let node = super.create();

        node.setAttribute("href", value);
        node.setAttribute("class", "btn-primary");

        return node;
    }
    
    static formats(node: { getAttribute: (arg0: string) => any; }) {
        return node.getAttribute("href");
    }
}
LinkBlot.blotName = 'link';
LinkBlot.tagName = 'a';
Quill.register('formats/a', LinkBlot);

function BlogEditor() {
    const { id } = useParams();
    const { mutate, isSuccess } = useBlogMutate();
    const { data, isLoading } = useBlogPostData(id || '');
    const location = useLocation();

    const [editMode, setEditMode] = useState<boolean>();
    // const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const [enteredTitle, setEnteredTitle] = useState('');
    const [enteredDescription, setEnteredDescription] = useState('');
    const [enteredImageUrl, setEnteredImageUrl] = useState('');
    const [enteredContent, setEnteredContent] = useState('');
    const [enteredCategory, setEnteredCategory] = useState('');
    const [enteredPagePath, setEnteredPagePath] = useState('');
    const [enteredId, setEnteredId] = useState(-1);

    useEffect(() => {
        if(location.pathname === "/admin/blog/add") {
            setEditMode(false);
        } else {
            setEditMode(true);
        }
    }, [location]);

    useEffect(() => {
        if(editMode && data) {
            setEnteredId(data.id);
            setEnteredTitle(data.title);
            setEnteredDescription(data.description);
            setEnteredImageUrl(data.image_url);
            setEnteredContent(data.content);
            setEnteredCategory(data.category);
            setEnteredPagePath(data.page_path);
        }
    }, [editMode, data]);

    const quillRef = useRef<ReactQuill | null>(null);
    const [editor, setEditor] = useState<any>();

    const blobMutate = useBlobMutate();
    const blobMutateInputImage = useBlobMutate();

    const imageHandler = (e: any) => {
        if(quillRef.current) {
            setEditor(quillRef.current.getEditor());
            // const editor = quillRef.current.getEditor();
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();
        
            input.onchange = async () => {
                const file = input && input.files ? input.files[0] : null;
                if(!file) return;
                if (/^image\//.test(file.type)) {
                    const formData = new FormData();
                    formData.append("file", file);

                    const data = {
                        data: formData,
                        contentType: 'multipart/form-data'
                    }
                    blobMutate.mutate(data);
                } else {
                    console.log('You could only upload images.');
                }
                
            };
        }
    }
    
    useEffect(() => {
        if(editor && blobMutate.data) {
            const selection = editor.getSelection();
            if(selection !== null) {
                editor.insertEmbed(selection.index, "image", blobMutate.data.url);
            }
        }
    }, [editor, blobMutate.data, blobMutate.isSuccess]);

    const modules = useMemo(() => ({
        toolbar: {
          container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ color: [
                    "#00414C", '#00343D', '#0D4B55', '#265D67', '#407179', '#59838A',
                    '#42D1A3', '#35A782', '#4CD3A8', '#5ED8B1', '#71DDBA', '#84E1C3',
                    '#B7CD25', '#92A41E', '#BBD030', '#C2D445', '#C9DA5C', '#D0DE71',
                    '#00C247', '#004CE8', '#FFE16A', '#FF3333', '#D8D8D8', '#879AC1',
                    '#212121', '#424242', '#616161', '#757575', '#9E9E9E', '#BDBDBD', '#E0E0E0', '#EEEEEE', '#F5F5F5', '#FAFAFA',
                    '#FFFFFF', '#000000', '#F54336', '#EA1E61', '#9D28AC', '#673AB3',
                    '#3F51B2', '#1A96F0', '#00A9F1', '#00BCD3', '#009689', '#4AAF57',
                    '#8BC255', '#CDDC4C', '#FFEB4F', '#FFC02D', '#FF981F', '#FF5726',
                    '#7A5548', '#607D8A', '#F2F4F2'
                ] }, { background: [
                    "#00414C", '#00343D', '#0D4B55', '#265D67', '#407179', '#59838A',
                    '#42D1A3', '#35A782', '#4CD3A8', '#5ED8B1', '#71DDBA', '#84E1C3',
                    '#B7CD25', '#92A41E', '#BBD030', '#C2D445', '#C9DA5C', '#D0DE71',
                    '#00C247', '#004CE8', '#FFE16A', '#FF3333', '#D8D8D8', '#879AC1',
                    '#212121', '#424242', '#616161', '#757575', '#9E9E9E', '#BDBDBD', '#E0E0E0', '#EEEEEE', '#F5F5F5', '#FAFAFA',
                    '#FFFFFF', '#000000', '#F54336', '#EA1E61', '#9D28AC', '#673AB3',
                    '#3F51B2', '#1A96F0', '#00A9F1', '#00BCD3', '#009689', '#4AAF57',
                    '#8BC255', '#CDDC4C', '#FFEB4F', '#FFC02D', '#FF981F', '#FF5726',
                    '#7A5548', '#607D8A', '#F2F4F2'
                ] }],
                [{ list:  "ordered" }, { list:  "bullet" }],
                [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
                ["link", "image", "video"],
            ],
            handlers: {
                image: imageHandler
            }
        },
    }), []);

    function handleAddPost() {
        // setLoading(true);
        setSuccess(false);
        setError(false);

        const title = enteredTitle.trim();
        const description = enteredDescription.trim();
        const imageUrl = enteredImageUrl.trim();
        const content = enteredContent.trim();
        const category = enteredCategory.trim();
        const pagePath = enteredPagePath.trim();

        if (title === "" || description === "" || 
            imageUrl === "" || content === "" || 
            category === "" ||  pagePath === "") {
            // setLoading(false);
            return;
        }

        const data = {
            title,
            description,
            image_url: imageUrl,
            content,
            category,
            page_path: pagePath
        };
        mutate(data);
    }

    useEffect(() => {
        setSuccess(isSuccess);
    }, [isSuccess]);

    const blogPostMutate = useBlogPostMutate();

    function handleEditPost() {
        setSuccess(false);
        setError(false);

        const title = enteredTitle.trim();
        const description = enteredDescription.trim();
        const imageUrl = enteredImageUrl.trim();
        const content = enteredContent.trim();
        const category = enteredCategory.trim();
        const pagePath = enteredPagePath.trim();

        if (title === "" || description === "" || 
            imageUrl === "" || content === "" || 
            category === "" ||  pagePath === "") {
            return;
        }

        const data = {
            id: enteredId,
            title,
            description,
            image_url: imageUrl,
            content,
            category,
            page_path: pagePath
        };
        blogPostMutate.mutate(data);
    }

    const handleImageInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;
        if(!file) return;

        const formData = new FormData();
        formData.append("file", file);

        const data = {
            data: formData,
            contentType: 'multipart/form-data'
        }
        blobMutateInputImage.mutate(data);
    }

    useEffect(() => {
        if(blobMutateInputImage.data && blobMutateInputImage.isSuccess) {
            setEnteredImageUrl(blobMutateInputImage.data.data.url);
        }
    }, [blobMutateInputImage.data, blobMutateInputImage.isSuccess]);

    return (
        <BasePage
            title='Editor'
            col={12}
            beadcrumb={(
                <Breadcrumb>
                    <Link to="/admin">Painel Administrativo</Link>
                    <Link to="/admin/blog">Blog</Link>
                    <span>{editMode ? "Editar post" : "Adicionar post"}</span>
                </Breadcrumb>
            )}
        >
            <>
                <Form
                    enteredTitle={enteredTitle}
                    enteredDescription={enteredDescription}
                    enteredCategory={enteredCategory}
                    enteredPagePath={enteredPagePath}
                    enteredImageUrl={enteredImageUrl}
                    setEnteredTitle={setEnteredTitle}
                    setEnteredDescription={setEnteredDescription}
                    setEnteredCategory={setEnteredCategory}
                    setEnteredPagePath={setEnteredPagePath}
                    setEnteredImageUrl={setEnteredImageUrl}
                    handleImageInput={handleImageInput}
                />
                <section className='row'>
                    <div className='col-md-6'>
                        <ReactQuill
                            ref={quillRef}
                            theme="snow"
                            value={enteredContent}
                            onChange={e => setEnteredContent(e)}
                            modules={modules}
                            // formats={formats}
                        />
                    </div>
                    <div
                        className='col-md-6 blog'
                        dangerouslySetInnerHTML={{__html: enteredContent}}
                    />
                </section>
                <When expr={success || blogPostMutate.isSuccess}>
                    <p className="input-success">Operação realizada com sucesso!</p>
                </When>
                <When expr={error}>
                    <p className="input-error">Erro ao realizar a operação!</p>
                </When>
                <ButtonSubmit
                    editMode={editMode}
                    handleEditPost={handleEditPost}
                    handleAddPost={handleAddPost}
                />
                <When expr={(editMode && isLoading) || blogPostMutate.isLoading}>
                    <Spinner />
                </When>
            </>
        </BasePage>
    );
}
export default BlogEditor;
