import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { PostData } from "../types/blog";
import { useHeaderConfig } from "./useHeaderConfig";

const FetchData = async (page_path: string): Promise<AxiosResponse<PostData>> => {
    const config = useHeaderConfig();
    const response = await axios.get<PostData>(`/api/blog?page_path=${page_path}`, config);
    return response;
};

export function useBlogPostData(page_path: string) {
    const query = useQuery({
        queryFn: () => FetchData(page_path),
        queryKey: ['blog-data', page_path],
        enabled: !!page_path,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        ...query,
        data: query.data?.data
    };
}