import { BsPencilSquare } from 'react-icons/bs';

import Line from './line';
import styles from '../../styles/my-data.module.css';

interface NonEditableFieldProps {
    title: string,
    value: string | undefined
}

const NonEditableField = ({ title, value }: NonEditableFieldProps) => {
    return (
        <div>
            <div className={styles['data-text-wrapper']}>
                <p className="text-body text-xlarge text-semibold color-greyscale-01">
                    {title}
                </p>
                <div className="d-flex justify-content-between">
                    <p className="text-body text-large color-greyscale-03">
                        {value}
                    </p>
                    <BsPencilSquare
                        className="color-greyscale-03"
                        style={{ opacity: '0.3'}}
                    />
                </div>
            </div>
            <Line />
        </div>
    );
}

export default NonEditableField