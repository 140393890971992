import { useState } from "react";
import { Link } from "react-router-dom";

import Filter from "./filter";
import Breadcrumb from "../../common/breadcrumb";
import When from "../../common/when/when";
import AuthGuard from "../../guards/auth-guard";

const RelatorioNew = () => {
    const [base64STR, setBase64STR] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmited, setIsSubmited] = useState(false);

    async function onSuccess(data: { data: string }, variables: any, context: any) {
        setBase64STR(data.data);
    }

    return (
        <AuthGuard
            allow="greener"
            notAllowMessage={
                <div className="container" style={{ padding: '60px 0 180px 0' }}>
                    <h3 className={"title-h3 text-bold"}>
                        Página exclusiva para administradores
                    </h3>
                </div>
            }
        >
            <div className="bg-grey">
                <div className="container">
                    <div style={{ marginBottom: '32px', paddingTop: '48px' }}>
                        <Breadcrumb>
                            <Link to="/admin">Painel Administrativo</Link>
                            <Link to="/relatorio-distribuidores">Relatório de Distribuidores</Link>
                            <span>Gerar Relatório</span>
                        </Breadcrumb>
                        <h3 className={'title-h3 text-bold'}>
                            Gerar Relatório
                        </h3>
                    </div>
                    <Filter
                        onSuccess={onSuccess}
                        setIsLoading={setIsLoading}
                        setIsSubmited={setIsSubmited}
                    />
                    <When expr={isSubmited}>
                        <div style={{ display: 'flex', gap: '24px', alignItems: 'center', justifyContent: 'center' }}>
                            <p>O relatório está sendo gerado, esse processo dura em média 2 minutos. Assim, que aperação for finalizada, será possível acessá-lo em <Link to="/relatorio-distribuidores">Relatório de Distribuidores</Link>.</p>
                        </div>
                    </When>
                </div>
            </div>
        </AuthGuard>
    );
}

export default RelatorioNew;