import { Link } from "react-router-dom";

import BasePage from "./base-page";
import EditableFields from "./editable-fields";
import NonEditableFields from "./non-editable-fields";

import Breadcrumb from "../../common/breadcrumb";
import { useProfileData } from "../../hooks/useProfileData";
import styles from '../../styles/my-data.module.css';

function MyData() {
    const { profile } = useProfileData();

    return (
        <>
            {profile ? (
                <BasePage>
                    <Breadcrumb>
                        <Link to="/perfil">Perfil</Link>
                        <span>Meus dados</span>
                    </Breadcrumb>
                    <div className={'row ' + styles['row-text']}>
                        <h3
                            className={'title-h3 text-bold ' + styles['title']}
                            style={{ marginBottom: '24px' }}
                        >
                            Meus dados
                        </h3>
                        {/* <p className={'text-body text-large ' + styles['subtitle']}>
                            Lorem ipsum dolor sit amet consectetur. Lobortis amet eget ut dignissim elementum magna elementum malesuada faucibus. Quam ac morbi ligula pe.
                        </p> */}
                    </div>
                    <section className={styles['data']}>
                        <NonEditableFields />
                        <EditableFields />
                    </section>
                </BasePage>
                            
            ) : (
                <Link to='/login'>Fazer login</Link>
            )}
        </>
    );
}
export default MyData;