import { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { IProduct } from '../../../types/produto';
import styles from './search-product.module.css';

function SearchProduct(props: { products: IProduct[], style?: any }) {
    const [inputValue, setInputValue] = useState<string>('');
    const [productsSearch, setProductsSearch] = useState<IProduct[] | []>([]);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        
        setInputValue(e.target.value);
        if(props.products.length === 0) return;
        if(inputValue === '') {
            setProductsSearch([]);
            return;
        }

        setProductsSearch(
            props.products.filter(product => 
                product.title.toLocaleLowerCase()
                    .includes(inputValue.toLocaleLowerCase())
            ).slice(0, 5)
        );
    }

    return (
        <div className={styles['search']} style={props.style}>
            <input
                type="text"
                className={styles['search-input']}
                value={inputValue}
                onChange={(e) => handleChange(e)}
                placeholder="Pesquise um produto"
            />
            <AiOutlineSearch className={styles['search-icon']} />
            {
                inputValue !== '' &&
                <ul className={styles['search-list']}>
                    {
                        productsSearch.map(product => (
                            <Link to={`/produto/${product.id}`}>
                                <li>{product.title}</li>
                            </Link>
                        ))
                    }
                </ul>
            }
        </div>
    );
}
export default SearchProduct;