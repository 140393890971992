import { SetStateAction } from "react";
import { FaChevronRight } from "react-icons/fa";

import styles from './carousel-header.module.css';

interface BtnRightArrowProps {
    index: number;
    length: number;
    setIndex: (value: SetStateAction<number>) => void
}

const BtnRightArrow = ({index, length, setIndex}: BtnRightArrowProps) => {
    return (
        <button
            onClick={() => setIndex(id => ++id)}
            disabled={index >= (length - 1)}
            className={
                styles['right-arrow'] +
                (index >= (length - 1) 
                    ? ' ' + styles['arrow-disabled'] : ''
                )
            }
        >
            <FaChevronRight />
        </button>
    );
}

export default BtnRightArrow