import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styles from '../../home/pagination-cards/pagination-cards.module.css';
import BaseCard from "../../../common/base-card";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { PostData } from "../../../types/blog";

function BlogPaginationCards(props: { posts: PostData[] }) {
    const gaEventTracker = useAnalyticsEventTracker('Card');
    const [posts, setPosts] = useState<PostData[] | []>([]);
    const [animation, setAnimation] = useState('fade-in');
    useEffect(() => {
        setAnimation(_ => 'fade-out');
        setTimeout(() => {
            setPosts(_ => props.posts);
            setAnimation(_ => 'fade-in');
        }, 500);
    }, [props.posts]);

    return (
        <div className="row">
            {posts.map(item => (
                <div
                    key={item.id}
                    style={{ marginBottom: '42px' }}
                    className={'col-xl-3 col-lg-4 col-md-6 ' + styles[animation]}
                >
                    <Link
                        to={`/blog/${item.page_path}`}
                        onClick={() => gaEventTracker(
                            `Clique no card do blog: ${item.title}`,
                            `Clique no card do blog para a visualização do post ${item.title}`
                        )}
                    >
                        <BaseCard
                            id={item.id}
                            thumbnail_link={item.image_url}
                            title={item.title}
                            description={item.description}
                            paragraph_1={''}
                            paragraph_2={''}
                        />
                    </Link>
                </div>
            ))}
        </div>
    );
}

export default BlogPaginationCards;