import { Link } from 'react-router-dom';

import Nav from './nav';
import UserData from './user-data';
import Spinner from '../../common/spinner/spinner';
import { useProfileData } from '../../hooks/useProfileData';
import styles from '../../styles/profile.module.css';

function Profile() {
    const { profile, isLoading } = useProfileData();
    
    return (
        <>
            {profile ? (
                <div className="bg-grey">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className={'col-md-10 ' + styles['col-content']}>
                                <UserData name={profile.nome} />
                                <Nav />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Link to='/login'>Fazer login</Link>
            )}
            {isLoading && <Spinner />}
        </>
    );
}
export default Profile;