import { Link } from "react-router-dom";

import BaseCard from "../base-card";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

interface CardProps {
    id: string;
    thumbnail_link: string;
    title: string;
    description: string;
    paragraph_1: string;
    paragraph_2: string;
    redirect_link: string;
    badge?: string;
}

const Card = (props: CardProps) => {
    const gaEventTracker = useAnalyticsEventTracker('Card');
    
    return (
        <Link
            to={props.redirect_link}
            onClick={() => gaEventTracker(
                `Clique no card: ${props.title}`,
                `Clique no card da página '${window.location.pathname}' para a visualização do produto ${props.title}`
            )}
        >
            <BaseCard {...props} />
        </Link>
    );
}

export default Card