import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import styles from '../../styles/profile.module.css';

interface NavItemProps {
    link: string;
    icon: string;
    title: string;
    description: string;
}

const NavItem = ({ link, icon, title, description }: NavItemProps) => {
    return (
        <Link
            to={link}
            className="d-flex justify-content-between align-items-center"
        >
            <div className="d-flex">
                <img src={icon} alt="Meus dados" />
                <div className={styles['nav-text-wrapper']}>
                    <p className="text-body text-xlarge color-greyscale-01">
                        {title}
                    </p>
                    <p className="text-body color-greyscale-03">
                        {description}
                    </p>
                </div>
            </div>
            <FaChevronRight className='color-greyscale-03' />
        </Link>
    );
}

export default NavItem