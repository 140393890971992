import styles from "./footer.module.css";
import logoDark from "../../assets/images/logo-dark.svg";
import { Link } from "react-router-dom";
import { RiLinkedinFill } from "react-icons/ri";
import { AiFillInstagram } from "react-icons/ai";

function Footer() {
  return (
    <footer className={styles["bg"]}>
      <div className="container">
        <div className="row">
          <div className={"col-lg-4 " + styles["col-logo"]}>
            <Link to="/">
              <img
                src={logoDark}
                alt="Logo Greendex"
                className={styles["logo"]}
              />
            </Link>
          </div>
          <div className={"col-lg-4 d-flex " + styles["col-category-greendex"]}>
            <div>
              <h6 className={"title-h6 text-w-medium " + styles["list-title"]}>
                Categorias
              </h6>
              <ul className={styles["list-items"]}>
                <li>
                  <Link to="/#GD">
                    <p className="text-body text-medium">Geração Distribuída</p>
                  </Link>
                </li>
                <li>
                  <Link to="/#mercado-livre">
                    <p className="text-body text-medium">Mercado Livre</p>
                  </Link>
                </li>
                <li>
                  <Link to="/#relatorios-importacao">
                    <p className="text-body text-medium">
                      Relatórios de Importação
                    </p>
                  </Link>
                </li>
                {/* <li>
                                    <Link to="/#dashboard">
                                        <p className="text-body text-medium">
                                            Dashboards
                                        </p>
                                    </Link>
                                </li> */}
              </ul>
            </div>
            <div>
              <h6 className={"title-h6 text-w-medium " + styles["list-title"]}>
                Greendex
              </h6>
              <ul className={styles["list-items"]}>
                <li>
                  <a
                    href="https://www.greener.com.br/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="text-body text-medium">Greener</p>
                  </a>
                </li>
                <li>
                  <Link to="/perfil">
                    <p className="text-body text-medium">Minha Conta</p>
                  </Link>
                </li>
                <li>
                  <a
                    href="https://greendexv2beta.blob.core.windows.net/greendex-assets/Greendex_-_Contrato_de_Licenciamento_de_Uso_de_Plataforma_e_Prestacao_de_Servicos_1.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="text-body text-medium">Termos de Uso</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className={'col-lg-2 ' + styles['col-contact']}>
              <h6 className={'title-h6 text-w-medium ' + styles['list-title']}>
                  Fale conosco
              </h6>
              <ul className={styles['list-items']}>
                  <li>
                      <Link to='/contato'>Contato</Link>
                  </li>
              </ul>
          </div>
          <div className={"col-lg-1 " + styles["col-social"]}>
            <h6 className={"title-h6 text-w-medium " + styles["list-title"]}>
              Social
            </h6>
            <div className="d-flex" style={{ gap: "26px" }}>
              <a
                href="https://www.instagram.com/greendex.info/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillInstagram
                  style={{ fontSize: "20px", color: "#0A142F" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/greendex.info/about/"
                target="_blank"
                rel="noreferrer"
              >
                <RiLinkedinFill
                  style={{ fontSize: "20px", color: "#0A142F" }}
                />
              </a>
            </div>
          </div>
        </div>
        <div className={styles["line"]}></div>
        <div className="d-flex justify-content-center">
          <span className={styles["copy"]}>
            © {new Date().getFullYear()} Greendex | All Rights Reserved
          </span>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
