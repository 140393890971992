import { BsFillPlayFill } from 'react-icons/bs';
import { MdDeleteOutline } from 'react-icons/md';

import { FilterData } from '../../../types/filter';

interface FilterItemActionsProps {
    filter: FilterData,
    applyFilter: Function,
    deleteFilter: Function
}

const FilterItemActions = ({ filter, applyFilter, deleteFilter }: FilterItemActionsProps) => {
    return (
        <div className="d-flex" style={{ gap: '4px' }}>
            <BsFillPlayFill
                className='color-greyscale-04'
                style={{ fontSize: '24px', cursor: 'pointer' }}
                onClick={() => applyFilter(filter.state)}
            />
            <MdDeleteOutline
                className='color-red'
                style={{ fontSize: '24px', cursor: 'pointer' }}
                onClick={() => deleteFilter(filter)}
            />
        </div>
    );
}

export default FilterItemActions