import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { FilterDelete } from "../types/filter";
import { useHeaderConfig } from "./useHeaderConfig";

const DeleteData = async (data: FilterDelete) => {
    const defaultConfig = useHeaderConfig();
    const config = {...defaultConfig, data}
    return await axios.delete('api/filtro', config);
}

export function useFilterDelete() {
    const queryClient = useQueryClient();
    const mutate = useMutation({
        mutationFn: DeleteData,
        onSuccess: () => {
            queryClient.invalidateQueries(['filter-data']);
        }
    });

    return mutate;
}