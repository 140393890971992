import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { HomeData } from "../types/home";
import { useHeaderConfig } from "./useHeaderConfig";

const FetchData = async (): Promise<AxiosResponse<HomeData>> => {
    const config = useHeaderConfig();
    const response = await axios.get<HomeData>('/api/home', config);
    return response;
};

export function useHomeData() {
    const query = useQuery({
        queryFn: FetchData,
        queryKey: ['home-data'],
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        ...query,
        data: {
            products: [
                ...(query.data?.data.pacotes ? query.data?.data.pacotes : []),
                ...(query.data?.data["produtos_cliente"]
                        ? query.data?.data["produtos_cliente"].filter(product => product.id !== 'radar-gd')
                        : [])
            ],
            carousel: query.data?.data.carrossel,
            packages: query.data?.data.pacotes,
            internProducts: [...(query.data?.data["produtos_internos"]
                ? query.data?.data["produtos_internos"]
                : [])
            ]
        }
    };
}