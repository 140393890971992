const Loader = () => {
    return (
        <div style={{
            border: '5px solid #E0E0E0', //var(--greyscale-07)
            borderTop: '5px solid #42D1A3', //var(--secondary-01)
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            animation: 'spin 1s linear infinite'
        }} />
    );
}

export default Loader