import Loader from './loader';

const FiltersLoading = () => {
    return (
        <aside
            className='d-flex align-items-center'
            style={{padding: '16px', gap: '16px', flexDirection: 'column' }}
        >
            <Loader />
            <h5
                className="title-h5 color-greyscale-04"
                style={{ fontSize: '18px', textAlign: 'center' }}
            >
                Gerando relatório
            </h5>
        </aside>
    );
}

export default FiltersLoading