import StatusSection from "../components/monitor-api/status-section";
import { HistoricSection } from "../components/monitor-api/historic-section";
import { useEffect, useState } from "react";

interface MonitorApiData {
    api_status: {
        data: string,
        status_atual: string,
        off_time: number
    }[],
    history: []
};

export const commits = [
    {
        title: 'Range de preços de sistemas com filtros',
        date: '2023-06-06',
        description: 'Recurso de range de preços de sistemas fotovoltaicos com filtros de marcas de módulos e inversores.'
    },
    {
        title: 'Range de preços de sistemas',
        date: '2023-06-01',
        description: 'Recurso de range de preços de sistemas fotovoltaicos.'
    },
    {
        title: 'Dimensionador de sistemas',
        date: '2023-05-29',
        description: 'Recurso de dimensionador de sistemas fotovoltaicos.'
    },
    {
        title: 'Autenticação e autorização',
        date: '2023-05-24',
        description: 'Fluxo de autenticação e de autorização a recursos.'
    },
    {
        title: 'Manter usuários',
        date: '2023-05-18',
        description: 'Criação do recurso usuários com fluxos e definições de dados.'
    },
];

const MonitorApi = () => {
    const [apiStatus, setApiStatus] = useState<MonitorApiData>();
    useEffect(() => {
        fetch('/api/api-status')
            .then(response => response.json())
            .then(data => setApiStatus({...data, history: commits}))
    }, []);

    return (
        <main className="bg-grey" style={{ padding: '15px' }}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        {apiStatus?.api_status && (
                            <StatusSection
                                title="Status"
                                apiName="API Preços"
                                status={apiStatus?.api_status}
                            />
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {apiStatus?.history && (
                            <HistoricSection
                                title="Histórico"
                                commits={apiStatus?.history}
                            />
                        )}
                    </div>
                </div>
            </div>
        </main>
    );
};

export default MonitorApi;