import SearchUser from './search-user';

import styles from '../../styles/purchases-historic.module.css';

interface HeaderProps {
    filterValue: string,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handleSearch: (e: any) => void
}

const Header = ({ filterValue, handleChange, handleSearch }: HeaderProps) => {
    return (
        <div className={"row " + styles["row-text"]}>
            <div
                className="d-flex justify-content-between"
                style={{
                    width: "100%",
                    marginBottom: "24px",
                    flexWrap: "wrap",
                    gap: "16px",
                }}
            >
                <h3 className={"title-h3 text-bold " + styles["title"]}>
                    Gerenciar Usuários
                </h3>
                {/* <Button
                    className='btn-primary'
                    text='+ Adicionar'
                    onClick={() => {}}
                /> */}
            </div>
            <SearchUser
                filterValue={filterValue}
                handleChange={handleChange}
                handleSearch={handleSearch}
            />
        </div>
    );
}

export default Header