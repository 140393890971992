import { Button } from '../button';
import styles from '../styles.module.css';
import priceFormatter from '../../../app/formatter';

interface ProductActionsProps {
    id: string,
    title: string
    type: 'produto' | 'pacote'
}

const DiscontinuedProductActions = (props: ProductActionsProps) => {
    return (
        <div>
            <div style={{ marginBottom: '34px' }}>
                <span className="color-red"><b>Aviso Importante: Produtos Descontinuados</b></span>
            </div>
            <Button.LearnMore
                title={props.title}
                link={`/${props.type}/${props.id}`}
            />
        </div>
    );
}

export default DiscontinuedProductActions