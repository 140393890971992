import { HTMLAttributes } from "react";

import styles from './styles.module.css';
import When from "../when/when";

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
    col?: number
}

const Container = ({ col, children }: ContainerProps) => {
    return (
        <div className="bg-grey">
            <div className="container">
                <div className="row">
                    <When expr={!col}>
                        <div className="col-md-1" />
                    </When>
                    <div className={
                        (col && col === 12 )
                            ? 'col-md-12 ' + styles['col-content']
                            : 'col-md-10 ' + styles['col-content']
                    }>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Container