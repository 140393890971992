import { InputHTMLAttributes, useState } from "react";
import { useFormContext } from 'react-hook-form'
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

interface InputPasswordProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string
};

function InputPassword(props: InputPasswordProps) {
    const { register } = useFormContext();
    const [typePassword, setTypePassword] = useState<'text' | 'password'>('password');

    return (
        <div style={{ position: 'relative' }}>
            <input
                className="input-register"
                type={typePassword}
                {...register(props.name)} 
                {...props}
            />
            {typePassword === 'text' ? (
                <AiFillEye
                    style={{
                        position: 'absolute',
                        top: '18px',
                        right: '18px',
                        cursor: 'pointer',
                        color: '#4D4D4D',
                    }}
                    onClick={() =>setTypePassword('password')}
                />
            ) : (
                <AiFillEyeInvisible
                    style={{
                        position: 'absolute',
                        top: '18px',
                        right: '18px',
                        cursor: 'pointer',
                        color: '#4D4D4D',
                    }}
                    onClick={() =>setTypePassword('text')}
                />
            )}
        </div>
    );
}
export default InputPassword;