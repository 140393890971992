import { Dispatch, SetStateAction } from "react";

import ButtonAddImage from "./button-add-image";
import ButtonEditImage from "./button-edit-image";
import When from "../../common/when/when";

interface FormProps {
    enteredTitle: string,
    enteredDescription: string,
    enteredCategory: string,
    enteredPagePath: string,
    enteredImageUrl: string,
    setEnteredTitle: Dispatch<SetStateAction<string>>,
    setEnteredDescription: Dispatch<SetStateAction<string>>,
    setEnteredCategory: Dispatch<SetStateAction<string>>,
    setEnteredPagePath: Dispatch<SetStateAction<string>>,
    setEnteredImageUrl: Dispatch<SetStateAction<string>>,
    handleImageInput: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Form = (props: FormProps) => {
    return (
        <section>
            <p className="label-register">Título</p>
                <input
                    className="input-register"
                    type="text"
                    value={props.enteredTitle}
                    onChange={e => props.setEnteredTitle(e.target.value)}
                />
                <p className="label-register">Descrição</p>
                <input
                    className="input-register"
                    type="text"
                    value={props.enteredDescription}
                    onChange={e => props.setEnteredDescription(e.target.value)}
                />
                <p className="label-register">Categoria</p>
                <input
                    className="input-register"
                    type="text"
                    value={props.enteredCategory}
                    onChange={e => props.setEnteredCategory(e.target.value)}
                />
                <p className="label-register">Caminho</p>
                <input
                    className="input-register"
                    type="text"
                    value={props.enteredPagePath}
                    onChange={e => props.setEnteredPagePath(e.target.value)}
                />
                <p className="label-register">Imagem</p>
                <When expr={props.enteredImageUrl === ''}>
                    <ButtonAddImage
                        handleImageInput={props.handleImageInput}
                    />
                </When>
                <When expr={props.enteredImageUrl !== ''}>
                    <ButtonEditImage
                        enteredImageUrl={props.enteredImageUrl}
                        setEnteredImageUrl={props.setEnteredImageUrl}
                    />
                </When>
        </section>
    );
}

export default Form