import { Button } from "../../../common/button";
import dashV1 from "../../../assets/images/dash-v1.png";
import styles from './about-us.module.css';

function AboutUs(props: { onClick: Function }) {
    return (
        <section className={styles['section']}>
            <div className={"container " + styles['container']}>
                <div className={"row " + styles['row']}>
                    <div
                        className="col-lg-6 d-flex"
                        style={{ flexDirection: 'column', gap: '32px' }}
                    >
                        <h6
                            className={"text-body color-primary-01 " + styles['text']}
                            style={{ marginBottom: '-16px'/*, textTransform: 'uppercase'*/ }}
                        >
                            Tome decisões mais estratégicas com a Greendex
                        </h6>
                        <h2 className={"title-h2 text-bold color-primary-01 " + styles['title']}>
                            Identifique <span className="color-secondary-01">oportunidades</span> e acompanhe as tendências do mercado
                        </h2>
                        <div className={styles['description']}>
                            <p className={"text-body color-primary-01 " + styles['text-desc']}>
                                Nossa plataforma centraliza e monitora todos os indicadores do setor <span className="text-bold">fotovoltaico</span> em um só lugar, permitindo acesso a informações cruciais para decisões mais assertivas e otimização da sua gestão.
                            </p>
                            <p className={"text-body color-primary-01 " + styles['text-desc']}>
                                Com nossos <span className="text-bold">dashboards online</span>, você pode impulsionar seus resultados e maximizar seus negócios de uma forma simples, dinâmica e segura.
                            </p>
                        </div>
                        {/* <Button.Primary
                            btnType="btn-primary"
                            onClick={() => props.onClick()}
                            style={{ width: 'max-content' }}
                        >
                            Conheça as soluções
                        </Button.Primary> */}
                    </div>
                    <div className="col-lg-6 d-flex align-items-center justify-content-center">
                        <img
                            src={dashV1}
                            alt="Sobre nós"
                            style={{ width: '100%' }}
                            className={styles['image']}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
export default AboutUs;