import { useEffect, useState } from "react";
import { MdMail } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";

import { phoneMask } from "../app/mask";
import BasePage from "../common/base-page";
import { Button } from "../common/button";
import Input from "../common/input/input";
import Spinner from "../common/spinner/spinner";
import styles from '../styles/my-data.module.css';
import { useContatoMutate } from "../hooks/useContatoMutate";

function Contact() {
    const [formSubmited, setFormSubmited] = useState(false);
    const [message, setMessage] = useState('');
    const [formValue, setFormValue] = useState({
        name: { value: "", valid: false },
        email: { value: '', valid: false },
        phone: { value: '', valid: false },
        company: { value: "", valid: false },
    });

    function validateEntries() {
        //typescript não aceita fazer isso de forma iterativa
        return (
            formValue["name"].valid &&
            formValue['email'].valid &&
            formValue['phone'].valid &&
            formValue["company"].valid
        );
    }

    function setInputValue(inputName: string, value: string, valid: boolean) {
        setFormValue((state) => {
            if (inputName === 'phone') {
                value = phoneMask(value);
            }
            return {
                ...state,
                [inputName]: { value, valid },
            };
        });
    }

    useEffect(() => {
        const formLeadStr = localStorage.getItem("formLead");
        if (!formLeadStr) return;

        const formLead: {
            email: string;
            empresa: string;
            setor: string;
        } = JSON.parse(formLeadStr);
        setInputValue("email", formLead.email, true);
        setInputValue("company", formLead.empresa, true);
    }, []);

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const contactMutate = useContatoMutate();

    function handleSubmit(e: any) {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');
        setFormSubmited(true);
        if (!validateEntries()) {
            return;
        }

        const phone = formValue.phone.value.replace(/\D/g, '');

        const data = {
            nome: formValue.name.value,
            email: formValue.email.value,
            tefone: phone,
            empresa: formValue.company.value,
            mensagem: message,
        };
        contactMutate.mutate(data);
    }

    useEffect(() => {
        if (contactMutate.isSuccess) {
            setSuccessMessage('Mensagem enviada com sucesso! Entraremos em contato em breve.');
        }
        if (contactMutate.isError) {
            setErrorMessage('Erro ao enviar a mensagem!');
        }
    }, [contactMutate.isError, contactMutate.isSuccess]);

    return (
        <>
            <BasePage title="Contato">
                <>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <Input
                            inputName="name"
                            label="Nome"
                            placeholder="Seu nome"
                            type="text"
                            errorMessage="* insira um nome válido"
                            formSubmited={formSubmited}
                            setInputValue={setInputValue}
                            value={formValue['name'].value}
                        />
                        <Input
                            inputName="email"
                            label="E-mail"
                            placeholder="Seu melhor e-mail"
                            type="email"
                            errorMessage="* insira um e-mail válido"
                            formSubmited={formSubmited}
                            setInputValue={setInputValue}
                            value={formValue['email'].value}
                        />
                        <Input
                            inputName="phone"
                            label="Telefone"
                            placeholder="Seu número de telefone"
                            type="text"
                            errorMessage="* insira um telefone válido"
                            formSubmited={formSubmited}
                            setInputValue={setInputValue}
                            value={formValue['phone'].value}
                            maxLength={15}
                        />
                        <Input
                            inputName="company"
                            label="Empresa"
                            placeholder="Sua empresa"
                            type="text"
                            errorMessage="* insira uma empresa válida"
                            formSubmited={formSubmited}
                            setInputValue={setInputValue}
                            value={formValue["company"].value}
                        />
                        <p className="label-register">
                            Mensagem
                        </p>
                        <textarea
                            className="input-register"
                            placeholder="Como podemos lhe ajudar?"
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                        ></textarea>
                        {
                            errorMessage &&
                            <p className="input-error">
                                {errorMessage}
                            </p>
                        }
                        {
                            successMessage &&
                            <p className="input-success">
                                {successMessage}
                            </p>
                        }
                        <Button.Primary
                            btnType="btn-primary"
                            type="submit"
                            style={{ width: "100%", marginTop: "16px" }}
                        >
                            Enviar
                        </Button.Primary>
                    </form>
                    <div style={{
                        width: '100%',
                        height: '1px',
                        background: '#eee'
                    }}></div>
                    <div className="d-flex" style={{ flexDirection: 'column', gap: '8px', marginTop: '16px' }}>
                        {/* <div className="d-flex">
                            <BsTelephoneFill style={{ marginRight: '16px' }} />
                            <p className={'text-body text-large ' + styles['subtitle']}>
                                +55 (11) 3586-9466
                            </p>
                        </div> */}
                        <div className="d-flex">
                            <RiWhatsappFill style={{ marginRight: '16px' }} />
                            <p className={'text-body text-large ' + styles['subtitle']}>
                                +55 (11) 94584-1946
                            </p>
                        </div>
                        <div className="d-flex">
                            <MdMail style={{ marginRight: '16px' }} />
                            <p className={'text-body text-large ' + styles['subtitle']}>
                                relacionamento@greener.com.br
                            </p>
                        </div>
                    </div>
                </>
            </BasePage>
            {contactMutate.isLoading && <Spinner />}
        </>
    );
}
export default Contact;