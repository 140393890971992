import { RiCloseFill } from "react-icons/ri";

import styles from './styles.module.css';

interface TopBarProps {
    title: string,
    onClose: Function
}

const TopBar = ({ title, onClose }: TopBarProps) => {
    return (
        <div className={styles['title-wrapper']}>
            <h5 className="title-h5 color-greyscale-04">
                {title}
            </h5>
            <RiCloseFill
                className='color-greyscale-04'
                onClick={() => onClose()}
                style={{ fontSize: '24px', cursor: 'pointer' }}
            />
        </div>
    );
}

export default TopBar