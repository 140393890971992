import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import styles from '../../styles/profile.module.css';
import BasePage from '../../common/base-page';
import { useRelatoriosData } from '../../hooks/useRelatoriosData';
import Spinner from '../../common/spinner/spinner';
import { Button } from '../../common/button';
import Breadcrumb from '../../common/breadcrumb';
import AuthGuard from '../../guards/auth-guard';

function RelatorioList() {
    const { data, isLoading } = useRelatoriosData();

    return (
        <AuthGuard
            allow="greener"
            notAllowMessage={
                <div className="container" style={{ padding: '60px 0 180px 0' }}>
                    <h3 className={"title-h3 text-bold"}>
                        Página exclusiva para administradores
                    </h3>
                </div>
            }
        >
            <BasePage
                title='Relatório de Distribuidores'
                beadcrumb={
                    <Breadcrumb>
                        <Link to="/admin">Painel Administrativo</Link>
                        <span>Relatório de Distribuidores</span>
                    </Breadcrumb>
                }
                titleButton={
                    <Link to="new">
                        <Button.Primary
                            btnType='btn-primary'
                        >
                            + Gerar Relatório
                        </Button.Primary>
                    </Link>
                }
            >
                <nav>
                    {data && data.map((relatorio, index) => (
                        <>
                            <Link
                                key={relatorio.id}
                                to={`${relatorio.id}`}
                                className="d-flex justify-content-between align-items-center"
                            >
                                <div className="d-flex">
                                    <div className={styles['nav-text-wrapper']}>
                                        <p className="text-body text-xlarge color-greyscale-01">
                                            {relatorio.distributor_name}
                                        </p>
                                        <div style={{ display: 'flex', gap: '16px' }}>
                                            <p className="text-body color-greyscale-03">
                                                início: {relatorio.start_date.split('-')[2]}/{relatorio.start_date.split('-')[1]}/{relatorio.start_date.split('-')[0]}
                                            </p>
                                            <p className="text-body color-greyscale-03">
                                                fim: {relatorio.end_date.split('-')[2]}/{relatorio.end_date.split('-')[1]}/{relatorio.end_date.split('-')[0]}
                                            </p>
                                            <p className="text-body color-greyscale-03">
                                                data de geração: {relatorio.generation_date.split('-')[2]}/{relatorio.generation_date.split('-')[1]}/{relatorio.generation_date.split('-')[0]}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <FaChevronRight className='color-greyscale-03' />
                            </Link>
                            {index !== (data.length - 1) &&
                                <div className={styles['line']} style={{ margin: '24px 0' }}></div>
                            }
                        </>
                    ))}
                    {isLoading && <Spinner />}
                </nav>
            </BasePage>
        </AuthGuard>
    );
}
export default RelatorioList;