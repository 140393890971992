import { HiOutlineChevronDoubleLeft } from 'react-icons/hi';

import { useEffect, useState } from 'react';

import FilterItemContainer from './filter-item-container';
import FilterList from './filter-list';
import FilterItemName from './filter-item-name';
import FilterItemActions from './filter-item-actions';

import Spinner from '../../spinner/spinner';
import When from '../../when/when';

import { FilterData } from '../../../types/filter';
import { useFilterData } from '../../../hooks/useFilterData';
import { useFilterDelete } from '../../../hooks/useFilterDelete';
import ErrorMessage from '../../error-message';

interface SavedFiltersProps {
    id: string,
    setOpenFilters: Function,
    applyFilter: Function
}

const SavedFilters = ({ id, setOpenFilters, applyFilter }: SavedFiltersProps) => {
    const filterData = useFilterData();
    const [filters, setFilters] = useState<FilterData[]>([]);

    useEffect(() => {
        if(filterData.data && Array.isArray(filterData.data))
            setFilters(
                filterData.data.filter((dataItem: FilterData) => dataItem.id_produto === id)
            );
    }, [filterData.data]);

    const filterDelete = useFilterDelete();
    function deleteFilter(filter: FilterData) {
        const data = {
            id_produto: filter.id_produto,
            nome: filter.nome,
        };
        filterDelete.mutate(data);
    }

    return (
        <>
            <FilterItemContainer>
                <h5
                    className="title-h5 color-greyscale-04"
                    style={{ width: 'max-content', marginRight: '24px', fontSize: '18px' }}
                >
                    Filtros Salvos
                </h5>
                <HiOutlineChevronDoubleLeft
                    className='color-greyscale-04'
                    style={{ fontSize: '24px', cursor: 'pointer' }}
                    onClick={() => setOpenFilters(false)}
                />
            </FilterItemContainer>
            <FilterList>
                {filters && filters.map((filter: FilterData) => (
                    <FilterItemContainer style={{marginBottom: '8px'}}>
                        <FilterItemName name={filter.nome} />
                        <FilterItemActions
                            filter={filter}
                            applyFilter={applyFilter}
                            deleteFilter={deleteFilter}
                        />
                    </FilterItemContainer>
                ))}
            </FilterList>
            <ErrorMessage
                expr={filterDelete.isError}
                message='Erro ao deletar filtro.'
            />
            <When expr={filterData.isLoading || filterDelete.isLoading}>
                <Spinner />
            </When>
        </>
    );
}

export default SavedFilters