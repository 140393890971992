import { Link } from 'react-router-dom';
import PrimaryButton from './primary-button';

interface LinkButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    btnType: 
        | 'btn-primary'
        | 'btn-secondary' 
        | 'btn-social'
        | 'btn-filter'
        | 'btn-white'
        | 'btn-primary-outline'
        | 'btn-secondary-outline'
        | 'btn-red-outline',
    to: string
}

const LinkButton = ({ to, ...props }: LinkButtonProps) => {
    return (
        <Link to={to}>
            <PrimaryButton {...props} />
        </Link>
    );
}

export default LinkButton