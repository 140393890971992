import { useMutation, useQueryClient } from "@tanstack/react-query";
import { RdRegisterMutate } from "../types/register";
import axios from "axios";

const PostData = async (data: RdRegisterMutate): Promise<any> => {
    return await axios.post('https://api.rd.services/platform/conversions?api_key=nABhGLXLxorXldxoZUTPVCjZatAgXgfHntCt', data);
}

export function useRdRegisterMutate() {
    
    const mutate = useMutation({
        mutationFn: PostData,
    });

    return mutate;
}