import { Dispatch, SetStateAction } from 'react';

import { Button } from '../../common/button';

interface ButtonEditImageProps {
    enteredImageUrl: string,
    setEnteredImageUrl: Dispatch<SetStateAction<string>>
}

const ButtonEditImage = ({ enteredImageUrl, setEnteredImageUrl }: ButtonEditImageProps) => {
    return (
        <div className='d-flex flex-column'>
            <img src={enteredImageUrl} style={{ maxWidth: '100%', marginBottom: '16px' }} />
            <Button.Primary
                btnType='btn-primary'
                onClick={() => setEnteredImageUrl('')}
            >
                Editar imagem
            </Button.Primary>
        </div>
    );
}

export default ButtonEditImage