import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { ExportBiMutate } from "../types/export-bi";
import { useHeaderConfig } from "./useHeaderConfig";

const PostData = async (data: ExportBiMutate): Promise<any> => {
    const config = useHeaderConfig();
    return await axios.post('api/export_bi', data, config);
}

export function useExportBiMutate() {
    const mutate = useMutation({
        mutationFn: PostData,
        // onSuccess: (data) => {
        //     window.open(data.data,'_blank');
        // }
    });

    return mutate;
}