import { BsGear } from 'react-icons/bs';

import styles from '../../styles/admin-user.module.css';
import { IAcessos } from '../../types/user';

interface SubUserItemActionsProps {
    subscription: IAcessos,
    handleOpenModalManage: Function
}

const SubUserItemActions = ({ subscription, handleOpenModalManage }: SubUserItemActionsProps) => {
    return (
        <div
            className={styles['subscription-gear']}
            onClick={() => handleOpenModalManage(subscription)}
        >
            <BsGear className={styles['gear']}/>
            <p className={"text-body text-small color-greyscale-03 " + styles['show-sm']}>
                Gerenciar assinatura
            </p>
        </div>
    );
}

export default SubUserItemActions