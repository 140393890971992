import styles from '../../styles/purchases-historic.module.css';

interface PostContentProps {
    title: string,
    description: string,
    image_url: string
}

const PostContent = ({ title, description, image_url }: PostContentProps) => {
    return (
        <div className="d-flex">
            <img
                src={image_url}
                alt={title}
                className={styles['order-image']}
            />
            <div className={styles['order-purchase-data']}>
                <p className="text-body text-large text-w-medium color-greyscale-01">
                    {title}
                </p>
                <p className="text-body color-greyscale-03">
                    {description}
                </p>
            </div>
        </div>
    );
}

export default PostContent