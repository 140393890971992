import styles from '../../styles/purchases-historic.module.css';
import { ProductData } from '../../types/home';

interface ProductDataSectionProps {
    product: ProductData
}

const ProductDataSection = ({ product }: ProductDataSectionProps) => {
    return (
        <div className={styles["order-purchase-data"]}>
            <p className="text-body text-large text-w-medium color-greyscale-01">
                {product.title}
            </p>
            <p className="text-body text-small">
                {product.description}
            </p>
        </div>
    );
}

export default ProductDataSection