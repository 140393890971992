import styles from './carousel-header.module.css';

import { Button } from "../../../common/button";
import { ICarsouselHeaderItem } from "../../../types/carousel-header";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";


const CarouselItem = ({ item }: { item: ICarsouselHeaderItem }) => {
    const gaEventTracker = useAnalyticsEventTracker('Home - Carousel');

    return (
        <div className={styles['item']} key={item.title}>
            <h3 className='title-h3 text-bold'>
                {item.title}
            </h3>
            <h6 className='title-h6'>
                {item.description}
            </h6>
            {/* <Button.Link
                btnType='btn-primary'
                to={item.button_URL}
                onClick={() => gaEventTracker(
                    `Clique no botão ${item.button_title} do Header`,
                    `Clique no botão ${item.button_title}`
                )}
            >
                {item.button_title}
            </Button.Link> */}
        </div>
    );
}

export default CarouselItem