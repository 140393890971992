import EditableFieldInput from './editable-field-input';
import EditableFieldInputData from './editable-field-input-data';
import styles from '../../styles/my-data.module.css';

interface EditableFieldProps {
    title: string;
    value: string | undefined;
    showInput: boolean;
    onShowInput: () => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onEdit: () => void;
    onCancel: () => void;
}

const EditableField = ({ title, value, showInput, onShowInput, onChange, onEdit, onCancel }: EditableFieldProps) => {
    return (
        <div className={styles['data-text-wrapper']}>
            <p className="text-body text-xlarge text-semibold color-greyscale-01">
                {title}
            </p>
            {showInput ? (
                <EditableFieldInput
                    value={value}
                    onChange={onChange}
                    onEdit={onEdit}
                    onCancel={onCancel}
                />
            ) : (
                <EditableFieldInputData
                    value={value}
                    onShowInput={onShowInput}
                />
            )}
        </div>
    );
}

export default EditableField