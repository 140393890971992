import CircleAvatar from '../../common/circle-avatar';
import styles from '../../styles/purchases-historic.module.css';

interface UserImageProps {
    initialLetter: string;
}

const UserImage = ({ initialLetter }: UserImageProps) => {
    return (
        <div className={styles["user-image"]}>
            <CircleAvatar
                nameInitial={initialLetter}
                style={{ width: "100%", height: "100%" }}
            />
        </div>
    );
}

export default UserImage