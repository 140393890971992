import { HTMLAttributes } from "react"

interface FilterItemContainerProps extends HTMLAttributes<HTMLDivElement> {}

const FilterItemContainer = (props: FilterItemContainerProps) => {
    const defaultStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '24px'
    }
    return (
        <div
            {...props}
            style={{...defaultStyle, ...props.style}}
        />
    );
}

export default FilterItemContainer