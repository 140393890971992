import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import UserData from "../types/user";
import { useHeaderConfig } from "./useHeaderConfig";

const FetchData = async (id: string): Promise<AxiosResponse<UserData>> => {
    const config = useHeaderConfig();
    const response = await axios.get<UserData>(`/api/adm-usuarios?id=${id}`, config);
    return response;
};

export function useAdminUserData(id: string) {
    const query = useQuery({
        queryFn: () => FetchData(id),
        queryKey: ['admin-user-data', id],
        enabled: !!id,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        ...query,
        user: query.data?.data
    };
}