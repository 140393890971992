import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { LoginMutate } from "../types/login";

const postData = async (data: LoginMutate): Promise<any> => {
    return await axios.post('/api/login', data);
}

export function useLoginMutate(path?: string) {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    
    const mutate = useMutation({
        mutationFn: postData,
        onSuccess: (data) => {
            console.log(data)
            localStorage.setItem('token', data.data.token)
            queryClient.invalidateQueries(['profile-data', 'auth-me-data']);
            if(path)
                navigate(path);
        }
    });

    return mutate;
}