import styles from '../../styles/admin-user.module.css';

interface UserDataContentProps {
    title: string,
    value: string
}

const UserDataContent = ({ title, value }: UserDataContentProps) => {
    return (
        <div className={styles['data-text-wrapper']}>
            <p className="text-body text-xlarge text-semibold color-greyscale-01">
                {title}
            </p>
            <p className="text-body text-large color-greyscale-03">
                {value}
            </p>
        </div>
    );
}

export default UserDataContent