import { Dispatch, HTMLAttributes, MouseEvent, SetStateAction } from "react";

interface CardContainerProps extends HTMLAttributes<HTMLDivElement> {
    setTooltipPosition: Dispatch<SetStateAction<"left" | "right">>,
    setIsHovering: Dispatch<SetStateAction<boolean>>
}

const CardContainer = ({ setTooltipPosition, setIsHovering, children, ...props }: CardContainerProps) => {
    function handleMouseEnter(e: MouseEvent) {
        const screenWidth = window.innerWidth;
        if(e.clientX < screenWidth/2) {
            setTooltipPosition(_ => 'right');
        } else {
            setTooltipPosition(_ => 'left');
        }
        setIsHovering(true);
    }

    function handleMouseLeave(e: MouseEvent) {
        setIsHovering(false);
    }

    return (
        <div
            style={{ position: 'relative' }}
            onMouseEnter={(e: MouseEvent) => handleMouseEnter(e)}
            onMouseLeave={(e: MouseEvent) => handleMouseLeave(e)}
            {...props}
        >
            {children}
        </div>
    );
}

export default CardContainer