import CardBI from '../../components/my-products/card-BI/card-BI';
import styles from '../../styles/my-products.module.css';
import { MyProductData } from '../../types/my-product';

interface MyProductsSectionProps {
    title: string,
    products: MyProductData[],
    handleSelectBI: Function
}

const MyProductsSection = ({ title, products, handleSelectBI }: MyProductsSectionProps) => {
    return (
        <>
            <div className={'row ' + styles['row-text']}>
                <h3 className="title-h3 text-bold" style={{ marginBottom: '24px' }}>
                    {title}
                </h3>
            </div>
            <div className="row">
                {products.map(product => {
                    return (
                        <div
                            key={product.produto.id}
                            style={{ marginTop: '40px' }}
                            className={'col-md-4'}
                        >
                            <CardBI
                                id={product.detalhes_cartao.id}
                                thumbnail_link={product.detalhes_cartao.thumbnail_link}
                                title={product.detalhes_cartao.title}
                                description={product.detalhes_cartao.description}
                                badge={product.detalhes_cartao?.badge}
                                paragraph_1={product.detalhes_cartao.paragraphs ? product.detalhes_cartao.paragraphs[0] : ''}
                                paragraph_2={product.detalhes_cartao.paragraphs ? product.detalhes_cartao.paragraphs[1] : ''}
                                link_bi={product.produto.link}
                                product={product}
                                onSelectBI={handleSelectBI}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default MyProductsSection