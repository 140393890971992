import styles from './spinner.module.css';

function Spinner(props: { className?: string }) {
    return (
        <div className={
            styles['loader-container'] 
            + ' '
            + styles[props.className ? props.className : '']
        }>
            <div className={styles['loader']}></div>
        </div>
    );
}
export default Spinner;