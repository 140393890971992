import { BsPencilSquare } from 'react-icons/bs';
import { RiDeleteBin2Line } from 'react-icons/ri';

import styles from '../../styles/purchases-historic.module.css';
import IUser from '../../types/user';

interface UserItemActionsProps {
    user: IUser,
    selectUser: Function,
    openModalDelete: Function
}

const UserItemActions = ({ user, selectUser, openModalDelete }: UserItemActionsProps) => {
    return (
        <div className={"d-flex " + styles["actions"]}>
            <BsPencilSquare
                className="text-xlarge color-greyscale-03"
                style={{ cursor: "pointer", marginRight: "26px" }}
                onClick={() => selectUser(user)}
            />
            <RiDeleteBin2Line
                className="text-xlarge color-red"
                style={{ cursor: "pointer" }}
                onClick={() => openModalDelete(user)}
            />
        </div>
    );
}

export default UserItemActions