import { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

// import iconFacebook from '../assets/images/icon-facebook.svg';
import iconGoogle from '../assets/images/icon-google.svg';
import iconMicrosoft from '../assets/images/icon-microsoft.svg';
import logoDark from '../assets/images/logo-dark.svg';
import logoLight from '../assets/images/logo-light.svg';
import { Button } from '../common/button';
import useAnalyticsEventTracker from '../hooks/useAnalyticsEventTracker';
import Input from '../common/input/input';
import Spinner from '../common/spinner/spinner';
import styles from '../styles/login.module.css';
import { useAuthMeData } from '../hooks/useAuthMeData';
import { useLoginMutate } from '../hooks/useLoginMutate';

function LoginSocial() {
    const gaEventTracker = useAnalyticsEventTracker('Login');
    const navigate = useNavigate();
    const authMeData = useAuthMeData();
    
    useEffect(() => {
        if(authMeData.data && authMeData.data.clientPrincipal) {
            navigate('/cadastro-social');
        }
    }, [authMeData.data]);

    const [formSubmited, setFormSubmited] = useState(false);
    const [formValue, setFormValue] = useState({
        email: { value: '', valid: false },
        password: { value: '', valid: false },
    });

    function validateEntries() {
        //typescript não aceita fazer isso de forma iterativa
        return formValue['email'].valid;
    }

    function setInputValue(inputName: string, value: string, valid: boolean) {
        setFormValue(state => {
            return {
                ...state,
                [inputName]: { value, valid }
            };
        })
    }

    const loginMutate = useLoginMutate('/');

    function handleSubmit(e: any) {
        e.preventDefault();
        setFormSubmited(true);

        if(!validateEntries()) {
            return;
        }

        const data = {
            email: formValue.email.value,
            senha: formValue.password.value
        };
        loginMutate.mutate(data);
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className={"col-md-8 " + styles['col-img']}>
                        <Link to="/">
                            <img src={logoLight} alt="logo Greendex" />
                        </Link>
                        <Link
                            to="/"
                            className={styles['back-button']}
                            
                        >
                            <FaChevronLeft className={styles['icon']} />
                            <span className={'text-body t-medium ' + styles['back-button-text']}>
                                Voltar
                            </span>
                        </Link>
                    </div>
                    <div className="col-md-4 col-xl-3 p-4">
                        <Link to='/'>
                            <img
                                src={logoDark} alt="logo Greendex"
                                className={styles['logo-dark']}
                            />
                        </Link>
                        <h5
                            className="title-h5 text-semibold"
                            style={{ marginBottom: '24px' }}
                        >
                            Bem vindo
                        </h5>
                        <form onSubmit={handleSubmit}>
                            <Input
                                inputName="email"
                                label="Login"
                                placeholder="Seu e-mail"
                                type="email"
                                errorMessage="* insira um e-mail válido"
                                formSubmited={formSubmited}
                                setInputValue={setInputValue}
                            />
                            <div className={styles['password-field']}>
                                <Input
                                    inputName="password"
                                    label="Senha"
                                    placeholder="Sua senha"
                                    type="password"
                                    errorMessage="* insira uma senha válida"
                                    formSubmited={formSubmited}
                                    setInputValue={setInputValue}
                                />
                            </div>
                            {loginMutate.isError && 
                                <p className="input-error">
                                    * Erro ao fazer login
                                </p>
                            }
                            <Button.Primary
                                btnType='btn-primary'
                                type='submit'
                                style={{ width: '100%', marginTop: '16px' }}
                            >
                                Entrar
                            </Button.Primary>
                        </form>
                        <div className={"d-flex " + styles['row-continue']}>
                            <div className={styles['line']}></div>
                            <span className='text-body text-small color-greyscale-06'>
                                Ou continue com
                            </span>
                            <div className={styles['line']}></div>
                        </div>
                        <Button.a
                            btnType='btn-social'
                            href="/.auth/login/google?post_login_redirect_uri=/cadastro-social"
                            onClick={() => gaEventTracker(
                                'Clique no botão Login',
                                `Clique no botão Login Google`
                            )}
                        >
                            <img src={iconGoogle} alt="ícone Google" />
                            <span>Google</span>
                            <div></div>
                        </Button.a>
                        {/* <a href="/.auth/login/facebook">
                            <Button
                                className='btn-social'
                                text='Facebook'
                                onClick={() => {}}
                            >
                                <img src={iconFacebook} alt="ícone Facebook" />
                            </Button>
                        </a> */}
                        <Button.a
                            btnType='btn-social'
                            href="/.auth/login/aad?post_login_redirect_uri=/cadastro-social"
                            onClick={() => gaEventTracker(
                                'Clique no botão Login',
                                `Clique no botão Login Microsoft`
                            )}
                        >
                            <img src={iconMicrosoft} alt="ícone Microsoft" />
                            <span>Microsoft 365</span>
                            <div></div>
                        </Button.a>
                        <div className='text-body text-small' style={{ textAlign: 'center' }}>
                            <span style={{ marginRight: '8px' }}>
                                Não possui uma conta?
                            </span>
                            <Link to='/cadastro'>Inscrever-se agora</Link>
                        </div>
                        <div className='text-body text-small' style={{ textAlign: 'center' }}>
                            <span style={{ marginRight: '8px' }}>
                                Esqueceu sua senha?
                            </span>
                            <Link to='/esqueci-minha-senha'>Esqueci minha senha</Link>
                        </div>
                    </div>
                </div>
            </div>
            {(loginMutate.isLoading && authMeData.isLoading) && <Spinner />}
        </>
    );
}
export default LoginSocial;