import { HTMLAttributes } from "react";

import styles from '../../styles/purchases-historic.module.css';

interface PostItemContainer extends HTMLAttributes<HTMLLIElement> {}

const PostItemContainer = (props: PostItemContainer) => {
    return (
        <li
            className={styles['d-flex-blog'] + ' ' + styles['order-data']}
            {...props}
        />
    );
}

export default PostItemContainer