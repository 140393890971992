import { useEffect, useState } from 'react';

import Spinner from '../../common/spinner/spinner';
import NavbarDesktop from './navbar-desktop/navbar-desktop';
import NavbarMobile from './navbar-mobile/navbar-mobile';
import styles from './navbar.module.css';
import { useHomeData } from '../../hooks/useHomeData';
import { useAuthMeData } from '../../hooks/useAuthMeData';
import { useProfileData } from '../../hooks/useProfileData';

function Navbar() {
    const homeData = useHomeData();
    const authMeData = useAuthMeData();

    const { profile } = useProfileData();

    return (
        <div>
            <div className={styles['show-desktop']}>
                <NavbarDesktop
                    products={homeData.data.products}
                    profile={profile}
                />
            </div>
            <div className={styles['show-mobile']}>
                <NavbarMobile
                    products={homeData.data.products}
                    profile={profile}
                />
            </div>
            {(homeData.isLoading || authMeData.isLoading) && <Spinner />}
        </div>
    );
}
export default Navbar;