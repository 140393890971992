import { BsPencilSquare } from "react-icons/bs";
import { RiDeleteBin2Line } from "react-icons/ri";
import { Link } from "react-router-dom";

interface PostActionsProps {
    id: number,
    page_path: string,
    handleDeletePost: Function
}

const PostActions = ({ id, page_path, handleDeletePost }: PostActionsProps) => {
    return (
        <div>
            <Link to={String(page_path)}>
                <BsPencilSquare
                    className="text-xlarge color-greyscale-03"
                    style={{ cursor: "pointer", marginRight: "26px" }}
                />
            </Link>
            <RiDeleteBin2Line
                className="text-xlarge color-red"
                style={{ cursor: "pointer" }}
                onClick={() => handleDeletePost(id)}
            />
        </div>
    );
}

export default PostActions