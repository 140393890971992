import { useEffect, useState } from "react";
import styles from './pagination-buttons.module.css';

function PaginationButtons(props: { currentPage: number, lastPage: number, onChangePagination: Function }) {
    const [pageNumbers, setPageNumbers] = useState<number[]>([1, 2, 3]);

    useEffect(() => {
        if(props.currentPage === 1) {
            if(props.lastPage >= 3) {
                setPageNumbers(_ => [1, 2, 3]);
            } else if (props.lastPage === 2) {
                setPageNumbers(_ => [1, 2]);
            } else {
                setPageNumbers(_ => [1]);
            }
        } else if (props.currentPage === props.lastPage) {
            if(props.currentPage === 2) {
                setPageNumbers(_ => [(props.lastPage - 1), props.lastPage]);
            } else {
                setPageNumbers(_ => [(props.lastPage - 2), (props.lastPage - 1), props.lastPage]);
            }
        } else {
            setPageNumbers(_ => [(props.currentPage - 1), props.currentPage, (props.currentPage + 1)])
        }

    }, [props.currentPage, props.lastPage]);

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination">
                <li className="page-item">
                    <span
                        className={
                            'page-link ' + 
                            styles['page-link'] +
                            (props.currentPage === 1 ? ' color-greyscale-04' : ' color-greyscale-01')
                        }
                        aria-label="Previous"
                        onClick={() => props.onChangePagination('previus')}
                    >
                        <span aria-hidden="true">&lt;</span>
                        <span className="sr-only">Previous</span>
                    </span>
                </li>
                {pageNumbers.map(pageNumber => 
                    <li
                        className="page-item"
                        key={pageNumber}
                        onClick={() => props.onChangePagination(pageNumber)}
                    >
                        <span className={
                            'text-body text-large page-link ' + 
                            styles['page-link'] +
                            (props.currentPage === pageNumber ? ' ' + styles.active : ' color-greyscale-04')
                        }>
                            {pageNumber}
                        </span>
                    </li>
                )}
                <li className="page-item">
                    <span
                        className={
                            'page-link ' + 
                            styles['page-link'] +
                            (props.currentPage === props.lastPage ? ' color-greyscale-04' : ' color-greyscale-01')
                        }
                        aria-label="Next"
                        onClick={() => props.onChangePagination('next')}
                    >
                        <span aria-hidden="true">&gt;</span>
                        <span className="sr-only">Next</span>
                    </span>
                </li>
            </ul>
        </nav>
    );
}
export default PaginationButtons;