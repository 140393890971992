import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import { FilterData } from "../types/filter";
import { useHeaderConfig } from "./useHeaderConfig";


const FetchData = async (): Promise<AxiosResponse<FilterData[]>> => {
    const config = useHeaderConfig();
    const response = await axios.get<FilterData[]>('api/filtro', config);
    return response;
};

export function useFilterData() {
    const query = useQuery({
        queryFn: FetchData,
        queryKey: ['filter-data'],
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        ...query,
        data: query.data?.data
    };
}