import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { useHeaderConfig } from "./useHeaderConfig";
import { RelatorioData } from "../types/relatorio";

const mountQueryUrlAndKey = (id: string | undefined) => {
    if(id)
        return {
            url: `/api/relatorio?id=${id}`,
            key: ['relatorio-distribuidores', id]
        };
    return {
        url: `/api/relatorio`,
        key: ['relatorio-distribuidores']
    }
}

const FetchData = async (url: string): Promise<AxiosResponse<RelatorioData>> => {
    const config = useHeaderConfig();
    const response = await axios.get<RelatorioData>(url, config);
    return response;
};

export function useRelatorioData(id?: string) {
    const { url, key } = mountQueryUrlAndKey(id);
    const query = useQuery({
        queryFn: () => FetchData(url),
        queryKey: key,
        staleTime: 2 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        ...query,
        data: query.data?.data
    };
}