import { FaChevronRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import styles from '../styles/profile.module.css';
import iconMeusDados from '../assets/images/icon-meus-dados.svg';
import iconGerenciarAssinaturas from '../assets/images/icon-gerenciar-assinaturas.svg';
import iconMeusProdutos from '../assets/images/icon-meus-produtos.svg';
import BasePage from '../common/base-page';
import AuthGuard from '../guards/auth-guard';
import { AcessUsers } from '../types/user';

function Admin() {
    const navItems: { link: string; icon: string; title: string; description: string; allow: AcessUsers; }[] = [
        {
            link: 'usuario',
            icon: iconMeusDados,
            title: 'Usuários',
            description: 'Gerencie os usuários.',
            allow: 'administrador'
        },
        {
            link: 'blog',
            icon: iconGerenciarAssinaturas,
            title: 'Blog',
            description: 'Gerencie os posts do blog.',
            allow: 'administrador'
        },
        {
            link: 'produtos-internos',
            icon: iconMeusProdutos,
            title: 'Produtos',
            description: 'Adicione produtos internos.',
            allow: 'administrador'
        },
        {
            link: '/dash-prices-stage-producao',
            icon: iconMeusProdutos,
            title: 'Dash Prices Produção x Stage',
            description: 'Visualize o dashboard.',
            allow: 'greener'
        },
        {
            link: '/relatorio-distribuidores',
            icon: iconGerenciarAssinaturas,
            title: 'Relatório de Distribuidores',
            description: 'Gere relatórios.',
            allow: 'greener'
        },
    ];

    return (
        <BasePage title='Painel Administrativo'>
            <nav>
                {navItems.map((item, index) => (
                    <AuthGuard allow={item.allow} key={item.title}>
                        <>
                            <Link
                                to={item.link}
                                className="d-flex justify-content-between align-items-center"
                            >
                                <div className="d-flex">
                                    <img src={item.icon} alt={item.title} />
                                    <div className={styles['nav-text-wrapper']}>
                                        <p className="text-body text-xlarge color-greyscale-01">
                                            {item.title}
                                        </p>
                                        <p className="text-body color-greyscale-03">
                                            {item.description}
                                        </p>
                                    </div>
                                </div>
                                <FaChevronRight className='color-greyscale-03' />
                            </Link>
                            {index !== (navItems.length - 1) &&
                                <div className={styles['line']} style={{ margin: '24px 0' }}></div>
                            }
                        </>
                    </AuthGuard>
                ))}
            </nav>
        </BasePage>
    );
}
export default Admin;