import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { PostDataMutate } from "../types/blog";
import { useHeaderConfig } from "./useHeaderConfig";

const AddPost = async (data: PostDataMutate) => {
    const config = useHeaderConfig();
    return await axios.post("/api/blog", data, config);
}

export function useBlogMutate() {
    const queryClient = useQueryClient();
    const mutate = useMutation({
        mutationFn: AddPost,
        onSuccess: () => {
            queryClient.invalidateQueries(['blog-data'])
        }
    });

    return mutate;
}