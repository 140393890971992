import { RiCloseFill } from "react-icons/ri";

import { Button } from "../../../common/button";
import styles from './modal-pos-save-report.module.css';

interface ModalPosSaveReportProps {
    title: string;
    description: string;
    btn_text: string;
    onConfirm: Function;
    onClose: Function;
    children?: JSX.Element;
}
function ModalPosSaveReport(props: ModalPosSaveReportProps) {
    return (
        <div className={styles["modal-wrapper"]}>
            <div className={styles['modal-bg']} onClick={() => props.onClose()}></div>
            <div className={styles['modal']}>
                <header
                    className="d-flex justify-content-between"
                    style={{ marginBottom: '24px' }}
                >
                    <h5 className={"text-body text-semibold color-dark-01 text-align-center " + styles['title-config']}>
                        {props.title}
                    </h5>
                    <RiCloseFill
                        onClick={() => props.onClose()}
                        style={{ fontSize: '26px' }}
                    />
                </header>
                <section className={"d-flex justify-between " + styles['body']}>
                    {props.description && (
                        <p className="text-body text-medium color-greyscale-02">
                            {props.description}
                        </p>
                    )}
                    {props?.children}
                    <div className={styles["col-buttons"]}>
                        <Button.Primary
                            btnType="btn-primary-outline"
                            onClick={() => props.onConfirm()}
                            style={{
                                fontSize: '12px',
                                padding: '8px 12px',
                                marginBottom: '16px'
                            }}
                        >
                            {props.btn_text}
                        </Button.Primary>
                    </div>
                </section>
            </div>
        </div>
    );
}
export default ModalPosSaveReport;