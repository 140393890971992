import React, { useEffect, useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import { emailValidation, passwordValidation } from "../../app/validators";

interface IInput {
    inputName: string,
    label: string,
    placeholder: string,
    type: string,
    errorMessage: string,
    formSubmited: boolean,
    setInputValue: Function,
    value?: string,
    password?: string
    maxLength?: number
}

function Input(props: IInput) {
    const [enteredValue, setEnteredValue] = useState<string | number>('');
    const [validValue, setValidValue] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>(props.errorMessage);
    const [typePassword, setTypePassword] = useState<'text' | 'password'>('password');

    useEffect(() => {
        validateInput(String(enteredValue));
        props.setInputValue(props.inputName, enteredValue, validValue);
    }, [props.formSubmited, props.password]);

    useEffect(() => {
        if(!props.value) return;
        setEnteredValue(props.value);
        props.setInputValue(props.inputName, props.value, true);
    }, [props.value])

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEnteredValue(e.target.value);
        const valid = validateInput(e.target.value);
        props.setInputValue(props.inputName, e.target.value, valid);
    }

    function validateInput(newValue: string) {
        if(props.type === 'text' || props.type === 'number') {
            if(!newValue || newValue === 'undefined') {
                setValidValue(false);
                return false;
            }
            setValidValue(true);
            return true;
        } else if(props.type === 'email') {
            setValidValue(emailValidation(String(newValue)));
            return emailValidation(String(newValue));
        } else if(props.type === 'password' && props.inputName === 'password') {
            if(newValue && passwordValidation(String(newValue)) === 'valid') {
                setValidValue(true);
                return true;
            } else {
                setValidValue(false);
                setErrorMessage(passwordValidation(String(newValue)));
                return false;
            }
        } else if(props.type === 'password' && props.inputName === 'confirmPassword') {
            if(!newValue) {
                setValidValue(false);
                return false;
            }
            setValidValue(newValue === props.password);
            return (newValue === props.password)
        }
    }

    return (
        <>
            <p className="label-register">
                {props.label}
            </p>
            {props.type !== 'password' ? (
                <input
                    className="input-register"
                    type={props.type}
                    placeholder={props.placeholder}
                    value={enteredValue}
                    onChange={handleChange}
                    maxLength={props.maxLength}
                />
            ) : (
                <div style={{ position: 'relative' }}>
                    <input
                        className="input-register"
                        type={typePassword}
                        placeholder={props.placeholder}
                        value={enteredValue}
                        onChange={handleChange}
                    />
                    {typePassword === 'text' ? (
                        <AiFillEye
                            style={{
                                position: 'absolute',
                                top: '18px',
                                right: '18px',
                                cursor: 'pointer',
                                color: '#4D4D4D',
                            }}
                            onClick={() =>setTypePassword('password')}
                        />
                    ) : (
                        <AiFillEyeInvisible
                            style={{
                                position: 'absolute',
                                top: '18px',
                                right: '18px',
                                cursor: 'pointer',
                                color: '#4D4D4D',
                            }}
                            onClick={() =>setTypePassword('text')}
                        />
                    )}
                </div>
            )}
            {
                props.formSubmited && !validValue &&
                <p className="input-error">
                    {errorMessage}
                </p>
            }
        </>
    );
}
export default Input;