import { HTMLAttributes } from "react";

import styles from '../../styles/my-data.module.css';

interface BasePageProps extends HTMLAttributes<HTMLDivElement> {}

const BasePage = (props: BasePageProps) => {
    return (
        <div className="bg-grey">
            <div className="container">
                <div className="row">
                    <div className="col-md-1"></div>
                    <div
                        className={'col-md-10 ' + styles['col-content']}
                        {...props}
                    />
                </div>
            </div>
        </div>
    );
}

export default BasePage