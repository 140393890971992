import styles from './styles.module.css';

interface TitleProps {
    title: string,
    description?: string | JSX.Element
}

const Title = ({ title, description }: TitleProps) => {
    return (
        <div className={'row ' + styles['row-text']}>
            <h3
                className={'title-h3 text-bold ' + styles['title']}
                style={{ marginBottom: '24px' }}
            >
                {title}
            </h3>
            {description && typeof description === 'string' ? (
                <p className={'text-body text-large ' + styles['subtitle']}>
                    {description}
                </p>
            ) : (
                <>{description}</>
            )}
        </div>      
    );
}

export default Title