import { useParams } from "react-router-dom";

import Post from "./post";
import PostNotFound from "./post-not-found";
import Spinner from "../../common/spinner/spinner";
import { useBlogPostData } from "../../hooks/useBlogPostData";
import When from "../../common/when/when";

function BlogPost() {
    const { id } = useParams();
    const { data, isLoading } = useBlogPostData(id || '');
    
    return (
        <>
            <When expr={data}>
                <Post data={data!} />
            </When>
            <When expr={!data}>
                <PostNotFound />
            </When>
            <When expr={isLoading}>
                <Spinner />
            </When>
        </>
    );
}
export default BlogPost;
