import styles from './styles.module.css';

interface Badgeprops {
    text: string
}

const Badge = ({ text }: Badgeprops) => {
    return (
        <span className={'bg-tertiary-02 color-white text-body text-medium ' + styles.badge}>
            {text}
        </span>
    );
}

export default Badge