import { dateFormatter } from '../../app/formatter';
import styles from '../../styles/admin-user.module.css';

interface SubUserItemRenovationContentProps {
    type: string,
    renova_ou_expira_em: string
}

const SubUserItemRenovationContent = ({ type, renova_ou_expira_em }: SubUserItemRenovationContentProps) => {
    return (
        <p className={"text-body color-greyscale-03 " + styles['status']}>
            {type === 'Assinatura'
                ? `Renova em ${dateFormatter(renova_ou_expira_em)}`
                : `Expira em ${dateFormatter(renova_ou_expira_em)}`
            }
            {/* {subscription.tipo === 'Assinatura' && 
                `renova em ${subscription.renova_ou_expira_em}`}
            {subscription.tipo === ' Compra única' && 
                `expira em ${subscription.renova_ou_expira_em}`}
            {subscription.tipo === ' produto gratuito' && 
                `expira em ${subscription.renova_ou_expira_em}`} */}
        </p>
    );
}

export default SubUserItemRenovationContent