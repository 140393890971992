import Badge from './badge';
import CardContent from './card-content';
import CardWrapper from './card-wrapper';
import styles from './styles.module.css';
import When from '../when/when';

interface CardPlanValuesProps {
    id: string;
    thumbnail_link: string;
    title: string;
    description: string;
    payment_link: string;
    periodo_faturamento: string;
    badge?: string;
    price?: number;
    preco_anual?: number; //atualmente usado para o valor semestral
    preco_semestral?: number; //atualmente usado para o valor trimestral
}

function CardPlanValues(props: CardPlanValuesProps) {

    return (
        <CardWrapper>
            <img
                className={'card-img-top ' + styles['card-img-top']}
                src={props.thumbnail_link}
                alt={props.title}
            />
            <When expr={props.badge && props.badge !== "None"}>
                <Badge text={props.badge!} />
            </When>
            <CardContent {...props} />
        </CardWrapper>
    );
}
export default CardPlanValues;