import SubUserItemContent from './sub-user-item-content';
import SubUserItemActions from './sub-user-item-actions';
import SubUserItemRenovationContent from './sub-user-item-renovation-content';

import { IAcessos } from "../../types/user";
import styles from '../../styles/admin-user.module.css';
import When from '../../common/when/when';

interface SubscriptionsUserListProps {
    subscriptionsUser: IAcessos[],
    handleOpenModalManage: Function
}

const SubscriptionsUserList = ({ subscriptionsUser, handleOpenModalManage }: SubscriptionsUserListProps) => {
    return (
        <ul className={styles['list']}>
            {subscriptionsUser.map((subscription: IAcessos, index) => (
                <li className={styles['subscription-data']} key={index}>
                    <SubUserItemContent
                        title={subscription.produto.title}
                        description={subscription.produto.description}
                    />
                    <div className={"d-flex align-items-center " + styles['subscription-col-2']}>
                        <SubUserItemRenovationContent
                            type={subscription.tipo}
                            renova_ou_expira_em={subscription.renova_ou_expira_em}
                        />
                        <SubUserItemActions
                            subscription={subscription}
                            handleOpenModalManage={handleOpenModalManage}
                        />
                    </div>
                </li>
            ))}
            <When expr={subscriptionsUser.length === 0}>
                <p className="text-body text-large text-w-medium color-greyscale-01">
                    Nenhuma assinatura encontrada
                </p>
            </When>
        </ul>
    );
}

export default SubscriptionsUserList