import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useHeaderConfig } from "./useHeaderConfig";

const DeleteData = async (id: number) => {
    const config = useHeaderConfig();
    return await axios.get(`/api/exclui-usuario?id=${id}`, config);
}

export function useUserDelete() {
    const queryClient = useQueryClient();
    const mutate = useMutation({
        mutationFn: DeleteData,
        onSuccess: () => {
            queryClient.invalidateQueries(['admin-user-data']);
            // window.location.reload();
        }
    });

    return mutate;
}