import { HTMLAttributes } from "react";

import styles from '../../styles/purchases-historic.module.css';

interface BasePageAdminProps extends HTMLAttributes<HTMLDivElement> {}

const BasePageAdmin = (props: BasePageAdminProps) => {
    return (
        <div className="container">
                <div className="row">
                    <div className="col-md-1"></div>
                    <div
                        className={'col-md-10 ' + styles['col-content']}
                        {...props}
                    >
                        {props.children}
                    </div>
            </div>
        </div>
    );
}

export default BasePageAdmin