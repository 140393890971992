import { createSlice } from '@reduxjs/toolkit';
import IUser from "../types/user";

interface IUserState {
    user: IUser | null;
};

const initialState: IUserState = {
    user: null,
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
        }
    }
});

export const { setUser } = userSlice.actions;

export const selectUser =
    (state: { user: IUserState }) => state.user.user;

export default userSlice.reducer;