import { useEffect, useState } from "react";

import CardTooltip from '../../../common/card-tooltip/';
import styles from './pagination-cards.module.css';
import { IProduct } from "../../../types/produto";
import CardPlanValues from "../../../common/card-plan-values";

function PaginationCards(props: { products: IProduct[], type: 'home' | 'plansAndValues' }) {
    const [products, setProducts] = useState<IProduct[] | []>([]);
    const [animation, setAnimation] = useState('fade-in');
    useEffect(() => {
        setAnimation(_ => 'fade-out');
        setTimeout(() => {
            setProducts(_ => props.products);
            setAnimation(_ => 'fade-in');
        }, 500);
    }, [props.products]);

    return (
        <div className="row">
            {products.map(item => (
                <div
                    key={item.id}
                    style={{ marginBottom: '42px' }}
                    className={'col-xl-3 col-lg-4 col-md-6 ' + styles[animation]}
                >
                    {props.type === 'home' && (
                        <CardTooltip
                            id={item.id}
                            thumbnail_link={item.thumbnail_link}
                            title={item.title}
                            description={item.description}
                            badge={item?.badge}
                            paragraph_1={item.paragraphs ? item.paragraphs[0] : ''}
                            paragraph_2={item.paragraphs ? item.paragraphs[1] : ''}
                            redirect_link={item.id.includes('pacote') ? `/pacote/${item.id}` : `/produto/${item.id}`}
                        />
                    )}
                    {props.type === 'plansAndValues' && (
                        <CardPlanValues
                            id={item.id}
                            thumbnail_link={item.thumbnail_link}
                            title={item.title}
                            description={item.description}
                            payment_link={item.payment_link}
                            badge={item?.badge}
                            price={item?.price}
                            preco_anual={item?.preco_anual}
                            preco_semestral={item?.preco_semestral}
                            periodo_faturamento={item?.periodo_faturamento}
                        />  
                    )}
                </div>
            ))}
        </div>
    );
}

export default PaginationCards;