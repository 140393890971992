import { useRef } from 'react';

import AboutUs from '../components/home/about-us/about-us';
import CarouselHeader from '../components/home/carousel-header/carousel-header';

function HomeView() {

    const paginationSection = useRef<HTMLDivElement>(null);
    function scrollToPaginationSection() {
        if (paginationSection.current) {
            window.scrollTo({
                top: paginationSection.current.offsetTop,
                behavior: "smooth",
            });
        }
    }

    return (
        <main>
            <AboutUs onClick={scrollToPaginationSection} />
            <CarouselHeader />
        </main>
    );
}

export default HomeView;
