import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { RelatorioMutate } from "../types/relatorio";

const PostData = async (data: RelatorioMutate): Promise<any> => {
    const controller = new AbortController();
    const httpClient = axios.create({ timeout: 100000 });
    return await httpClient.post('/api/relatorio', data, { timeout: 100000, signal: controller.signal });
}

export function useRelatorioMutate() {
    
    const mutate = useMutation({
        mutationFn: PostData,
    });

    return mutate;
}