import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { EmbeddedBIData } from "../types/embedded-bi";
import { useHeaderConfig } from "./useHeaderConfig";

const FetchData = async (id: string): Promise<AxiosResponse<EmbeddedBIData>> => {
    const config = useHeaderConfig();
    const response = await axios.get<EmbeddedBIData>(`/api/embeddedbi?id=${id}`, config);
    return response;
};

export function useEmbeddedBiData(id: string) {
    const query = useQuery({
        queryFn: () => FetchData(id),
        queryKey: ['embedded-bi-data', id],
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        ...query,
        report: query.data?.data
    };
}