import { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import logoDark from '../assets/images/logo-dark.svg';
import logoLight from '../assets/images/logo-light.svg';
import { Button } from '../common/button';
import Input from '../common/input/input-zod';
import Spinner from '../common/spinner/spinner';
import styles from '../styles/register-social.module.css';
import { useRegisterMutate } from '../hooks/useRegisterMutate';

import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import Label from '../common/label/label';
import InputErrorMessage from '../common/input-error-message';
import SelectInput from '../common/input/select-input';
import InputPassword from '../common/input/input-password';
import Field from '../common/field/field';

const createUserSchema = z.object({
    name: z.string()
        .min(1, {
            message: 'O nome é obrigatório'
        }),
    email: z.string()
        .min(1, {
            message: "O e-mail é obrigatório"
        }).email({
            message: 'Formato de e-mail inválido',
        }),
    company: z.string()
        .min(1, {
            message: "O nome da sua empresa é obrigatório"
        }),
    occupationArea: z.string()
        .min(1, {
            message: "A área de atuação é obrigatória"
        }),
    password: z.string()
        .min(8, {
            message: 'A senha precisa ter no mínimo 8 caracteres',
        }).refine(password => {
            return new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/, "gm").test(password)
        }, {
            message: 'A senha a precisa ter letras maiúsculas, minúsculas, números e caracteres especiais'
        }),
    confirmPassword: z.string()
        .min(8, {
            message: 'A senha precisa ter no mínimo 8 caracteres',
        }).refine(password => {
            return new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])[0-9a-zA-Z$*&@#]{8,}$/, "gm").test(password)
        }, {
            message: 'A senha a precisa ter letras maiúsculas, minúsculas, números e caracteres especiais'
        }),
    privacyPolicies: z.literal<boolean>(true, {
        errorMap: () => ({ message: "A confirmação dos termos de uso é obrigatória" })
    }),
});

type CreateUserData = z.infer<typeof createUserSchema>

function Register() {
    const createUserForm = useForm<CreateUserData>({
        resolver: zodResolver(createUserSchema),
    });

    const {
        register,
        handleSubmit,
        formState: { errors }
      } = createUserForm;

    const occupationAreaOptions = [
        'Integrador',
        'Distribuidor',
        'EPC',
        'Financiador',
        'Desenvolvedor',
        'Investidor',
        'Fabricante',
        'Serviços',
        'Consumidor',
        'Empresa de serviços públicos',
        'Outro'
    ];

    useEffect(() => {
        const formLeadStr = localStorage.getItem('formLead');
        if(!formLeadStr) return;

        const formLead: {
            email: string,
            empresa: string,
            setor: string
        } = JSON.parse(formLeadStr);

        const email = formLead.email;
        const company = formLead.empresa;
        const occupationArea = formLead.setor;

        createUserSchema.parse({ email, company, occupationArea });
    }, []);

    const registerMutate = useRegisterMutate('/login');

    function createUser(data: CreateUserData) {
        if(!validatePasswords(data)) return;

        const mutateData = {
            nome: data.name,
            email: data.email,
            empresa: data.company,
            setor: data.occupationArea,
            senha: data.password
        };
        registerMutate.mutate(mutateData);
    }

    const [error, setError] = useState<string>('');

    const validatePasswords = (data: CreateUserData) => {
        if (data.password !== data.confirmPassword) {
            setError("As senhas não coincidem");
            return false;
        }
        setError("");
        return true;
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className={"col-md-8 " + styles['col-img']}>
                        <Link to="/">
                            <img src={logoLight} alt="logo Greendex" />
                        </Link>
                    </div>
                    <div className="col-md-4 col-xl-3 p-4">
                        <Link
                            to="/login"
                            className={'d-flex align-items-center ' + styles['back-button']}
                            
                        >
                            <FaChevronLeft className={styles['icon']} />
                            <span className={'text-body t-medium ' + styles['back-button-text']}>
                                Voltar
                            </span>
                        </Link>
                        <Link to="/">
                            <img
                                src={logoDark} alt="logo Greendex"
                                className={styles['logo-dark']}
                            />
                        </Link>
                        <h5
                            className="title-h5 text-semibold"
                            style={{ marginBottom: '24px' }}
                        >
                            Cadastro
                        </h5>

                        <FormProvider {...createUserForm}>
                            <form onSubmit={handleSubmit(createUser)}>
                                <Field>
                                    <Label htmlFor="name">
                                        Nome de usuário
                                    </Label>
                                    <Input
                                        type="name"
                                        name="name"
                                        placeholder="Defina um nome de usuário"
                                    />
                                    <InputErrorMessage field="name" />
                                </Field>
                            
                                <Field>
                                    <Label htmlFor="email">
                                        E-mail
                                    </Label>
                                    <Input
                                        type="email"
                                        name="email"
                                        placeholder="Seu melhor e-mail"
                                    />
                                    <InputErrorMessage field="email" />
                                </Field>
                            
                                <Field>
                                    <Label htmlFor="company">
                                        Empresa
                                    </Label>
                                    <Input
                                        type="text"
                                        name="company"
                                        placeholder="Sua empresa"
                                    />
                                    <InputErrorMessage field="company" />
                                </Field>

                                <Field>
                                    <Label htmlFor="occupationArea">
                                        Área de atuação
                                    </Label>
                                    <SelectInput
                                        name="occupationArea"
                                        options={occupationAreaOptions}
                                    />
                                    <InputErrorMessage field="occupationArea" />
                                </Field>

                                <Field>
                                    <Label htmlFor="password">
                                        Senha
                                    </Label>
                                    <InputPassword
                                        name="password"
                                        placeholder="Sua senha"
                                    />
                                    <InputErrorMessage field="password" />
                                </Field>

                                <Field>
                                    <Label htmlFor="confirmPassword">
                                        Confirmar senha
                                    </Label>
                                    <InputPassword
                                        name="confirmPassword"
                                        placeholder="Sua senha"
                                    />
                                    <InputErrorMessage field="confirmPassword" />
                                    {error && <p className="input-error">{error}</p>}
                                </Field>

                                <Field>
                                    <div>
                                        <input
                                            type="checkbox"
                                            {...register('privacyPolicies')}
                                        />
                                        <span>
                                            {" "}
                                            Eu li e concordo com os
                                            <a
                                                target="_blank"
                                                href="https://greendexv2beta.blob.core.windows.net/greendex-assets/Greendex_-_Contrato_de_Licenciamento_de_Uso_de_Plataforma_e_Prestacao_de_Servicos_1.pdf"
                                            >
                                                {" Termos de Uso"}
                                            </a>
                                        </span>
                                    </div>
                                    <InputErrorMessage field="privacyPolicies" />
                                </Field>

                                {registerMutate.isError && (
                                    <p className="input-error">Erro ao realizar cadastro.</p>
                                )}
                                {registerMutate.isSuccess && (
                                    <p className="input-success">Cadastro realizado com sucesso.</p>
                                )}

                                <Button.Primary
                                    btnType='btn-primary'
                                    type='submit'
                                    style={{ width: '100%', marginTop: '16px' }}
                                >
                                    Cadastrar
                                </Button.Primary>
                            </form>
                        </FormProvider>

                        <p className={styles['bottom-panel']}>
                            <div style={{ letterSpacing: '0.3px' }}>
                                <span style={{
                                        color: '#1A1A1A',
                                        marginRight: '8px'
                                }} >
                                    Já possui uma conta?
                                </span>
                                <Link to='/login'>Entrar agora</Link>
                            </div>
                            © Greendex {new Date().getFullYear()}
                        </p>
                    </div>
                </div>
            </div>
            {registerMutate.isLoading && <Spinner />}
        </>
    );
}
export default Register;