import { useState } from "react";
import { Tooltip } from "react-tooltip";

import Box from "../../common/box/box";
import { dateFormatter } from "../../app/formatter";

interface ApiStatusData {
    data: string,
    status_atual: string,
    off_time: number
};

const Line = () => {
    return (
        <hr style={{ 
            flexGrow: '1',
            maxWidth: '100%',
            marginLeft: '16px',
            marginRight: '16px'
        }} />
    );
}

const StatusItem = ({ isSucces, setTooltipContent, disabled = false }: { isSucces: boolean, setTooltipContent: Function, disabled?: boolean }) => {
    return (
        <div
            className={disabled ? "bg-greyscale-05 status-item-tooltip" : isSucces ? "bg-secondary-01 status-item-tooltip" : "bg-error status-item-tooltip"}
            onMouseEnter={() =>
                setTooltipContent()
            }
            onMouseLeave={() => setTooltipContent("")}
        ></div>
    );
}

const StatusSection = (props: { title: string, apiName: string, status: ApiStatusData[] }) => {
    const [content, setContent] = useState(<div></div>);
    
    return (
        <section>
            <h3 className="title-h3 text-bold color-primary-01" style={{ marginTop: '48px', marginBottom: '24px' }}>
                {props.title}
            </h3>
            <Box>
                <div className="d-flex justify-content-between" style={{ marginBottom: '16px' }}>
                    <h5 className="title-h5">
                        {props.apiName}
                    </h5>
                    <span className="color-secondary-02">{
                        props.status[props.status.length - 1].status_atual  === 'Running' ?
                            'Operacional' : ''
                    }</span>
                </div>
                <div className="d-flex flex-wrap justify-content-end" style={{ width: '100%', gap: '4px'}}>
                    {props.status.length < 90 && Array.from(Array(90 - props.status.length).keys()).map((_, index) => (
                        <StatusItem
                            key={index}
                            isSucces={false}
                            disabled={true}
                            setTooltipContent={() => setContent(
                                <div className="d-flex flex-column align-items-center">
                                    <span>API em fase de desenvolvimento</span>
                                </div>
                            )}
                        />
                    ))}
                    {props.status.map((itemStatus, index) => (
                        <StatusItem
                            key={index}
                            isSucces={itemStatus.status_atual === 'Running' && itemStatus.off_time === 0}
                            setTooltipContent={() => setContent(
                                <div className="d-flex flex-column align-items-center">
                                    <span>{dateFormatter(itemStatus.data)}</span>
                                    {itemStatus.off_time === 0 ? (
                                        <span className="color-secondary-02">
                                            100% Operacional
                                        </span>
                                    ):(
                                        <span className="color-error">Indisponível por {itemStatus.off_time}s</span>
                                    )}
                                </div>
                            )}
                        />
                    ))}
                </div>
                <div className="d-flex justify-content-between color-greyscale-05" style={{ marginTop: '16px' }}>
                    <span>
                        90 dias atrás
                    </span>
                    <Line />
                    <span>100% uptime</span>
                    <Line />
                    <span>hoje</span>
                </div>
            </Box>
            <Tooltip anchorSelect=".status-item-tooltip">{content}</Tooltip>
        </section>
    );
};

export default StatusSection;