interface FilterItemNameProps {
    name: string
}

const FilterItemName = ({ name }: FilterItemNameProps) => {
    return (
        <span
            className='color-greyscale-04'
            style={{ width: 'max-content', marginRight: '24px' }}
        >
            {name}
        </span>
    );
}

export default FilterItemName