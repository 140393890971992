import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";

import { AdminUserData } from "../types/user";
import { useHeaderConfig } from "./useHeaderConfig";

const mountQuery = (filter?: string, page?: number) => {
    if(filter && page)
        return `/api/adm-usuarios?substring=${filter}&pagina=${page}`;
    if (filter)
        return `/api/adm-usuarios?substring=${filter}`;
    if(page)
        return `/api/adm-usuarios?pagina=${page}`;
    return '/api/adm-usuarios';
}

const FetchData = async (url: string): Promise<AxiosResponse<AdminUserData>> => {
    const config = useHeaderConfig();
    const response = await axios.get<AdminUserData>(url, config);
    return response;
};

export function useAdminUserListData(filter?: string, page?: number) {
    const url = mountQuery(filter, page);

    const query = useQuery({
        queryFn: () => FetchData(url),
        queryKey: ['admin-user-data', filter, page],
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false
    });

    return {
        ...query,
        data: query.data?.data
    };
}