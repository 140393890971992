import React, { useEffect, useState } from 'react'

import EditableField from './editable-field';
import Line from './line';
import When from '../../common/when/when';
import { useProfileData } from '../../hooks/useProfileData';

const EditableFields = () => {
    const { profile } = useProfileData();

    useEffect(() => {
        setPhone(profile?.telefone);
        setCompany(profile?.empresa);
        setOccupationArea(profile?.setor);
    }, [profile]);

    const [phone, setPhone] = useState(profile?.telefone);
    const [showInputPhone, setShowInputPhone] = useState(false);

    const [company, setCompany] = useState(profile?.empresa);
    const [showInputCompany, setShowInputCompany] = useState(false);

    const [occupationArea, setOccupationArea] = useState(profile?.setor);
    const [showInputOccupationArea, setShowInputOccupationArea] = useState(false);
    
    const editableFields = [
        {
            title: 'Telefone',
            value: phone,
            showInput: showInputPhone,
            onShowInput: () => setShowInputPhone(true),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value),
            onEdit: () => {setShowInputPhone(false)},
            onCancel: () => {
                setPhone(profile?.telefone);
                setShowInputPhone(false);
            }
        },
        {
            title: 'Empresa',
            value: company,
            showInput: showInputCompany,
            onShowInput: () => setShowInputCompany(true),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setCompany(e.target.value),
            onEdit: () => {setShowInputCompany(false)},
            onCancel: () => {
                setCompany(profile?.empresa);
                setShowInputCompany(false);
            }
        },
        {
            title: 'Área de atuação',
            value: occupationArea,
            showInput: showInputOccupationArea,
            onShowInput: () => setShowInputOccupationArea(true),
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => setOccupationArea(e.target.value),
            onEdit: () => {setShowInputOccupationArea(false)},
            onCancel: () => {
                setOccupationArea(profile?.setor);
                setShowInputOccupationArea(false);
            }
        },
    ];

    return (
        <>
            {editableFields.map((field, index) => (
                <div key={index}>
                    <EditableField
                        title={field.title}
                        value={field.value}
                        showInput={field.showInput}
                        onShowInput={field.onShowInput}
                        onChange={field.onChange}
                        onEdit={field.onEdit}
                        onCancel={field.onCancel}
                    />
                    <When expr={index !== (editableFields.length - 1)}>
                        <Line />
                    </When>
                </div>
            ))}
        </>
    );
}

export default EditableFields