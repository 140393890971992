import UserData from './user-data';
import UserImage from './user-image';
import UserItemActions from './user-item-actions';
import UserItemContainer from './user-item-container';

import When from '../../common/when/when';
import styles from '../../styles/purchases-historic.module.css';
import IUser from '../../types/user';

interface UsersListProps {
    users: IUser[],
    handleSelectUser: Function,
    handleOpenModalDelete: Function
}

const UsersList = ({ users, handleSelectUser, handleOpenModalDelete }: UsersListProps) => {
    return (
        <ul className={styles["list"]}>
            {users.map((user) => (
                <UserItemContainer key={user.id}>
                    <div className={"d-flex " + styles["row-user-circle"]}>
                        <UserImage
                            initialLetter={user.nome.substring(0, 1)}
                        />
                        <UserData user={user} />
                    </div>
                    <UserItemActions
                        user={user}
                        selectUser={handleSelectUser}
                        openModalDelete={handleOpenModalDelete}
                    />
                </UserItemContainer>
            ))}
            <When expr={users.length === 0}>
                <p className="text-body text-large text-w-medium color-greyscale-01">
                    Nenhum usuário encontrado
                </p>
            </When>
        </ul>
    );
}

export default UsersList