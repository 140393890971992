import { InputHTMLAttributes } from "react";
import { useFormContext } from 'react-hook-form'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string
};

function Input(props: InputProps) {
    const { register } = useFormContext();

    return (
        <input 
            id={props.name}
            className="input-register"
            {...register(props.name)} 
            {...props}
        />
    )
}
export default Input;