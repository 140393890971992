import { HTMLAttributes } from "react";

import styles from '../styles.module.css';

interface FilterListProps extends HTMLAttributes<HTMLDivElement> {}

const FilterList = (props: FilterListProps) => {
    return (
        <div className={styles['filters-list']} {...props} />
    );
}

export default FilterList