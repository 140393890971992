import { useRef, useState } from 'react';
import { BsGear } from 'react-icons/bs';
import { FaChevronRight } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import CircleAvatar from '../../../common/circle-avatar';
import { ProfileData } from '../../../types/profile';
import styles from './logout-dropdown.module.css';
import { useLogout } from '../../../hooks/useLogout';
import AuthGuard from '../../../guards/auth-guard';

function LogoutDropdown(props: { profile: ProfileData }) {
    const [showDropdownLogout, setShowDropdownLogout] = useState<boolean>(false);
    const dropdownRef = useRef(null);

    const logout = useLogout();

    return (
        <div
            className={styles['dropdown-logout']}
            onMouseEnter={() => setShowDropdownLogout(true)}
            onMouseLeave={() => setShowDropdownLogout(false)}
        >
            <Link to="/perfil" className={styles['circle']}>
                <CircleAvatar
                    nameInitial={props.profile.nome.substring(0, 1)}
                />
            </Link>
            <CSSTransition
                in={showDropdownLogout}
                nodeRef={dropdownRef}
                timeout={200}
                unmountOnExit
                classNames='fade'
            >
                <div ref={dropdownRef}>
                    <ul className={styles['dropdown-items-logout']}>
                        <li>
                            <Link
                                to="/perfil"
                                className='d-flex justify-content-between'
                            >
                                <p className="text-body text-medium color-dark-03">
                                    Perfil
                                </p>
                                <FaChevronRight className='color-dark-03' />
                            </Link>
                        </li>
                        <AuthGuard allow="greener">
                            <li>
                                <Link
                                    to="/admin"
                                    className='d-flex justify-content-between'
                                >
                                    <p className="text-body text-medium color-dark-03">
                                        Painel
                                    </p>
                                    <BsGear className='color-dark-03' />
                                </Link>
                            </li>
                        </AuthGuard>
                        <li>
                            <span
                                className='d-flex justify-content-between'
                                onClick={() => logout()}
                                style={{ cursor: 'pointer' }}
                            >
                                <p className="text-body text-medium color-red">
                                    Sair
                                </p>
                                <FiLogOut className='color-red' />
                            </span>
                        </li>
                    </ul>
                </div>
            </CSSTransition>
        </div>
    );
}
export default LogoutDropdown;