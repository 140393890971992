import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { BlogPostMutate } from "../types/blog";
import { useHeaderConfig } from "./useHeaderConfig";

const AddPost = async (data: BlogPostMutate) => {
    const config = useHeaderConfig();
    return await axios.put("/api/blog", data, config);
}

export function useBlogPostMutate() {
    const queryClient = useQueryClient();
    const mutate = useMutation({
        mutationFn: AddPost,
        onSuccess: () => {
            queryClient.invalidateQueries(['blog-data'])
        }
    });

    return mutate;
}