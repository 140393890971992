import { Dispatch, SetStateAction } from "react";

import styles from '../../styles/admin-user.module.css';

interface ModalConfirmContentProps {
    selectedPeriod: string,
    setSelectedPeriod: Dispatch<SetStateAction<string>>
}

const ModalConfirmContent = ({ selectedPeriod, setSelectedPeriod }: ModalConfirmContentProps) => {
    const subscriptionPeriods = [
        '1 mês',
        '6 meses',
        '1 ano',
    ];

    return (
        <div>
            <p className="label-register">
                Período de acesso
            </p>
            <div className={styles['select-wrapper']}>
                <select
                    className={"input-register " + styles['select']}
                    value={selectedPeriod}
                    onChange={e => setSelectedPeriod(e.target.value)}
                >
                    {subscriptionPeriods.map((period, index) =>
                        <option value={period} key={index}>
                            {period}
                        </option>
                    )}
                </select>
            </div>
        </div>
    );
}

export default ModalConfirmContent