import logoGDark from '../../assets/images/logo-g-dark.svg';
import styles from '../../styles/admin-user.module.css';

interface SubUserItemContentProps {
    title: string,
    description: string
}

const SubUserItemContent = ({ title, description }: SubUserItemContentProps) => {
    return (
        <div className="d-flex">
            <img
                src={logoGDark}
                alt={title}
                className={styles['subscription-image']}
            />
            <div className={styles['subscription-text']}>
                <p className="text-body text-large text-w-medium color-greyscale-01">
                    {title}
                </p>
                <p className="text-body color-greyscale-02">
                    {description}
                </p>
            </div>
        </div>
    );
}

export default SubUserItemContent