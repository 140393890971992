import { Link } from 'react-router-dom';

import Breadcrumb from '../../common/breadcrumb';
import { Button } from '../../common/button';

import styles from '../../styles/purchases-historic.module.css';

const Header = () => {
    return (
        <>
            <Breadcrumb>
                <Link to="/admin">Painel Administrativo</Link>
                <span>Blog</span>
            </Breadcrumb>
            <div className={'row d-flex justify-content-between ' + styles['row-text']}>
                <h3
                    className={'title-h3 text-bold ' + styles['title']}
                    style={{ marginBottom: '24px' }}
                >
                    Blog
                </h3>
                <Button.Link
                    btnType='btn-primary'
                    to='add'
                >
                    Adicionar
                </Button.Link>
            </div>
        </>
    );
}

export default Header