import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { SubscriptionDelete } from "../types/payment";
import { useHeaderConfig } from "./useHeaderConfig";

const DeleteData = async (data: SubscriptionDelete) => {
    const defaultConfig = useHeaderConfig();
    const config = {...defaultConfig, data};
    return await axios.delete('api/assinaturas', config);
}

export function useSubscriptionDelete() {
    const queryClient = useQueryClient();
    const mutate = useMutation({
        mutationFn: DeleteData,
        onSuccess: () => {
            queryClient.invalidateQueries([
                'manage-subscriptions-data',
                'product-data',
                'my-products-data',
                'purchase-historic-data',
                'admin-user-data'
            ]);
            window.location.reload();
        }
    });

    return mutate;
}