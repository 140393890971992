import { Link } from "react-router-dom";

import styles from './card-BI.module.css';
import BaseCard from "../../../common/base-card";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { MyProductData } from "../../../types/my-product";

interface CardBIProps {
    id: string;
    thumbnail_link: string;
    title: string;
    description: string;
    paragraph_1: string;
    paragraph_2: string;
    link_bi: string;
    onSelectBI: Function;
    product: MyProductData;
    badge?: string;
}

function CardBI(props: CardBIProps) {
    const gaEventTracker = useAnalyticsEventTracker('Meus Produtos');
    
    return (
        <BaseCard
            id={props.id}
            thumbnail_link={props.thumbnail_link}
            title={props.title}
            description={props.description}
            paragraph_1={props.paragraph_1}
            paragraph_2={props.paragraph_2}
        >
            {(props.id !== 'dados-geracao') && (props.product.acesso !== 'interno-link') && (props.product.acesso !== 'interno-capacidade') && (
                <Link to={`/produto/${props.id}`}>
                    <button
                        className={'color-primary-04 ' + styles['button']}
                        onClick={() => gaEventTracker(
                            'Clique no botão Detalhes do produto',
                            `Clique no botão Detalhes do produto do produto ${props.title}`
                        )}
                    >
                        Detalhes do produto
                    </button>
                </Link>
            )}
            <button
                className={'color-primary-04 ' + styles['button']}
                onClick={() => {
                    gaEventTracker(
                        `Clique no botão Acessar Produto: ${props.title}`,
                        `Clique no botão Acessar Produto do produto ${props.title}`
                    );
                    props.onSelectBI(props.product);
                }}
            >
                Acessar produto
            </button>
        </BaseCard>
    );
}
export default CardBI;