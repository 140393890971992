import { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { Link, useParams, useSearchParams } from 'react-router-dom';

import logoDark from '../assets/images/logo-dark.svg';
import logoLight from '../assets/images/logo-light.svg';
import { Button } from '../common/button';
import Input from '../common/input/input';
import Spinner from '../common/spinner/spinner';
import styles from '../styles/register-social.module.css';
import { useResetPassMutate } from '../hooks/useResetPassMutate';

function ResetPass() {
    const [searchParams, _] = useSearchParams();
    const token = searchParams.get('token');

    const [formSubmited, setFormSubmited] = useState(false);
    const [formValue, setFormValue] = useState({
        password: { value: '', valid: false },
        confirmPassword: { value: '', valid: false },
    });

    function validateEntries() {
        //typescript não aceita fazer isso de forma iterativa
        return formValue['password'].valid &&
            formValue['confirmPassword'].valid;
    }

    function setInputValue(inputName: string, value: string, valid: boolean) {
        setFormValue(state => {
            return {
                ...state,
                [inputName]: { value, valid }
            };
        })
    }

    const resetPassMutate = useResetPassMutate();

    function handleSubmit(e: any) {
        e.preventDefault();
        setFormSubmited(true);

        if(!validateEntries()) return;

        const data = {
            recovery_code: token,
            senha: formValue.password.value
        };
        resetPassMutate.mutate(data);
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className={"col-md-8 " + styles['col-img']}>
                        <Link to="/">
                            <img src={logoLight} alt="logo Greendex" />
                        </Link>
                    </div>
                    <div className="col-md-4 col-xl-3 p-4">
                        <Link
                            to="/login"
                            className={'d-flex align-items-center ' + styles['back-button']}
                            
                        >
                            <FaChevronLeft className={styles['icon']} />
                            <span className={'text-body t-medium ' + styles['back-button-text']}>
                                Voltar
                            </span>
                        </Link>
                        <Link to="/">
                            <img
                                src={logoDark} alt="logo Greendex"
                                className={styles['logo-dark']}
                            />
                        </Link>
                        <h5
                            className="title-h5 text-semibold"
                            style={{ marginBottom: '24px' }}
                        >
                            Redefinir senha
                        </h5>
                        <form onSubmit={e => handleSubmit(e)}>
                            <Input
                                inputName="password"
                                label="Senha"
                                placeholder="Sua senha"
                                type="password"
                                errorMessage="* insira uma senha válida"
                                formSubmited={formSubmited}
                                setInputValue={setInputValue}
                            />
                            <Input
                                inputName="confirmPassword"
                                label="Confirmar senha"
                                placeholder="Sua senha"
                                type="password"
                                errorMessage="* senha não confere"
                                formSubmited={formSubmited}
                                setInputValue={setInputValue}
                                password={formValue.password.value}
                            />
                            {resetPassMutate.isError && (
                                <p className="input-error">Erro ao realizar trocar senha.</p>
                            )}
                            {resetPassMutate.isSuccess && (
                                <p className="input-success">Senha trocada com sucesso.</p>
                            )}
                            <Button.Primary
                                btnType='btn-primary'
                                type='submit'
                                style={{ width: '100%', marginTop: '16px' }}
                            >
                                Cadastrar
                            </Button.Primary>
                        </form>
                    </div>
                </div>
            </div>
            {resetPassMutate.isLoading && <Spinner />}
        </>
    );
}
export default ResetPass;