import './button.css';

import AnchorButton from "./anchor-button";
import LinkButton from "./link-button";
import PrimaryButton from "./primary-button";

export const Button =  {
    Primary: PrimaryButton,
    Link: LinkButton,
    a: AnchorButton
}