import styles from './styles.module.css';
import Container from './container';
import Title from './title';

interface IBasePage {
    title: string
    children: JSX.Element
    beadcrumb?: JSX.Element
    description?: string | JSX.Element
    col?: number
    titleButton?: JSX.Element
}

function BasePage(props : IBasePage) {
    return (
        <Container>
            {props.beadcrumb}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title
                    title={props.title}
                    description={props.description}
                />
                {props.titleButton}
            </div>
            <section className={styles['data']}>
                {props.children}
            </section>
        </Container>
    );
}
export default BasePage;