import { SelectHTMLAttributes } from "react";
import { useFormContext } from 'react-hook-form';

import styles from './select-input.module.css';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    name: string;
    options: any[];
};

const SelectInput = (props: SelectProps) => {
    const { register } = useFormContext();

    return (
        <div className={styles['select-wrapper']}>
            <select
                className={"input-register " + styles['select']}
                {...register(props.name)}
                {...props}
            >
                {props.options.map(option =>
                    <option value={option} key={option}>
                        {option}
                    </option>
                )}
            </select>
        </div>
    );
}

export default SelectInput