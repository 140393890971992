import { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BsGear } from 'react-icons/bs';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { MdMail } from 'react-icons/md';
import { RiMenu2Line, RiMenu3Fill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import logoLight from '../../../assets/images/logo-light.svg';
import CircleAvatar from '../../../common/circle-avatar';
import { IProduct } from '../../../types/produto';
import styles from './navbar-mobile.module.css';
import { useLogout } from '../../../hooks/useLogout';
import { ProfileData } from '../../../types/profile';
import AuthGuard from '../../../guards/auth-guard';


export interface NavbarProps {
    products: IProduct[] | [],
    profile: ProfileData | undefined
}

function NavbarMobile(props: NavbarProps) {
    const logout = useLogout();
    const [showSidemenu, setShowSidemenu] = useState<boolean>(false);
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [productsSearch, setProductsSearch] = useState<IProduct[] | []>([]);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        setInputValue(e.target.value);
        if (props.products.length === 0) return;
        if (inputValue === '') {
            setProductsSearch([]);
            return;
        }

        setProductsSearch(
            props.products.filter(product =>
                product.title.toLocaleLowerCase()
                    .includes(inputValue.toLocaleLowerCase())
            ).slice(0, 5)
        );
    }

    return (
        <>
            <header
                className={'d-flex justify-content-between align-items-center ' + styles['header']}
            >
                <RiMenu2Line
                    className='color-white'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowSidemenu(true)}
                />
                {!showSearch ? (
                    <Link to="/">
                        <img src={logoLight} alt="logo Greendex" style={{ width: '160px' }} />
                    </Link>
                ) : (
                    <div className={styles['search']}>
                        <input
                            type="text"
                            className={styles['search-input']}
                            value={inputValue}
                            onChange={(e) => handleChange(e)}
                            placeholder="Pesquise um produto"
                        />
                        {
                            inputValue !== '' &&
                            <ul className={styles['search-list']}>
                                {
                                    productsSearch.map(product => (
                                        <Link to={`/produto/${product.id}`}>
                                            <li>{product.title}</li>
                                        </Link>
                                    ))
                                }
                            </ul>
                        }
                    </div>
                )}
                <AiOutlineSearch
                    className='color-white'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowSearch(state => !state)}
                />
            </header>
            {showSidemenu &&
                <aside className={styles['side-menu']}>
                    {props.profile ? (
                        <div
                            className={'d-flex justify-content-between align-items-center ' + styles['profile']}
                        >
                            <div className='d-flex'>
                                <Link to="/perfil">
                                    <CircleAvatar
                                        nameInitial={props.profile.nome.substring(0, 1)}
                                    />
                                </Link>
                                <div className="d-flex" style={{ flexDirection: 'column' }}>
                                    <p className="text-body text-large text-semibold text-white">
                                        Olá, <b>{props.profile.nome}</b>
                                    </p>
                                    <Link to="/perfil" className={styles['link']}>Ver perfil</Link>
                                </div>
                            </div>
                            <RiMenu3Fill
                                className='color-white'
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowSidemenu(false)}
                            />
                        </div>
                    ) : (
                        <div
                            className={'d-flex justify-content-between align-items-center ' + styles['profile']}
                        >
                            <Link to="/login" className={styles['link']}>Fazer login</Link>
                            <RiMenu3Fill
                                className='color-white'
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowSidemenu(false)}
                            />
                        </div>
                    )}
                    <div className={'section ' + styles['category']}>
                        <ul className={styles['category-items']}>
                            <li onClick={() => setShowSidemenu(false)}>
                                <Link
                                    to="/dados-gd"
                                    className='d-flex justify-content-between'
                                >
                                    <p className="text-body text-medium color-white">
                                        Dados GD
                                    </p>
                                    <FaChevronRight className='color-white' />
                                </Link>
                            </li>
                            <li onClick={() => setShowSidemenu(false)}>
                                <Link
                                    to="/blog"
                                    className='d-flex justify-content-between'
                                >
                                    <p className="text-body text-medium color-white">
                                        Blog
                                    </p>
                                    <FaChevronRight className='color-white' />
                                </Link>
                            </li>
                            <li onClick={() => setShowSidemenu(false)}>
                                <Link
                                    to="/contato"
                                    className='d-flex justify-content-between'
                                >
                                    <p className="text-body text-medium color-white">
                                        Contato
                                    </p>
                                    <MdMail className='color-white' />
                                </Link>
                            </li>
                            <AuthGuard allow='greener'>
                                <li onClick={() => setShowSidemenu(false)}>
                                    <Link
                                        to="/admin"
                                        className='d-flex justify-content-between'
                                    >
                                        <p className="text-body text-medium color-white">
                                            Painel administrativo
                                        </p>
                                        <BsGear className='color-white' />
                                    </Link>
                                </li>
                            </AuthGuard>
                            <li>
                                <span
                                    className='d-flex justify-content-between'
                                    onClick={() => logout()}
                                >
                                    <p className="text-body text-medium color-red">
                                        Sair
                                    </p>
                                    <FiLogOut className='color-red' />
                                </span>
                            </li>
                        </ul>
                    </div>
                </aside>
            }
        </>
    );
}
export default NavbarMobile;