import styles from '../../styles/purchases-historic.module.css';
import IUser from '../../types/user';

interface UserDataProps {
    user: IUser
}

const UserData = ({ user }: UserDataProps) => {
    return (
        <div className={styles["order-purchase-data"]}>
            <p className="text-body text-large text-w-medium color-greyscale-01">
                {user.nome}
            </p>
            <div className="d-flex">
                <p className="text-body text-small">
                    <span
                        className="color-greyscale-03"
                        style={{ marginRight: "5px" }}
                    >
                        E-mail:
                    </span>
                    <span className="color-greyscale-03">
                        {user.email}
                    </span>
                </p>
                <p className="text-body text-small">
                    <span
                        className="color-greyscale-03"
                        style={{ marginRight: "5px" }}
                    >
                        Empresa:
                    </span>
                    <span className="color-greyscale-03">
                        {user.empresa}
                    </span>
                </p>
            </div>
            <div className="d-flex">
                <p className="text-body text-small">
                    <span
                        className="color-greyscale-03"
                        style={{ marginRight: "5px" }}
                    >
                        N° de assinaturas:
                    </span>
                    <span className="color-greyscale-03">
                        {user.acessos.length}
                    </span>
                </p>
                <p className="text-body text-small">
                    <span
                        className="color-greyscale-03"
                        style={{ marginRight: "5px" }}
                    >
                        Início:
                    </span>
                    <span className="color-greyscale-03">
                        Desconhecido
                        {/* {user.start.slice(-2)}/{user.start.slice(5, 7)}/
{user.start.slice(0, 4)} */}
                    </span>
                </p>
            </div>
        </div>
    );
}

export default UserData