import { useEffect, useState } from "react";

import { Button } from "../../../common/button";
import Spinner from "../../../common/spinner/spinner";
import styles from '../../../styles/register-social.module.css';
import { PRICES_API_AUTHORIZATION, PRICES_API_URL } from "../../../views/prices-api";

interface IFormSimulation {
    btn: 'btn-primary-outline' | 'btn-primary';
    state: string | undefined;
    city: string | undefined;
    billAverage: number | undefined;
    setKwp: Function;
    setSelectedState: Function;
    setSelectedCity: Function;
    setSelectedBillAverage: Function;
};

function FormSimulation(props: IFormSimulation) {
    const [loading, setLoading] = useState(false);

    const [states, setStates] = useState<string[]>([]);
    const [selectedState, setSelectedState] = useState("");
    
    const [cities, setCities] = useState<string[]>([]);
    const [selectedCity, setSelectedCity] = useState("");
    
    const [averageBillValue, setAverageBillValue] = useState<number>();
    const [validAverageBillValue, setValidAverageBillValue] = useState(true);

    useEffect(() => {
        getStates();
        
        if(props.billAverage) {
            setAverageBillValue(props.billAverage);
        }
    }, []);

    useEffect(() => {
        if(props.state) {
            getCities(props.state);
            setSelectedState(props.state);
        } else {
            getCities(states[0]);
            setSelectedState(states[0]);
            props.setSelectedState(states[0]);
        }
    }, [states]);

    useEffect(() => {
        if(props.city) {
            setSelectedCity(props.city);
        } else {
            setSelectedCity(cities[0]);
            props.setSelectedCity(cities[0]);
        }
    }, [cities]);

    function getStates() {
        setLoading(true);
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Authorization': PRICES_API_AUTHORIZATION
            },
        };
        fetch(`${PRICES_API_URL}/uf`, requestOptions)
            .then(response => response.json())
            .then(data => setStates(data))
            .catch(e => console.log(e))
            .finally(() => setLoading(false));
    }

    function getCities(state: string) {
        if(!state) return;
        setLoading(true);

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Authorization': PRICES_API_AUTHORIZATION
            },
        };
        fetch(`${PRICES_API_URL}/cities?uf=${state}`, requestOptions)
            .then(response => response.json())
            .then(data => setCities(data))
            .catch(e => console.log(e))
            .finally(() => setLoading(false));
    }

    function handleSelectState(e: any) {
        props.setSelectedState(e.target.value);
        setSelectedState(e.target.value);
        getCities(e.target.value);
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if(!averageBillValue) {
            setValidAverageBillValue(false);
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Authorization': PRICES_API_AUTHORIZATION
            },
            body: JSON.stringify({
                uf: selectedState,
                municipalitie: selectedCity,
                client_type: "residencial",
                connection_type: "bifasica",
                average_bill_value: averageBillValue
            })
        };
        fetch(`${PRICES_API_URL}/sizer`, requestOptions)
            .then(response => response.json())
            .then(data => props.setKwp(data.kwp))
            .catch(e => console.log(e));
        
        setValidAverageBillValue(true);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <p className="label-register">Estado*</p>
                <div className={styles["select-wrapper"]}>
                    <select
                        className={"input-register " + styles["select"]}
                        value={selectedState}
                        onChange={handleSelectState}
                    >
                    {states.map((state) => (
                        <option value={state} key={state}>
                            {state}
                        </option>
                    ))}
                    </select>
                </div>
                <p className="label-register">Cidade*</p>
                <div className={styles["select-wrapper"]}>
                    <select
                        className={"input-register " + styles["select"]}
                        value={selectedCity}
                        onChange={e => {
                            props.setSelectedCity(e.target.value);
                            setSelectedCity(e.target.value);
                        }}
                    >
                    {cities.map((city) => (
                        <option value={city} key={city}>
                            {city}
                        </option>
                    ))}
                    </select>
                </div>
                <p className="label-register">Tipo de cliente*</p>
                <input
                    className="input-register"
                    type="text"
                    value="Residencial"
                    disabled
                />
                <p className="label-register">Tipo de conexão*</p>
                <input
                    className="input-register"
                    type="text"
                    value="Bifásica"
                    disabled
                />
                <p className="label-register">
                Valor médio das contas de energia nos últimos 12 meses* <span className="color-greyscale-06">(R$)</span>
                </p>
                <input
                    className="input-register"
                    type="number"
                    min={0}
                    step=".01"
                    value={averageBillValue}
                    onChange={e => {
                        props.setSelectedBillAverage(parseFloat(e.target.value));
                        setAverageBillValue(parseFloat(e.target.value));
                    }}
                />
                {!validAverageBillValue && (
                    <p className="input-error">
                        {"* Valor inválido"}
                    </p>
                )}
                <Button.Primary
                    btnType={props.btn}
                    type="submit"
                    style={{ width: '100%' }}
                >
                    Simular
                </Button.Primary>
            </form>
            {loading && <Spinner />}
        </>
    );
}
export default FormSimulation;