import { Button } from "../../../../common/button";

const BtnBuyWpp = (props: { style?: any }) => {
    return (
        <a href='https://api.whatsapp.com/send/?phone=+5535999295450&text=Ol%C3%A1!%20Quero%20garantir%20o%20Pacote%20GD' target="_blank">
            <Button.Primary
                btnType="btn-primary"
                style={props.style ? props.style : { width: "100%", marginTop: "18px" }}
            >
                Comprar agora
            </Button.Primary>
        </a>
    );
}

export default BtnBuyWpp