import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { useHeaderConfig } from "./useHeaderConfig";
import { InternProductMutate } from "../types/intern-product";

const PostData = async (data: InternProductMutate): Promise<any> => {
    const config = useHeaderConfig();
    return await axios.post('/api/dash-interno', data, config);
}

export function useAddInternProductMutate() {
    const queryClient = useQueryClient();

    const mutate = useMutation({
        mutationFn: PostData,
        onSuccess: () => {
            queryClient.invalidateQueries(['home-data']);
        }
    });

    return mutate;
}