import { Link } from 'react-router-dom';

import Line from './line';
import UserDataContent from './user-data-content';

import BasePage from '../../common/base-page';
import Breadcrumb from '../../common/breadcrumb';
import When from '../../common/when/when';
import styles from '../../styles/admin-user.module.css';

interface SectionUserDataProps {
    username: string,
    userData: {
        title: string;
        value: string;
    }[]
}

const SectionUserData = ({ username, userData }: SectionUserDataProps) => {
    return (
        <div className={styles['mb-section']}>
            <BasePage
                title="Dados do Usuário"
                beadcrumb={
                    <Breadcrumb>
                        <Link to="/admin">Painel Administrativo</Link>
                        <Link to="/admin/usuario">Usuários</Link>
                        <span>{username}</span>
                    </Breadcrumb>
                }
            >
                <section className={styles['data']}>
                    {userData.map((data, index) => (
                        <div key={index}>
                            <UserDataContent
                                title={data.title}
                                value={data.value}
                            />
                            <When expr={index !== (userData.length - 1)}>
                                <Line />
                            </When>
                        </div>
                    ))}
                </section>
            </BasePage>
        </div>
    );
}

export default SectionUserData