import { BsPencilSquare } from 'react-icons/bs';
import { RiDeleteBin2Line } from 'react-icons/ri';

import styles from '../../styles/purchases-historic.module.css';
import { ProductData } from '../../types/home';

interface ProductItemActionsProps {
    product: ProductData,
    selectProduct: Function,
    openModalDelete: Function
}

const ProductItemActions = ({ product, selectProduct, openModalDelete }: ProductItemActionsProps) => {
    return (
        <div className={"d-flex " + styles["actions"]}>
            <BsPencilSquare
                className="text-xlarge color-greyscale-03"
                style={{ cursor: "pointer", marginRight: "26px" }}
                onClick={() => selectProduct(product)}
            />
            <RiDeleteBin2Line
                className="text-xlarge color-red"
                style={{ cursor: "pointer" }}
                onClick={() => openModalDelete(product)}
            />
        </div>
    );
}

export default ProductItemActions