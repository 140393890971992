import { MouseEventHandler, SetStateAction } from 'react';
import { HiOutlineChevronDoubleRight, HiOutlineDocumentDownload } from 'react-icons/hi';

import styles from './styles.module.css';

interface FiltersClosedProps {
    id: string,
    setOpenFilters: (value: SetStateAction<boolean>) => void,
    saveReport: MouseEventHandler<SVGElement>
}

const FiltersClosed = ({ id, setOpenFilters, saveReport }: FiltersClosedProps) => {
    return (
        <aside className={styles['filters-close']}>
            <HiOutlineChevronDoubleRight
                className='color-greyscale-04'
                style={{ fontSize: '24px', cursor: 'pointer' }}
                onClick={() => setOpenFilters(true)}
            />
            {id === 'calculadora-gd' && (
                <HiOutlineDocumentDownload
                    className='color-greyscale-04'
                    style={{ fontSize: '24px', cursor: 'pointer' }}
                    onClick={saveReport}
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Download do arquivo em PDF"
                />
            )}
        </aside>
    );
}

export default FiltersClosed