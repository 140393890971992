import BasePage from "../common/base-page";
import Pagination from "../components/home/pagination/pagination";

function PlansAndValues() {

    return (
        <main>
            <BasePage
                title="Planos e Valores"
                // description="Lorem ipsum dolor sit amet consectetur. Lobortis amet eget ut dignissim elementum magna elementum malesuada faucibus. Quam ac morbi ligula pe."
                col={12}
            >
                <Pagination type='plansAndValues' />
            </BasePage>
        </main>
    );
}
export default PlansAndValues;