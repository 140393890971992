import { useEffect, useState } from "react";

import { IProduct } from "../types/produto";

function usePagination(type: 'home' | 'plansAndValues', dataProducts: any[]) {
    const dataProductsString = JSON.stringify(dataProducts); //para evitar looping infinito no useEffect

    const [products, setProducts] = useState<any[]>([]);
    const [pageProducts, setPageProducts] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 8;

    useEffect(() => {
        const dataProductsObject = JSON.parse(dataProductsString);

        if(type === 'home')
            setProducts(dataProductsObject);
        else
            setProducts(dataProductsObject.filter(
                (product: IProduct) => ((product.price && product.price !== 0) || product.preco_anual)
            ));
    }, [dataProductsString, type]);

    useEffect(() => {
        setPageProducts(products.slice(
            (currentPage-1)*productsPerPage, 
            currentPage*productsPerPage
        ));
    }, [currentPage, products]);

    const nextPage = () => {
        if(currentPage*productsPerPage < products.length)
            setCurrentPage(currentPage => ++currentPage);
    }

    const previousPage = () => {
        if(currentPage > 1)
            setCurrentPage(currentPage => --currentPage);
    }

    const applyFilter = (filterValue: string) => {
        if(filterValue === 'todas' && type === 'home')
            setProducts(dataProducts);
        else if(filterValue === 'todas')
            setProducts(dataProducts.filter(
                (product: IProduct) => ((product.price && product.price !== 0) || product.preco_anual)
            ));
        else if(type === 'home')
            setProducts(dataProducts.filter(
                product => product.category === filterValue
            ));
        else
            setProducts(dataProducts.filter(
                product => product.category === filterValue
            ).filter((product: IProduct) => 
                ((product.price && product.price !== 0) || product.preco_anual)
            ));

        setCurrentPage(1);
    }

    return {
        totalProducts: products.length,
        pageProducts,
        currentPage,
        productsPerPage,
        nextPage,
        previousPage,
        setCurrentPage,
        applyFilter
    }
}

export default usePagination