import { zodResolver } from '@hookform/resolvers/zod'
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button } from '../../common/button';
import Input from '../../common/input/input-zod';
import Label from '../../common/label/label';
import InputErrorMessage from '../../common/input-error-message';
import SelectInput from '../../common/input/select-input';
import Field from '../../common/field/field';
import { useRelatorioMutate } from '../../hooks/useRelatorioMutate';
import { RelatorioMutate } from '../../types/relatorio';
import Box from '../../common/box/box';
import { Link } from 'react-router-dom';

const createFilterSchema = z.object({
    distributor: z.string()
        .min(1, {
            message: 'O distribuidor é obrigatório'
        }),
    startDate: z.string()
        .min(1, {
            message: "A data de início é obrigatória"
        }),
    endDate: z.string()
        .min(1, {
            message: "A data de fim é obrigatória"
        })
});

type CreateFilterData = z.infer<typeof createFilterSchema>

interface FilterProps {
    onSuccess: ((data: any, variables: RelatorioMutate, context: unknown) => void) | undefined ;
    setIsLoading: Dispatch<SetStateAction<boolean>>
    setIsSubmited: Dispatch<SetStateAction<boolean>>
}

function Filter({ onSuccess, setIsLoading, setIsSubmited }: FilterProps) {
    const createFilterForm = useForm<CreateFilterData>({
        resolver: zodResolver(createFilterSchema),
    });

    const {
        handleSubmit,
        formState: { errors }
      } = createFilterForm;

    const nameDistributors = [
        'Aldo',
        'Sol+',
        'PHB',
        'Ecori',
        'BelEnergy',
        'Fortlev',
        'Ourolux',
        'NeoSolar',
        'Elgin'
    ];

    const relatorioMutate = useRelatorioMutate();

    function getPDF(data: CreateFilterData) {
        setIsSubmited(true);
        const mutateData = {
            'distributor-name': data.distributor,
            'start-date': data.startDate,
            'end-date': data.endDate
        };
        relatorioMutate.mutate(mutateData, {
            onSuccess: onSuccess
        });
    }

    useEffect(() => {
        setIsLoading(relatorioMutate.isLoading);
    }, [relatorioMutate.isLoading])

    return (
            <div style={{ paddingBottom: '24px' }}>
                <Box>
                    <FormProvider {...createFilterForm}>
                        <form onSubmit={handleSubmit(getPDF)}>
                            <Field>
                                <Label htmlFor="distributor">
                                    Distribuidor
                                </Label>
                                <SelectInput
                                    name="distributor"
                                    options={nameDistributors}
                                />
                                <InputErrorMessage field="distributor" />
                            </Field>
                
                            <Field>
                                <Label htmlFor="startDate">
                                    Data inicial
                                </Label>
                                <Input
                                    type="date"
                                    name="startDate"
                                    placeholder="Insira a data inicial"
                                />
                                <InputErrorMessage field="startDate" />
                            </Field>
                
                            <Field>
                                <Label htmlFor="endDate">
                                    Data final
                                </Label>
                                <Input
                                    type="date"
                                    name="endDate"
                                    placeholder="Insira a data final"
                                />
                                <InputErrorMessage field="endDate" />
                            </Field>
                            <Button.Primary
                                btnType='btn-primary'
                                type='submit'
                                style={{ width: '100%', marginTop: '16px' }}
                            >
                                Gerar Relatório
                            </Button.Primary>
                        </form>
                    </FormProvider>
                </Box>
            </div>
    );
}
export default Filter;