import { Button } from '../../button';
import useAnalyticsEventTracker from '../../../hooks/useAnalyticsEventTracker';
import { Link } from 'react-router-dom';

interface LearnMoreButtonProps {
    title: string,
    link: string
}

const LearnMoreButton = ({ title, link }: LearnMoreButtonProps) => {
    const gaEventTracker = useAnalyticsEventTracker('Card Planos e Valores');
    
    const handleClick = () => {
        gaEventTracker(
            'Clique no botão Saiba mais',
            `Clique no botão Saiba mais do card de planos e valores para a visualização do produto ${title}`
        )
    }

    return (
        <Link to={link}>
            <Button.Primary
                btnType='btn-secondary-outline'
                onClick={handleClick}
                style={{
                    width: '100%',
                    paddingTop: '9px',
                    paddingBottom: '9px',
                    fontSize: '14px'
                }}
            >
                Saiba mais
            </Button.Primary>
        </Link>
    );
}

export default LearnMoreButton