import { configureStore } from "@reduxjs/toolkit";

import carouselHeaderReducer from '../components/home/carousel-header/CarouselHeaderSlice';
import productReducer from '../slices/product-slice';
import homeReducer from '../slices/home-slice';
import profileReducer from '../slices/profile-slice';
import packageReducer from '../slices/package-slice';

export const store = configureStore({
    reducer: {
        carouselHeader: carouselHeaderReducer,
        product: productReducer,
        home: homeReducer,
        profile: profileReducer,
        package: packageReducer,
    }
});