import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ContatoMutate } from "../types/contact";
import { useHeaderConfig } from "./useHeaderConfig";

const PostData = async (data: ContatoMutate) => {
    const config = useHeaderConfig();
    return await axios.post('/api/contato', data, config);
}

export function useContatoMutate() {
    const mutate = useMutation({
        mutationFn: PostData
    });

    return mutate;
}