import { threadId } from "worker_threads";
import Produto from "./produto";
import StatusCompra from "./status_compra";
import Usuario from "./usuario";
class Compra {
  id: number;
  buy_date: string;
  buy_price: number;
  product: Produto;
  status: StatusCompra;
  user: Usuario;

  constructor({
    id,
    buy_date,
    buy_price,
    product,
    status,
    user,
  }: {
    id: number;
    buy_date: string;
    buy_price: number;
    product: Produto;
    status: string;
    user: Usuario;
  }) {
    this.id = id;
    this.buy_date = buy_date;
    this.buy_price = buy_price;
    this.product = product;
    this.status = Compra.getStatus(status);
    this.user = user;
  }

  static getStatus(value: string): StatusCompra {
    switch (value) {
      case "complete":
        return StatusCompra.complete;
      case "canceled":
        return StatusCompra.canceled;
      case "pending":
      default:
        return StatusCompra.pending;
    }
  }
  static getStatusLabel(value: StatusCompra): string {
    switch (value) {
      case StatusCompra.complete:
        return "Aprovado";
      case StatusCompra.canceled:
        return "Cancelado";
      case StatusCompra.pending:
      default:
        return "Pendente";
    }
  }
}
export default Compra;
