import { useState } from "react";

import { Button } from "../../../common/button";
import styles from '../../../styles/register-social.module.css';
import SimulationPrices from "../simulation-prices/simulation-prices";
import { PRICES_API_AUTHORIZATION, PRICES_API_URL } from "../../../views/prices-api";

interface IFormSimulation {
    state: string;
    suggestedSize: number;
    minPrice: number;
    averagePrice: number;
    maxPrice: number;
    setKwp: Function;
    setCost: Function;
    setFilteredPrice: Function;
};

function FormSimulation(props: IFormSimulation) {
    const [size, setSize] = useState<number>();
    const [costExpectation, setCostExpectation] = useState<number>();

    const [modules, setModules] = useState<string[]>(['-']);
    const [selectedModule, setSelectedModule] = useState("-");
    
    const [inverters, setInverters] = useState<string[]>(['-']);
    const [selectedInverter, setSelectedInverter] = useState("-");

    const [errorSize, setErrorSize] = useState(false);

    function handleChangeSize(value: any) {
        setSize(value);
        props.setKwp(value);
        
        if(!value) return;
        
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Authorization': PRICES_API_AUTHORIZATION
            },
        };
        fetch(`${PRICES_API_URL}/filters?size=${value}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setModules(['-', ...data.modules]);
                setInverters(['-', ...data.inverters]);
            })
            .catch(e => console.log(e));
    }
   
    function handleChangeModule(value: any) {
        if(!size) return;

        setSelectedModule(value);
        if(value === '-') {
            if(selectedInverter !== '-') {
                handleChangeInverter(selectedInverter);
            } else {
                handleChangeSize(size);
            }
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Authorization': PRICES_API_AUTHORIZATION
            },
        };
        fetch(`${PRICES_API_URL}/filters?size=${size}&module=${value}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setInverters(['-', ...data.inverters]);
            })
            .catch(e => console.log(e));
    }
   
    function handleChangeInverter(value: any) {
        if(!size) return;

        setSelectedInverter(value);
        if(value === '-') {
            if(selectedModule !== '-') {
                handleChangeModule(selectedModule);
            } else {
                handleChangeSize(size);
            }
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Authorization': PRICES_API_AUTHORIZATION
            },
        };
        fetch(`${PRICES_API_URL}/filters?size=${size}&inverter=${value}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setModules(['-', ...data.modules]);
            })
            .catch(e => console.log(e));
    }

    function getBody() {
        if(selectedModule !== '-' && selectedInverter !== '-' && costExpectation)
            return {
                size,
                uf: props.state,
                cost_expectation: costExpectation,
                module_brand: selectedModule,
                inverter_brand: selectedInverter
            };
        if(selectedModule !== '-' && selectedInverter !== '-')
            return {
                size,
                uf: props.state,
                module_brand: selectedModule,
                inverter_brand: selectedInverter
            };
        if(selectedModule !== '-' && costExpectation)
            return {
                size,
                uf: props.state,
                cost_expectation: costExpectation,
                module_brand: selectedModule
            };
        if(selectedModule !== '-')
            return {
                size,
                uf: props.state,
                module_brand: selectedModule
            };
        if(selectedInverter !== '-' && costExpectation)
            return {
                size,
                uf: props.state,
                cost_expectation: costExpectation,
                inverter_brand: selectedInverter
            };
        if(selectedInverter !== '-')
            return {
                size,
                uf: props.state,
                inverter_brand: selectedInverter
            };
        if(costExpectation)
            return {
                size,
                uf: props.state,
                cost_expectation: costExpectation
            };
        return {
            size,
            uf: props.state
        };
    }

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if(!size) {
            setErrorSize(true);
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000',
                'Authorization': PRICES_API_AUTHORIZATION
            },
            body: JSON.stringify(getBody())
        };
        fetch(`${PRICES_API_URL}/filter-prices`, requestOptions)
            .then(response => response.json())
            .then(data => props.setFilteredPrice(data))
            .catch(e => console.log(e));
        
        setErrorSize(false);
    }

    return (
        <section>
            <div style={{ marginBottom: '16px' }}>
                <SimulationPrices
                    size={props.suggestedSize}
                    minPrice={props.minPrice}
                    averagePrice={props.averagePrice}
                    maxPrice={props.maxPrice}
                />
            </div>
            <form onSubmit={handleSubmit}>
                <p className="label-register">
                    Porte do Sistema Consultado* <span className="color-greyscale-06">(kWp)</span>
                </p>
                <input
                    className="input-register"
                    type="number"
                    min={0}
                    step=".01"
                    value={size}
                    onChange={e => handleChangeSize(parseFloat(e.target.value))}
                />
                {errorSize && (
                    <p className="input-error">
                        {"* Valor inválido"}
                    </p>
                )}
                <p className="label-register">
                    Valor Consultado <span className="color-greyscale-06">(R$)</span>
                </p>
                <input
                    className="input-register"
                    type="number"
                    min={0}
                    step=".01"
                    value={costExpectation}
                    onChange={e => {
                        setCostExpectation(parseFloat(e.target.value));
                        props.setCost(parseFloat(e.target.value));
                    }}
                />
                <p className="label-register">Marca módulo</p>
                <div className={styles["select-wrapper"]}>
                    <select
                        className={"input-register " + styles["select"]}
                        value={selectedModule}
                        onChange={e => handleChangeModule(e.target.value)}
                    >
                    {modules.map((module) => (
                        <option value={module} key={module}>
                            {module}
                        </option>
                    ))}
                    </select>
                </div>
                <p className="label-register">Marca Inversor</p>
                <div className={styles["select-wrapper"]}>
                    <select
                        className={"input-register " + styles["select"]}
                        value={selectedInverter}
                        onChange={e => handleChangeInverter(e.target.value)}
                    >
                    {inverters.map((inverter) => (
                        <option value={inverter} key={inverter}>
                            {inverter}
                        </option>
                    ))}
                    </select>
                </div>
                    
                <Button.Primary
                    btnType="btn-primary"
                    type="submit"
                    style={{ width: '100%' }}
                >
                    Simular
                </Button.Primary>
            </form>
        </section>
    );
}
export default FormSimulation;