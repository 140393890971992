import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { FilterMutate } from "../types/filter";
import { useHeaderConfig } from "./useHeaderConfig";

const PostData = async (data: FilterMutate) => {
    const config = useHeaderConfig();
    return await axios.post('api/filtro', data, config);
}

export function useFilterMutate() {
    const queryClient = useQueryClient();
    const mutate = useMutation({
        mutationFn: PostData,
        onSuccess: () => {
            queryClient.invalidateQueries(['filter-data']);
        }
    });

    return mutate;
}