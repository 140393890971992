import { AiOutlineSearch } from 'react-icons/ai';

import styles from '../../styles/purchases-historic.module.css';

interface SearchUserProps {
    filterValue: string,
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handleSearch: (e: any) => void
}

const SearchUser = ({ filterValue, handleChange, handleSearch }: SearchUserProps) => {
    return (
        <form className={styles["search"]} onSubmit={handleSearch}>
            <input
                type="text"
                className={styles["search-input"]}
                value={filterValue}
                onChange={(e) => handleChange(e)}
                placeholder="Pesquisar usuários"
            />
            <AiOutlineSearch
                className={styles["search-icon"]}
                style={{ cursor: 'pointer' }}
                onClick={handleSearch}
            />
        </form>
    );
}

export default SearchUser