import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import BasePage from './base-page';
import Header from './header';
import UsersList from './users-list';

import Breadcrumb from '../../common/breadcrumb';
import Spinner from '../../common/spinner/spinner';
import When from '../../common/when/when';
import PaginationButtons from '../../components/home/pagination-buttons/pagination-buttons';
import ModalConfirm from '../../components/manage-subscriptions/modal-confirm/modal-confirm';
import { useAdminUserListData } from '../../hooks/useAdminUserListData';
import { useUserDelete } from '../../hooks/useUserDelete';
import { setUser } from '../../slices/admin-user-slice';
import IUser from '../../types/user';
import AuthGuard from '../../guards/auth-guard';

function AdminUsersList() {
    const [currentPage, setCurrentPage] = useState(0);
    const [lastPage, setLastPage] = useState(0);
    const [users, setUsers] = useState<IUser[] | []>([]);
    const [filterValue, setFilterValue] = useState('');
    const modalDeleteRef = useRef(null);

    const [filterHook, setFilterHook] = useState(filterValue);
    const [pageHook, setPageHook] = useState<number>();

    const adminUserList = useAdminUserListData(filterHook, pageHook);

    useEffect(() => {
        if (adminUserList.data) {
            setCurrentPage(adminUserList.data.metadados.pagina);
            setLastPage(adminUserList.data.metadados.pagina_limite);
            setUsers(adminUserList.data.usuarios);
        }
    }, [adminUserList.data]);

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();

        setFilterValue(e.target.value);
    }

    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    function handleSearch(e: any) {
        e.preventDefault();
        setFilterHook(filterValue);
        setPageHook(0);
        scrollToTop();
    }

    function handleChangePagePagination(change: string | number) {
        if (change === "next" && currentPage < lastPage) {
            setPageHook(currentPage + 1);
            scrollToTop();
        } else if (change === "previus" && currentPage > 0) {
            setPageHook(currentPage - 1);
            scrollToTop();
        } else if (typeof change === "number") {
            setPageHook(change - 1); //na contagem sempre é mostrado 1 a mais
            scrollToTop();
        } else {
            return;
        }
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();
    function handleSelectUser(user: IUser) {
        dispatch(setUser(user));
        navigate(`/admin/usuario/${user.id}`);
    }

    const [showModalDelete, setShowModalDelete] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<number>();
    function handleOpenModalDelete(user: IUser) {
        setShowModalDelete(true);
        setSelectedUserId(user.id);
    }
    function handleCloseModalDelete() {
        setShowModalDelete(false);
    }
    const [statusResponse, setStatusResponse] = useState({
        message: '',
        status: '',
    });

    const userDelete = useUserDelete();

    function deleteUser() {
        if (selectedUserId) {
            userDelete.mutate(selectedUserId);
        }
    }

    useEffect(() => {
        if (userDelete.isError) {
            setStatusResponse({
                message: 'Erro ao deletar usuário.',
                status: 'error'
            });
        }
        if (userDelete.isSuccess) {
            setStatusResponse({
                message: 'Sucesso ao deletar usuário.',
                status: 'success'
            });
            // fetchData();
            handleCloseModalDelete();
        }
    }, [userDelete.isError, userDelete.isSuccess])

    return (
        <AuthGuard
            allow="administrador"
            notAllowMessage={
                <div className="container" style={{ padding: '60px 0 180px 0' }}>
                    <h3 className={"title-h3 text-bold"}>
                        Página exclusiva para administradores
                    </h3>
                </div>
            }
        >
            <>
                <BasePage>
                    <Breadcrumb>
                        <Link to="/admin">Painel Administrativo</Link>
                        <span>Usuários</span>
                    </Breadcrumb>
                    <Header
                        filterValue={filterValue}
                        handleChange={handleChange}
                        handleSearch={handleSearch}
                    />
                    <UsersList
                        users={users}
                        handleSelectUser={handleSelectUser}
                        handleOpenModalDelete={handleOpenModalDelete}
                    />
                    <div className="d-flex justify-content-center">
                        <PaginationButtons
                            currentPage={currentPage + 1}
                            lastPage={lastPage + 1}
                            onChangePagination={handleChangePagePagination}
                        />
                    </div>
                </BasePage>
                <When expr={showModalDelete}>
                    <ModalConfirm
                        title="Você tem certeza que deseja deletar este usuário?"
                        description="O usuário será deletado e as informações serão perdidas."
                        btn_text="Deletar usuário"
                        statusResponse={statusResponse}
                        onConfirm={deleteUser}
                        onClose={handleCloseModalDelete}
                    />
                </When>
                <CSSTransition
                    in={showModalDelete}
                    nodeRef={modalDeleteRef}
                    timeout={300}
                    unmountOnExit
                    classNames='fade'
                >
                    <div ref={modalDeleteRef}>
                        <ModalConfirm
                            title="Você tem certeza que deseja deletar este usuário?"
                            description="O usuário será deletado e as informações serão perdidas."
                            btn_text="Deletar usuário"
                            statusResponse={statusResponse}
                            onConfirm={deleteUser}
                            onClose={handleCloseModalDelete}
                        />
                    </div>
                </CSSTransition>
                <When expr={adminUserList.isLoading || userDelete.isLoading}>
                    <Spinner />
                </When>
            </>
        </AuthGuard>
    );
}
export default AdminUsersList;
