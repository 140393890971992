import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { useHeaderConfig } from "./useHeaderConfig";
import { InternProductDelete } from "../types/intern-product";

const DeleteData = async (data: InternProductDelete) => {
    const defaultConfig = useHeaderConfig();
    const config = {...defaultConfig, data};
    return await axios.delete('/api/dash-interno', config);
}

export function useInternProductDelete() {
    const queryClient = useQueryClient();
    const mutate = useMutation({
        mutationFn: DeleteData,
        onSuccess: () => {
            queryClient.invalidateQueries(['home-data']);
            // window.location.reload();
        }
    });

    return mutate;
}