import { MouseEventHandler } from "react";

import styles from './styles.module.css';

const Indicator = ({ onClick }: { onClick: MouseEventHandler<HTMLDivElement> }) => {
    return (
        <div
            className={styles['indicator']}
            onClick={onClick}
        />
    );
}

export default Indicator