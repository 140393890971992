import { dateFormatter } from "../../app/formatter";
import Box from "../../common/box/box";

interface CommitData {
    title: string;
    date: string;
    description: string;
}

const HistoricItem = (props: CommitData) => {
    return (
        <Box>
            <h5 className="title-h5" style={{ marginBottom: '16px' }}>
                {props.title}
            </h5>
            <span className="text-medium color-greyscale-03">
                {dateFormatter(props.date)}
            </span>
            <p style={{ marginTop: '8px' }}>
                {props.description}
            </p>
        </Box>
    );
};

export const HistoricSection = (props: { title: string; commits: CommitData[]; }) => {
    return (
        <div className="d-flex flex-column" style={{ gap: '24px', marginTop: '48px' }}>
            <h3 className="title-h3 text-bold color-primary-01">
                {props.title}
            </h3>
            {props.commits.map(commit => (
                <HistoricItem
                    title={commit.title}
                    date={commit.date}
                    description={commit.description} />
            ))}
        </div>
    );
};
