import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { AdmAcessosMutate } from "../types/admin";
import { useHeaderConfig } from "./useHeaderConfig";

const PostData = async (data: AdmAcessosMutate): Promise<any> => {
    const config = useHeaderConfig();
    return await axios.post('/api/adm_acessos', data, config);
}

export function useAdmAcessosMutate() {
    const queryClient = useQueryClient();
    
    const mutate = useMutation({
        mutationFn: PostData,
        onSuccess: () => {
            queryClient.invalidateQueries([
                'manage-subscriptions-data',
                'product-data',
                'my-products-data',
                'purchase-historic-data',
                'admin-user-data'
            ]);
            window.location.reload();
        }
    });

    return mutate;
}