import { ICarsouselHeaderItem } from '../types/carousel-header';
import { IProduct } from '../types/produto';

const homeAPI = {
    fetchHomeData() {
        const HomeData = fetch('/api/home')
            .then((res) => res.json());
        return HomeData;
    },
    async fetchHomeDataFake() {
        const carouselData: {
            data: {
                carousel: ICarsouselHeaderItem[],
                products: IProduct[]
            }
        } = await new Promise((resolve) =>
            setTimeout(() => 
                resolve({ data: {
                    carousel: [
                        {
                            title: '1 - Dashboards para diferentes necessidades do setor energético',
                            description: 'Facilite sua visão estratégica por meio dos dashboards Greendex com uma linguagem simples, sustentável e acessível para o seu negócio !',
                            button_URL: '/',
                            button_title: 'Facilite seu dia-a-dia',
                        },
                        {
                            title: '2 - Dashboards para diferentes necessidades do setor energético',
                            description: 'Facilite sua visão estratégica por meio dos dashboards Greendex com uma linguagem simples, sustentável e acessível para o seu negócio !',
                            button_URL: '/',
                            button_title: 'Facilite seu dia-a-dia',
                        },
                        {
                            title: '3 - Dashboards para diferentes necessidades do setor energético',
                            description: 'Facilite sua visão estratégica por meio dos dashboards Greendex com uma linguagem simples, sustentável e acessível para o seu negócio !',
                            button_URL: '/',
                            button_title: 'Facilite seu dia-a-dia',
                        },
                        {
                            title: '4 - Dashboards para diferentes necessidades do setor energético',
                            description: 'Facilite sua visão estratégica por meio dos dashboards Greendex com uma linguagem simples, sustentável e acessível para o seu negócio !',
                            button_URL: '/produto/1',
                            button_title: 'Facilite seu dia-a-dia',
                        }
                    ],
                    products: [
                        {
                            id: "comparador-de-precos",
                            thumbnail_link: 'https://images.pexels.com/photos/4588065/pexels-photo-4588065.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                            title: "Comparador de Preços",
                            description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
                            badge: '40% de desconto',
                            paragraphs: ["Some quick example text to build on the card title and make up the bulk of the card's content.", "Some quick example text to build on the card title and make up the bulk of the card's content."],
                            category: 'equipamento',
                            price: 0,
                            payment_link: "",
                            periodo_faturamento: "",
                        },
                        {
                            id: "calculadora-quantica",
                            thumbnail_link: 'https://cdn.pixabay.com/photo/2023/01/10/00/17/italy-7708552_960_720.jpg',
                            title: "Calculadora Quântica",
                            description: "Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.",
                            paragraphs: ["Some quick example text to build on the card title and make up the bulk of the card's content.", "Some quick example text to build on the card title and make up the bulk of the card's content."],
                            category: 'mercado-livre',
                            price: 0,
                            payment_link: "",
                            periodo_faturamento: "",
                        },
                        {
                            thumbnail_link: 'https://cdn.pixabay.com/photo/2023/01/11/19/53/hiking-7712678_960_720.jpg',
                            id: "marketplace-de-energia",
                            title: "Marketplace de Energia",
                            description: "Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.",
                            paragraphs: ["Some quick example text to build on the card title and make up the bulk of the card's content.", "Some quick example text to build on the card title and make up the bulk of the card's content."],
                            category: 'gds',
                            price: 0,
                            payment_link: "",
                            periodo_faturamento: "",
                        },
                        {
                            id: "engrenagem-virtual",
                            thumbnail_link: 'https://cdn.pixabay.com/photo/2022/12/24/09/26/bridge-7675585_960_720.jpg',
                            title: "Engrenagem Virtual",
                            description: "Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.",
                            paragraphs: ["Some quick example text to build on the card title and make up the bulk of the card's content.", "Some quick example text to build on the card title and make up the bulk of the card's content."],
                            badge: 'Produto gratuito',
                            category: 'dashboard',
                            price: 0,
                            payment_link: "",
                            periodo_faturamento: "",
                        },
                        {
                            id: "amperio",
                            thumbnail_link: 'https://cdn.pixabay.com/photo/2022/11/07/20/23/new-york-7577186_960_720.jpg',
                            title: "Ampério: Uma Corrente de Informações",
                            description: "Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.",
                            paragraphs: ["Some quick example text to build on the card title and make up the bulk of the card's content.", "Some quick example text to build on the card title and make up the bulk of the card's content."],
                            badge: "Exclusivo por assinatura",
                            category: 'equipamento',
                            price: 0,
                            payment_link: "",
                            periodo_faturamento: "",
                        },
                        {
                            id: "comparador-de-precos2",
                            thumbnail_link: 'https://images.pexels.com/photos/4588065/pexels-photo-4588065.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
                            title: "Comparador de Preços",
                            description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
                            badge: '40% de desconto',
                            paragraphs: ["Some quick example text to build on the card title and make up the bulk of the card's content.", "Some quick example text to build on the card title and make up the bulk of the card's content."],
                            category: 'mercado-livre',
                            price: 0,
                            payment_link: "",
                            periodo_faturamento: "",
                        },
                        {
                            thumbnail_link: 'https://cdn.pixabay.com/photo/2023/01/10/00/17/italy-7708552_960_720.jpg',
                            id: "calculadora-quantica2",
                            title: "Calculadora Quântica",
                            description: "Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.",
                            paragraphs: ["Some quick example text to build on the card title and make up the bulk of the card's content.", "Some quick example text to build on the card title and make up the bulk of the card's content."],
                            category: 'gds',
                            price: 0,
                            payment_link: "",
                            periodo_faturamento: "",
                        },
                        {
                            thumbnail_link: 'https://cdn.pixabay.com/photo/2023/01/11/19/53/hiking-7712678_960_720.jpg',
                            id: "marketplace-de-energia2",
                            title: "Marketplace de Energia",
                            description: "Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.",
                            paragraphs: ["Some quick example text to build on the card title and make up the bulk of the card's content.", "Some quick example text to build on the card title and make up the bulk of the card's content."],
                            category: 'dashboard',
                            price: 0,
                            payment_link: "",
                            periodo_faturamento: "",
                        },
                        {
                            id: "engrenagem-virtual2",
                            thumbnail_link: 'https://cdn.pixabay.com/photo/2022/12/24/09/26/bridge-7675585_960_720.jpg',
                            title: "Engrenagem Virtual",
                            description: "Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.",
                            paragraphs: ["Some quick example text to build on the card title and make up the bulk of the card's content.", "Some quick example text to build on the card title and make up the bulk of the card's content."],
                            badge: 'Produto gratuito',
                            category: 'equipamento',
                            price: 0,
                            payment_link: "",
                            periodo_faturamento: "",
                        },
                        {
                            id: "amperio2",
                            thumbnail_link: 'https://cdn.pixabay.com/photo/2022/11/07/20/23/new-york-7577186_960_720.jpg',
                            title: "Ampério: Uma Corrente de Informações",
                            description: "Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content.",
                            paragraphs: ["Some quick example text to build on the card title and make up the bulk of the card's content.", "Some quick example text to build on the card title and make up the bulk of the card's content."],
                            badge: "Exclusivo por assinatura",
                            category: 'mercado-livre',
                            price: 0,
                            payment_link: "",
                            periodo_faturamento: "",
                        }
                    ]
                }})
            , 1000)
        );
        return carouselData;
    }
}
export default homeAPI;